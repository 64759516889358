// 购买时商品的状态
// 0正常 1素材下架 2合同到期 3权限关闭 4已购买 5数量不足 6购物车存在 ,
export enum PurchaseStatus {
  /** 正常 */
  Normal = 0,

  /** 素材下架 */
  Removed = 1,

  /** 合同到期 */
  Expired = 2,

  /** 权限关闭 */
  PermissionClosed = 3,

  /** 已购买 */
  Purchased = 4,

  /** 数量不足 */
  NotEnough = 5,

  /** 购物车已存在 */
  Existed = 6,

  /** 余额不足 */
  InsufficientBalance = 7,
}

// 0正常 1数量超额 2已在购物车中 3已购买,
export enum AddToCartStatus {
  /** 正常 */
  Normal = 0,
  /** 数量超额 */
  NotEnough = 1,
  /** 已在购物车中 */
  Existed = 2,
  /** 已购买 */
  Purchased = 3,
}

/** 购物车商品状态 */
export enum PurchaseBatchStatus {
  /** 成功 */
  Success = 1,
  /** 已下架 */
  PullOf = 2,
  /** 失败 */
  Fail = 3,
}

// 0正常 1修改信息一致 2购物车存在 3已购买 4请先完善被授权方信息
export enum UpdateCartStatus {
  /** 正常 */
  Normal = 0,
  /** 数量超额 */
  SameUpdate = 1,
  /** 已在购物车中 */
  Existed = 2,
  /** 已购买 */
  Purchased = 3,
  /** 请先完善被授权方信息 */
  NeedCompleteInfo = 4,
}

// 购物车中商品显示时状态
export enum CartStatus {
  /** 正常 */
  Normal = 0,

  /** 素材下架 */
  Removed = 1,

  /** 合同到期 */
  ContractExpired = 2,

  /** 权限关闭 */
  PermissionClosed = 3,

  /** 购买数量达到上限 */
  NotEnough = 4,

  /** 权限到期 */
  PermissionExpired = 5,
}

// 新增授权状态
// 0成功 1正常失败 2手动添加时id_number存在 3手动添加时name存在 4需要手动更新的状态 5能搜索到，统一代码不正确 ,
export enum WarrantSearchStatus {
  /** 正常 */
  Success = 0,

  /** 正常失败 */
  Failed = 1,

  /** 手动添加时id_number存在 */
  ExistIdNumber = 2,

  /** 手动添加时name存在 */
  ExistName = 3,

  /** 需要手动更新的状态 */
  NeedUpdate = 4,

  /** 能搜索到，统一代码不正确 */
  WrongIdNumber = 5,

  /** 验证码错误 */
  CaptchaFailed = 6,
}

// 0未找到 1正常 2下架
export enum ResourceStatus {
  /** 未找到 */
  NotFound = 0,

  /** 正常 */
  Normal = 1,

  /** 下架 */
  Offline = 2,
}

// 矢量图 vector / 照片 photo
export enum CreativeType {
  /** 矢量图 */
  Vector = 'vector',

  /** 照片 */
  Photo = 'photo',
}

// 确认购买弹窗数据来源
export enum PurchaseConfirmType {
  /** 图片详情页 -> 立即购买 */
  Show = 'show',

  /** 购物车 -> 结算 */
  Cart = 'cart',

  /** 批量操作 -> 立即购买 */
  Batch = 'batch',
}

// 素材类型 1创意图片 2视频 3字体
export enum ContentType {
  No = 0,

  /** 创意图片 */
  Creative = 1,

  /** 视频 */
  Video = 2,

  /** 字体 */
  Font = 3,

  /** 编辑图片 */
  Editorial = 4,

  /** 企业资源 */
  Dam = 5,

  /** 音乐 */
  Music = 6,

  /** 版权 */
  Ip = 7,
}

export enum SearchStatisticsType {
  Creative = 1,
  Video = 2,
  Music = 3,
  Font = 4,
  Tempalte = 5,
  Ip = 6,
}

/** 授权类型 1个人 2公司 */
export enum WarrantType {
  /** 个人 */
  Personal = 1,

  /** 公司 */
  Company = 2,
}
/** 创意详情  0 国内 1全部 */
export enum CreativeSimilarType {
  /** 国内 */
  local = 0,

  /** 全部 */
  all = 1,
}

export enum AccountStatus {
  /** 未激活 */
  Inactive = 0,

  /** 正常 */
  Normal = 1,

  /** 被冻结 */
  Frozen = 2,

  /** 已删除 */
  Delete = 3,
}

export enum RightStatus {
  /** 开启 */
  Open = 1,

  /** 关闭 */
  Close = 0,
}

export enum AmountLimit {
  /** 是 */
  Limit = 1,

  /** 否 */
  UnLimit = 0,
}

export enum SubsidiaryTab {
  /** 基本信息 */
  BasicInfo = 1,

  /** 权限明细 */
  RightDetail = 2,

  /** 数字资产权限明细 */
  DamRightDetail = 3,

  /** 套餐权限明细 */
  ComboAccessDetail = 4,

  /** AI生成明细 */
  AiCreate = 11,
}

/** 1充值 2购买 3余额到期 4退款 */
export enum BillingType {
  /** 充值 */
  Recharge = 1,

  /** 购买 */
  Buy = 2,

  /** 余额到期 */
  BalanceExpired = 3,

  /** 退款 */
  Refund = 4,
}

/** Form错误检查类型 */
export enum FormItemType {
  /** 公司名 */
  CompanyName = 'company_name',

  /** 公司id */
  CompanyId = 'company_id',

  /** 个人名 */
  PersonName = 'person_name',

  /** 个人id */
  PersonId = 'person_id',

  /** 验证码 */
  Captcha = 'captcha',
}

/** 授权弹窗类型 */
export enum AuthType {
  AddToCart = 'add_to_cart',
  AddToCartBatch = 'add_to_cart_batch',
  UpdateCart = 'update_cart',
}

/** 账户信息对应的类型 目前用于batch-operation组件 */
export enum AccountInfoType {
  /** 小样记录 */
  SAMPLE = 'sample',

  /** 已购素材（大客户） */
  PURCHASE = 'purchase',

  /** 已购素材（微利） */
  HELLORF = 'hellorf',

  /** 收藏夹 */
  COLLECTION = 'collection',

  /** 浏览记录 */
  HISTORY = 'history',

  /** 分享 */
  SHARE = 'share',

  /** 编辑图片 */
  EDITORIAL = 'editorial',

  /** 创意图片 */
  CREATIVE = 'creative',

  /** 视频 */
  VIDEO = 'video',

  /** ai助手 */
  AIASSISTANT_POINTSMANAGE = 'aiAssistantPointsManage',
}

// 微利素材类型
export enum HellorfType {
  /** 图片 */
  Photo = 'image',

  /** 视频 */
  Video = 'video',

  /** 字体 */
  Font = 'font',

  /** 音乐 */
  Music = 'music',

  /** 免费图 */
  FreePhoto = 'freeimage',
}

// 音乐分级
export enum MusicLevelType {
  /** A */
  Absolute = 1,
  /** B */
  Boost = 2,
  /** C */
  Chic = 3,
  /** SS */
  SHUTTERSTOCK = 99,
  /** SS精选 */
  SHUTTERSTOCK优选 = 100,
}

// 已购素材添加标签 0成功 2超标签 3超字符
export enum TagStatus {
  /** 成功 */
  Success = 0,

  /** 超标签 */
  MoreNumber = 2,

  /** 超字符 */
  MoreCharacter = 3,
}

export enum EditorialImageType {
  /** 单图 */
  SingleImage = 1,

  /** 组图 */
  GroupImage = 2,
}

export enum ImageListRenderType {
  /** 创意图 */
  CREATIVE = 'creative',

  /** 编辑图 */
  Editorial = 'editorial',

  /** 编辑组图分图 */
  EditorialGroupList = 'group-list',

  /** 字体 */
  Font = 'font',
}

export enum AddCartError {
  /** 将满 */
  WillFull = 1,

  /** 已满 */
  HasFull = 2,
}

export enum UserType {
  /** 预付费账户 */
  PrePaid = 1,

  /** 后付费账户 */
  PostPaid = 2,
}

export enum UserStatus {
  /** 申请中 */
  Applying = 0,

  /** 正常 */
  Normal = 1,

  /** 关闭 */
  Closed = 2,

  /** 未申请 */
  NotApplied = 3,
}

export enum SecondMenus {
  Inland = 1,
}

export enum DAMPageType {
  /** 正常资源页 */
  File = 0,

  /** 搜索结果页 */
  Search = 1,

  /** 我上传的 */
  Upload = 2,

  /** 海洛资源 */
  HelloRF = 3,

  /** 最近删除 */
  Delete = 4,

  /** 审核列表 */
  Review = 5,

  /** AI图库 */
  Aigc = 6,

  /** AI助手 */
  AiAssistant = 7,
}

export enum DAMFileType {
  /** 文件夹 */
  Folder = 1,
  /** 文件 */
  File = 0,
}

export enum DAMTagType {
  /** 无 */
  No = 0,

  /** 未编辑 */
  Edit = 1,

  /** 海洛 */
  HelloRF = 2,

  /** 即将到期 */
  Expiring = 3,

  /** 已过期 */
  Expired = 4,
}

export enum DAMLayoutType {
  /** 列表 */
  List = 1,

  /** 网格 */
  Grid = 2,

  /** 自适应 */
  Justified = 3,
}

export enum DAMMenuType {
  /** 系统目录菜单 海洛资源 */
  Plus = 1,

  /** 系统目录菜单 最近删除 */
  Delete = 2,

  /** 用户自定义菜单 可以有多个 */
  Custom = 0,

  /** 系统目录菜单 AI图库 */
  Aigc = 3,

  /** 系统目录菜单 AI助手 */
  AiAssistant = 4,
}

export enum DAMCheckType {
  /** 用途 */
  License = 'license',

  /** 分类 */
  Category = 'category',

  /** 来源 */
  Source = 'source',

  /** 目录 */
  Menu = 'menu',
}

export enum DAMFileUploadType {
  /** 图片 */
  Image = 'image',

  /** 版权文件 */
  Copyright = 'copyright',

  /** 附件 */
  Attachment = 'attachment',

  /** 文件封面 */
  Cover = 'cover',

  /** 视频 */
  Video = 'video',

  /** 字体 */
  Font = 'font',
}

export enum DAMCheckStatus {
  /** 未选中 */
  UnChecked = 'un-checked',

  /** 半选 */
  SemiChecked = 'semi-checked',

  /** 全选 */
  AllChecked = 'all-checked',
}

export enum FileUploadStatus {
  Preview = 'preview',
  Waiting = 'waiting',
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  Error = 'error',
}

export enum ResourceStatisticsType {
  /** 上传 */
  Upload = 'upload',

  /** 下载 */
  Download = 'download',

  /** 成员下载 */
  DownloadMember = 'download-member',

  /** 资源下载 */
  DownloadResource = 'download-resource',
}

// 排序
export enum StatisticsSort {
  /** 降序 */
  Desc = 'desc',

  /** 升序 */
  Asc = 'asc',
}

export enum CollectType {
  /** 添加 */
  Add = 'add',

  /** 移动 */
  Move = 'move',
}

export enum CollectActionType {
  /** 收藏 */
  Collect = 'collect',
  /** 取消收藏 */
  Cancel = 'cancel',
}

export enum InviteFail {
  /** 已加入本公司 */
  OurMember = 1,

  /** 已加入其他公司 */
  OtherMember = 2,

  /** 已加入海洛其他平台 */
  OtherSite = 3,
}

export enum PermanentStatus {
  /** 初始状态 */
  initialPermanent = 0,

  /** 永久授权 */
  ForeverPermanent = 1,

  /** 授权不明 */
  UnknownPermanent = 2,

  /** 有限授权 */
  LimitPermanent = 3,
}

export enum QuantityModel {
  /** 单个 */
  Single = 'single',

  /** 多个 */
  Multi = 'multi',
}

export enum DamResourceType {
  /** 图片 */
  Image = 'image',

  /** 视频 */
  Video = 'video',

  /** 字体 */
  Font = 'font',

  /** 文档 */
  Document = 'document',

  /** 音乐 */
  Audio = 'audio',
}

export enum DamType {
  /** 图片 */
  Image = '1',

  /** 视频 */
  Video = '2',

  /** 字体 */
  Font = '3',

  /** 文档 */
  Document = '4',

  /** 音乐 */
  Audio = '5',
}

export enum CustomMenuType {
  /** 登录成功进入dam首页，不显示plus菜单，仅搜索dam */
  TypeOne = '1',

  /** 登录成功进入plus首页，显示plus菜单，可搜索plus */
  TypeTwo = '2',

  /** 登录成功进入dam首页，显示plus菜单，可搜索plus */
  TypeThree = '3',
}

export enum SortStatus {
  /** 正常 */
  Normal = 'normal',
  /** 正序 */
  Asc = 'asc',
  /** 倒叙 */
  Desc = 'desc',
}

export enum SubsidiaryBatchAction {
  /** 修改基本信息 */
  SetBasicInfo = 'set-basic-info',
  /** 修改直接上级 */
  SetDirectLeader = 'set-direct-leader',
  /** 开启权限 */
  Open = 'open',
  /** 关闭权限 */
  Close = 'close',
  /** 设置数量限制 */
  SetLimit = 'set-limit',
  /** 取消数量限制 */
  CancelLimit = 'cancel-limit',
}

export enum SetLimitFailedType {
  // 已用数量＞数量限制
  Used = -1,
  // 月数量限制＞总数量限制
  Exceeded = -2,
}

export enum PermissionType {
  /** Ip授权 */
  Ip = 8,
}

export enum PermissionAiType {
  /** ai下载 */
  Download = 10,
  /** ai生成 */
  Create = 11,
}

export enum EditLeaderType {
  /** 批量 */
  Batch = 'batch',
  /** 编辑 */
  Edit = 'edit',
  /** 邀请 */
  Invite = 'invite',
  /** 查看架构 */
  Check = 'check',
}

export enum SubjectType {
  /** 专题 */
  Topic = 1,
  /** 运营专题 */
  Operation = 2,
}
export enum SubjectMenuType {
  /** 创意专题 */
  CreativeTopics = 1,
  /** 行业精选 */
  IndustryPicks = 2,
}

export enum Platform {
  /**
   * 图片
   */
  Image = 'image',
  /**
   * 视频
   */
  Video = 'video',
  /**
   * 字体
   */
  Font = 'font',
  /**
   * 音乐
   */
  Audio = 'audio',
  /**
   * Ip
   */
  Ip = 'ip',
}

export enum PurchasedTab {
  /** 按用途显示plus购买素材内容 */
  Plus = 1,

  /** 按套餐显示原微利已购素材内容 */
  HelloRf = 2,
}

export enum PurchasedType {
  /** plus套餐 */
  HelloRf = 1,

  /** plus购买微利套餐 */
  Plus = 2,
}
export enum FontPackageType {
  /**
   * 标准授权
   */
  Standard = 1,

  /**
   * 扩展授权
   */
  Enhanced = 2,

  /**
   * 单字标准授权
   */
  SingleStandard = 3,

  /**
   * 单字扩展授权
   */
  SingleEnhanced = 4,
}

export enum CreativeTabType {
  /**
   * 图片
   */
  Image = 0,
  /**
   * 图组
   */
  Groups = 1,
}

export enum LoginMessageType {
  /**
   *  登录成功
   */
  LoginSuccess = 'loginSuccess',
  /**
   *  已登录
   */
  ReadyLoginSuccess = 'readyLoginSuccess',
  /**
   *  退出登录
   */
  LogoutSuccess = 'logoutSuccess',
}

// 支付方式
export enum Payment {
  /** 支付宝 */
  Ali = 1,
  /** 微信 */
  Wechat = 6,
}

// 订单状态
export enum OrderStatus {
  /** 未支付 */
  Unpay,
  /** 成功 */
  Success,
  /** 关闭 */
  Close,
  /** 已取消 */
  Cancel,
  /** 已退款 */
  Refund,
  /** 到账审核中 */
  Checking,
}

export enum RESOURCETYPE {
  /**
   * 视频
   */
  VIDEO = 3,
  /**
   * 音乐
   */
  MUSIC = 4,
}

// Dam资源审核状态
export enum DamReviewStatus {
  /** 不需要审核 */
  NoReview,
  /** 未审核 */
  Unreview,
  /** 审核中 */
  Reviewing,
  /** 审核通过 */
  Passed,
}

// 模板菜单是否可见 1：是  0：否
export enum IsShowTemplate {
  show = 1,
  hide = 0,
}

// AI助手菜单是否可见 1：是  0：否
export enum IsShowAIGC {
  show = 1,
  hide = 0,
}

// 希音审核状态
export enum AuditStatus {
  /** 审核未通过 */
  Unpassed,
  /** 审核中 */
  Reviewing,
  /** 审核通过 */
  Passed,
}
export enum AuditStatusMap {
  /** 审核未通过 */
  unpassed = 3,
  /** 审核中 */
  reviewing = 1,
  /** 审核通过 */
  passed = 2,
}

// 希音素材购买状态
export enum AuditBuyStatus {
  /** 未购买 */
  nopurchase,
  /** 成功 */
  success,
  /** 失败 */
  fail,
}
export enum AuditBuyStatusMap {
  /** 未购买 */
  nopurchase = 1,
  /** 成功 */
  success = 2,
  /** 失败 */
  fail = 3,
}
// shein审核状态
export enum AuditStatusShein {
  /** 正常购买 */
  Normal = 0,
  /** 审核中 */
  Auditing = 1,
  /** 已购买 */
  Purchased = 2,
}

// aigc 图片生成状态
export enum AIGCCreateStatus {
  /** 待生成 */
  Await = 0,
  /** 生成中 */
  Creating = 1,
  /** 生成成功 */
  Success = 2,
  /** 生成失败 */
  Fail = 3,
}

// aigc 图片审核状态
export enum AIGCAuditStatus {
  /** 待审核/审核中 */
  Auditing = 0,
  /** 审核通过 */
  Success = 1,
  /** 审核失败 */
  Fail = 2,
}

// aigc 图片下载状态
export enum AIGCDownloadStatus {
  /** 待下载 */
  Await = 0,
  /** 下载中 */
  Downloading = 1,
  /** 下载成功 */
  Success = 2,
  /** 下载失败 */
  Fail = 3,
}

// 美图埋点参数类型
/**
 * 搜索页面名称
 */
export enum MeituAnalyzeSearchPageName {
  /** 搜索页 */
  SearchPage = 'search_page',
  /** 搜索结果页 */
  SearchResult1 = 'search_result_1',
  /** 搜索结果详情页 */
  SearchResult2 = 'search_result_2',
  /** 以图搜索 */
  SearchImagePage = 'search_image_page',
}

/** 搜索方式 */
export enum MeituAnalyzeSearchType {
  /** 搜索按钮 */
  SearchButtom = '0',
  /** 热词 */
  HotWord = '1',
  /** 以图搜索 */
  GraphSearch = '2',
}

/** 搜索内容类型 */
export enum MeituAnalyzeSearchContentType {
  /** 图片 */
  creative = 'image',
  /** 视频 */
  video = 'video',
  /** 字体 */
  font = 'typeface',
  /** 音乐 */
  music = 'music',
  /** IP */
  ip = 'ip',
}

/** 图片/视频搜索结果类型 */
export enum MeituAnalyzeSearchTabResultType {
  /** 热门 */
  popular = 'popular',
  /** 图片匹配度 */
  relevance = 'match',
  /** 视频匹配度 */
  similarity = 'match',
  /** 最新 */
  newest = 'new',
}

/** 素材类型映射 */
export const MeituAnalyzeContentType = {
  /** 创意图片 */
  1: 'creative',
  /** 视频 */
  2: 'video',
  /** 字体 */
  3: 'font',
  /** 编辑图片 */
  4: 'editorial',
  /** 企业资源 */
  5: 'dam',
  /** 音乐 */
  6: 'music',
  /** 版权 */
  7: 'ip',
}

/** 素材详情页点击按钮类型 */
export enum MeituAnalyzeBuyButtonType {
  /** 立即购买 */
  BuyNow = 'buy_now',
  /** 加入购物车 */
  BuyList = 'buy_list',
  /** 收藏 */
  Collect = 'collect',
  /** 取消收藏 */
  UnCollect = 'un_collect',
  /** 在线编辑 */
  Edit = 'edit',
  /** 举报 */
  Report = 'report',
  /** 下载素材 */
  DownloadSample = 'download_sample',
}

/** 立即购买页面名称 */
export enum MeituAnalyzeBuyPageName {
  /** 套餐弹窗（图片下载） */
  BuyPopWindow = 'buy_pop_window',
  /** 流量套餐页面 */
  BuyServicePage = 'buy_service_page',
}

/** 组织类型 */
export enum MeituAnalyzeOrganizationType {
  /** 企业*/
  Organization = 'organization',
  /** 个人*/
  Person = 'person',
}

/** 弹窗按钮类型 */
export enum MeituAnalyzePopBtnType {
  /**
   * 取消
   */
  Cancel = 'cancel',
  /**
   * 下载
   */
  Download = 'download',
  /**
   * 提交
   */
  Submit = 'submit',
}
/** 触发类型 */
export enum MeituAnalyzeTriggerType {
  /**
   * 加购物车
   */
  ShopList = 'shop_list',
  /**
   * 下载
   */
  Download = 'download',
  /**
   * 搜索结果页面加购物车
   */
  SearchResultShopList = 'search_result_shop_list',
}

/** 布尔映射类型 */
export enum MeituAnalyzeBooleanType {
  /**
   * true
   */
  true = '1',
  /**
   * false
   */
  false = '0',
}

/** 专题分类枚举 */
export enum MeituAnalyzeImageSubjectPageType {
  All = '全部',
  CreativeTopic = '创意专题',
  IndustrySelection = '行业精选',
}

export enum MeituAnalyzeNavName {
  /**
   * 创意图片
   */
  creative = '创意图片',
  /**
   * 视频
   */
  video = '视频',
  /**
   * 音乐
   */
  music = '音乐',
  /**
   * 字体
   */
  font = '字体',
  /**
   * IP授权
   */
  ip = 'IP授权',
  /**
   * AI助手
   */
  'ai-assistant' = 'AI助手',
  /**
   * 数字资产
   */
  dam = '数字资产',
  /**
   * 设计定制
   */
  creation = '设计定制',
  /**
   * 个人中心
   */
  account = '个人中心',
}

/** AI助手按钮类型*/
export enum MeituAnalyzeAiAssistantType {
  /**
   * 立即咨询
   * */
  applyNow = '立即咨询',
  /**
   * 立即使用
   * */
  useNow = '立即使用',
  /**
   * 立即体验
   * */
  tryItNow = '立即体验',
  /**
   * AI绘图
   * */
  aiDrawing = 'AI绘图',
  /**
   * 风格重绘
   * */
  styleRedraw = '风格重绘',
  /**
   * AI扩图
   * */
  expand = 'AI扩图',
  /**
   * 智能抠图
   * */
  cutout = '智能抠图',
}

/** AI助手功能页面名称 */
export enum MeituAnalyzeAiAssistantPageName {
  /**
   * AI绘图
   * */
  text2img = 'AI绘图',
  /**
   * 风格重绘
   * */
  img2img = '风格重绘',
  /**
   * AI扩图
   * */
  imageExtension = 'AI扩图',
  /**
   * 智能抠图
   * */
  cutout = '智能抠图',

  /**
   * 垂类模型
   * */
  verticalModel = '垂类模型',
}

/** AI助手按钮类型*/
export enum MeituAnalyzeAiAssistantButtonType {
  /**
   * 去商用
   * */
  business = '去商用',
  /**
   * AI扩图
   * */
  extend = 'AI扩图',
  /**
   * 再编辑
   * */
  reEdit = '再编辑',
  /**
   * 变清晰
   * */
  getClear = '变清晰',
  /**
   * 下载
   * */
  download = '下载',
  /**
   * 删除
   * */
  delete = '删除',
}

/**
 * 对账明细付费类型
 */
export enum PaidType {
  // 预付费
  Pre = 0,
  // 后付费
  Post = 1,
}

// 来源模块
export enum MeituAnalyzeFromModule {
  ResultList = '结果列表',
  ViewOtherWorks = '查看作者其他作品',
  SimilarImages = '相似图片',
  SameModel = '相同模特',
  SameGroup = '相同图组',
  SameContributorGroup = '相同作者图组',
}

// 来源页面
export enum MeituAnalyzeSourcePage {
  SearchResultPage = '搜索结果页',
  DetailPage = '详情页',
  AuthorOtherWorksPage = '作者其他作品页',
  SimilarImagesPage = '相似图片页',
  InspirationAlbumPage = '灵感图册页',
  SameModelPage = '相同模特',
  ImageGroupPage = '图组',
  GraphPage = '以图搜图页',
}

// 图片视频详情页作者分类标签点击类型
export enum MeituAnalyzeClickType {
  Author = '作者',
  Category = '分类',
}
