// node api
export const API_LOGOUT = '/logout'
export const API_THIRD_LOGIN_LIST = '/third_login_list'
export const API_CUSTOM_SETTINGS = '/custom-settings'
export const API_LOGIN_TEMPORARY = '/user/zhrmt/auth'

// plus api

// common

export const INDEX_FOOTER = '/index/footer'
export const INDEX_APPLY = '/index/apply'
export const INDEX_REGIONS = '/index/regions'
export const INDEX_INDUSTRIES = '/index/industries'
export const API_REPORT = '/user/report'
export const API_REPORT_CATEGORIES = '/user/report-category'

// image
/** 图片搜索，上传相似图片 */
export const API_UPLOAD_SIMILAR = '/file/upload/similar'

/** 下载已购买图片 */
export const API_DOWNLOAD = '/purchase/download'

/** 下载已购买授权书 */
export const API_DOWNLOAD_WARRANT = '/purchase/download/warrant'
/** 下载plus已购买授权书 */
export const API_PLUS_DOWNLOAD_WARRANT = '/hellorf/download-warrant'

// creative
/** 创意图片搜索 */
export const API_IMAGE_SEARCH = '/image/search'

/** 创意图片搜索智能提示 */
export const API_IMAGE_INTELLIGENT_SEARCH = '/index/suggest'

/** 创意图片详情 */
export const API_IMAGE_SHOW = '/image/show'

/** 创意图片简单详情 */
export const API_IMAGE_MINI_SHOW = '/image/mini-show'

/** 创意图片类似图片 */
export const API_IMAGE_SIMILAR = '/image/similar'

/** 创意图片供图作者信息 */
export const API_IMAGE_CONTRIBUTOR = '/image/contributor'

/** 创意图片图片中模特信息 */
export const API_IMAGE_MODEL = '/image/model'

/** 创意图片图片中组图信息 */
export const API_IMAGE_PICTURES = '/ip/image-group/get-image-group-by-id'

/** 创意图片全部组图 */
export const API_IMAGE_ALL_PICTURES = '/ip/image-group/get-all-image-group'

/** 创意图片全部组图提交商机 */
export const API_IMAGE_PICTURES_BUSINESS = '/ip/image-group/submit-opportunity'

/** 创意图片组图详情 */
export const API_IMAGE_PICTURES_DETAIL = '/ip/image-group/image-group-detail'

/** 创意图片新鲜设计列表 */
export const API_CREATIVE_DAILY_DESIGNS = '/image/article/daily-designs'

/** 创意图片新鲜设计详情 */
export const API_CREATIVE_DAILY_DESIGN_DETAIL = '/image/article/daily-design'

/** 创意图片新鲜设计 同系列其他图册 */
export const API_CREATIVE_DAILY_DESIGN_OTHER_TOPICS = '/image/topic/search'

/** 创意图片 - 灵感图册 - 分类下图册列表 */
export const API_CREATIVE_INSPIRATIONS = '/image/topic/inspiration'

/** 创意图片 - 灵感图册 - 分类 */
export const API_CREATIVE_INSPIRATION_CATEGORIES = '/image/topic/categories'

/** 创意图片 - 灵感图册 - Banner */
export const API_CREATIVE_INSPIRATION_BANNER = '/image/topic/banner '

/** 创意图片 - 灵感图册 - TOP5 */
export const API_CREATIVE_INSPIRATIONS_TOP5 = '/image/topic/top5'

/** 创意图片 - 灵感图册 - 图集详情 */
export const API_CREATIVE_INSPIRATION_DETAIL = '/image/topic/detail'

/** 创意图片 - 灵感图册 - 图集详情中相关图册 */
export const API_CREATIVE_INSPIRATION_RELATED_TOPICS = '/image/topic/others'

/** 创意图片 - 灵感图册 - 分类下相关图册 */
export const API_CREATIVE_INSPIRATION_TOPICS = '/image/topics'

/** 创意图片 - 灵感图册 - 灵感图册搜索 */
export const API_CREATIVE_INSPIRATION_TOPICS_SEARCH = '/image/topic/search'

/** 创意图片 - 首页 - 新图速递 */
export const API_CREATIVE_NEW_IMAGES = '/image/new-images'

/** 创意图片 - 首页 - 精选美图Banner */
export const API_CREATIVE_BANNER = '/image/banner'

/** 创意图片 - 首页 - 大神精选Banner */
export const API_CREATIVE_GOD_IMAGES = '/image/god-images'

/** 创意图片 - 首页 - 热搜分类 */
export const API_CREATIVE_KEYWORD_COLUMNS = '/image/keyword-columns'

/** 创意图片 - 专题 - 获取前几个专题 top后跟数字 */
export const API_CREATIVE_INVENTIONS = '/image/subject/top'

/** 创意图片 - 首页 - 搜索专题图册 */
export const API_CREATIVE_INVENTION_SEARCH = '/image/subject/search'

/** 创意图片 - 首页 - 专题详情 */
export const API_CREATIVE_INVENTION_DETAIL = '/image/subject/detail'

/** 创意图片 - 首页 - 专题 */
export const API_CREATIVE_SUBJECTS = '/image/subject/index'

/** 创意图片 - 作者页 - 图组 */
export const API_PROVIDER_IMAGE_GROUP = '/image/provider-image/group'

/** 创意图片 - 作者页 - 图组 - 详情 */
export const API_PROVIDER_IMAGE_GROUP_DETAIL = '/image/contributor-group-images'

// editorial
export const API_EDITORIAL_SEARCH_IMAGE = '/editorial/search/image'
export const API_EDITORIAL_SEARCH_GROUP = '/editorial/search/group'
export const API_EDITORIAL_CATEGORIES = '/editorial/categories'
export const API_EDITORIAL_GROUP = '/editorial/group'
export const API_EDITORIAL_SHOW = '/editorial/show'
export const API_EDITORIAL_BATCH_DOWNLOAD = '/editorial/batch-download'
export const API_EDITORIAL_CAROUSELS = '/editorial/carousels'
export const API_EDITORIAL_TOP_RECOMMEND = '/editorial/top-recommend'
export const API_EDITORIAL_EDITOR_RECOMMEND = '/editorial/editor-recommend'
export const API_EDITORIAL_SCROLL_NEWS = '/editorial/scroll-news'
export const API_EDITORIAL_TOPICS = '/editorial/topics'
export const API_EDITORIAL_TOPIC_DETAIL = '/editorial/topic/detail'
export const API_EDITORIAL_TOPIC_CHANNELS = '/editorial/topic/channels'
export const API_EDITORIAL_BATCH_DOWNLOAD_QUICKLY = '/editorial/smart-download'
export const API_EDITORIAL_CATEGORY_DETAIL = '/editorial/category-detail'
export const API_EDITORIAL_DOWNLOAD_SAMPLE = '/editorial/batch-comp' // 批量下载小样图

// font
export const API_FONT_BANNER = '/font/banner'
export const API_FONT_SHOW = '/font/show'
export const API_FONT_SEARCH = '/font/search'
export const API_FONT_SEARCH_PREVIEW = '/font/search/preview' // 字体预览模式
export const API_FONT_CATEGORIES = 'font/categories'
export const API_FONT_STYLES = 'font/styles'
export const API_FONT_CODES = 'font/codes'
export const API_FONT_DESIGN_UPLOAD = '/font/design-upload'
export const API_FONT_DESIGN = '/font/design'
export const API_HOT_FONTS = '/font/hot-font'
export const API_FONTS_ARTICLES = '/font/articles'
export const API_FONTS_ARTICLE_DETAILS = '/font/article'

// cart
export const API_CART = '/cart'
export const API_CART_UPDATE = '/cart/update'
export const API_CART_UPDATE_BATCH = '/cart/update-batch'
export const API_CART_DESTROY = '/cart/destroy'
export const API_CART_UPDATE_AUTH = '/cart/update-batch'
export const API_CART_UPDATE_PERMISSION = '/cart/update'
export const API_IMAGE_SEARCH_SIMILAR = '/image/simi-search'
export const API_IMAGE_SEARCH_SIMILAR_UPLOAD = '/image/simi-search/upload'
export const API_IMAGE_CATEGORIES = '/image/categories'
export const API_IMAGE_TOPIC_SEARCH = '/image/topic/search'
export const API_CART_ADD_BATCH = '/cart/batch-add'
export const API_CART_ADD_INTO_COLLECTION = '/cart/move-to-favorite'
export const API_CART_COUNT = '/cart/count'

// purchase
export const API_PURCHASE_BATCH = '/purchase/batch' // 批量购买
export const API_PURCHASE = '/purchase' // 单个购买
export const API_DOWNLOAD_SAMPLE = '/purchase/comp' // 下载小样图
export const API_PURCHASE_SAMPLES = '/comp-purchases' // 小样购买记录
export const API_PURCHASES = '/purchases' // 购买记录
export const API_HAS_HELLORF_PURCHASES = '/user/has_hellorf_purchase' // 是否有微利购买记录
export const API_PURCHASE_UPDATE_TAGS = '/purchase/update-tags' // 更新标签
export const API_PURCHASE_BATCH_ADD_TAGS = '/purchase/batch-add-tags' // 批量添加标签
export const API_PURCHASE_EXPORT_RECORDS = '/purchases/export' // 导出Excel
export const API_PURCHASE_DOWNLOAD_PACKAGE = '/purchase/package' // 批量素材下载
export const API_PURCHASE_WARRANTS = '/purchase/warrants' // 详情页授权列表
export const API_HELLORF_PURCHASE_WARRANTS = '/user/hellorf-warrants' // 微利授权列表

// permissions
export const API_PRODUCT_PERMISSIONS = '/permission'
export const API_SAMPLE_PERMISSION = '/permission/comp'
export const API_PERMISSIONS_LIST = '/permission/list'
export const API_DEFAULT_PERMISSION = '/permission/default'
export const API_COMBO_PERMISSION = '/permission/plus-hellorf-permissions'
export const API_ALL_COMBO_PERMISSION = '/permission/plus-hellorf-permissions-all'

// user-warrant
export const API_USER_WARRANT = '/user-warrant'
export const API_USER_WARRANT_ADD = '/user-warrant/search'
export const API_USER_WARRANT_COMPANY_SEARCH = '/user-warrant/search-company'
export const API_USER_WARRANT_ADD_TO_HISTORY = '/user-warrant/open'
export const API_USER_WARRANT_CAPTCHA = '/user-warrant/captcha'

// download
export const API_DOWNLOAD_IMAGE = '/purchase/download'
export const API_COMP_PURCHASE = '/comp-purchases'

// events

export const API_EVENT_LISTS = '/operate-activities'
export const API_EVENT_MENUS = '/operate-activity/categories'

// user info
export const API_USER_INFO = '/user/info'
export const API_USER_CHILD_USERS = '/user/child-users'
export const API_USER_PERMISSIONS = '/user/get-permissions'
export const API_USER_WARRANTS = '/user/get-warrants'
export const API_USER_UPDATE_INFO = '/user/update'
export const API_USER_ACCOUNT = '/user/account'
export const API_USER_CONSUMPTION = '/user/consumption'
export const API_USER_PERMISSION_LIST = '/user/permissions'
export const API_USER_ALL_PERMISSION_LIST = '/user/all-plus-hellorf-permission'
export const API_USER_PLUS_RF_PERMISSION_LIST = '/user/base-plus-hellorf-permission'
export const API_USER_PREFERENCES = '/user/preferences'

// 微利已购买记录
export const API_HELLORF_PURCHASE = '/hellorf/purchases'
// 微利授权书下载
export const API_HELLORF_WARRANT_DOWNLOAD = '/hellorf/download-warrant'
// 微利创建授权书
export const API_HELLORF_WARRANT_CREATE = '/hellorf/user-warrant'
// 微利购买素材下载
export const API_HELLORF_PURCHASED_DOWNLOAD = '/hellorf/purchased'

// account/subsidiary

/** 成员列表 */
export const API_ACCOUNT_MEMBERS = '/user/members'

/** 成员列表-权限明细 */
export const API_ACCOUNT_PERMISSION_DETAIL = '/user/permission-detail'

/** 成员列表-plus微利套餐权限明细列表 */
export const API_ACCOUNT_COMBO_PERMISSION_LIST = '/user/plus-hellorf-permission'

/** 权限明细-批量开启 */
export const API_ACCOUNT_PERMISSION_BATCH_OPEN = '/user/permission-detail/batch-open'

/** 套餐权限明细-批量开启 */
export const API_ACCOUNT_COMBO_PERMISSION_BATCH_OPEN = '/user/plus-hellorf-permission/batch-open'

/** 权限明细-批量关闭 */
export const API_ACCOUNT_PERMISSION_BATCH_CLOSE = '/user/permission-detail/batch-close'

/** 套餐权限明细-批量关闭 */
export const API_ACCOUNT_COMBO_PERMISSION_BATCH_CLOSE = '/user/plus-hellorf-permission/batch-close'

/** 权限明细-批量设置数量限制 */
export const API_ACCOUNT_PERMISSION_BATCH_SET_LIMIT = '/user/permission-detail/batch-limit'

/** 套餐权限明细-批量设置数量限制 */
export const API_ACCOUNT_COMBO_PERMISSION_BATCH_SET_LIMIT = '/user/plus-hellorf-permission/batch-limit'

/** 权限明细-批量取消数量限制 */
export const API_ACCOUNT_PERMISSION_BATCH_CANCEL_LIMIT = '/user/permission-detail/batch-cancel-limit'

/** 权限明细-批量取消套餐授权数量限制 */
export const API_ACCOUNT_COMBO_PERMISSION_BATCH_CANCEL_LIMIT = '/user/plus-hellorf-permission/batch-cancel-limit'

/** 成员列表-导出 */
export const API_ACCOUNT_MEMBERS_EXPORT = '/user/members/export'

/** 成员架构 */
export const API_ACCOUNT_UNDER_MEMBER = '/user/children'

/** 修改上级成员 */
export const API_ACCOUNT_UPDATE_PARENT = '/user/update-parent'

/** 修改上级成员 */
export const API_ACCOUNT_BATCH_UPDATE_PARENT = '/user/members/batch-update-parent'

/** 成员权限列表 */
export const API_ACCOUNT_MEMBER_PERMISSIONS = '/user/member-permissions'

/** 权限状态轮询 */
export const API_ACCOUNT_MEMBER_PERMISSIONS_POLLING = '/user/dam-permission-status'

/** 成员DAM权限列表 */
export const API_ACCOUNT_DAM_PERMISSIONS = '/user/dam-permission'

/** 成员管理-批量编辑基本信息 */
export const API_ACCOUNT_MEMBER_BATCH_EDIT_BASIC_INFO = '/user/members/batch-edit'

/** 添加成员 */
export const API_ACCOUNT_SUBSIDIARY_CREATE = '/user/add-members'

/** 成员详情  /sub-account/show/:id */
export const API_ACCOUNT_SUBSIDIARY_DETAIL = '/user/member'

/** 修改成员  /user/update/:id */
export const API_ACCOUNT_SUBSIDIARY_MODIFY = '/user/member-update'

/** 删除成员  /user/destroy/:id */
export const API_ACCOUNT_SUBSIDIARY_DELETE = '/user/destroy'

/** 冻结成员  /user/freeze/:id */
export const API_ACCOUNT_SUBSIDIARY_FREEZE = '/user/freeze'

/** 恢复成员  /user/unfreeze/:id */
export const API_ACCOUNT_SUBSIDIARY_RECOVER = '/user/unfreeze'

/** 邀请成员，发送邮件 /user/invite/:id */
export const API_ACCOUNT_SUBSIDIARY_INVITE = '/user/invite'

/** 激活子帐号  /sub-account/activation/:id */
export const API_ACCOUNT_SUBSIDIARY_ACTIVE = '/sub-account/activation'

/** 子帐号权限列表 */
export const API_ACCOUNT_SUBSIDIARY_PERMISSIONS = '/sub-account/permissions'

// account/billing

/** 预付费用户资金流水 */
export const API_ACCOUNT_BILLING_FLOW = '/user/get-cash-flow'

/** 后付费用户资金流水 */
export const API_ACCOUNT_POST_PAID_BILLING_FLOW = '/user/get-postpaid-cash-flow'

/** 用户资金流水筛选项 */
export const API_ACCOUNT_BILLING_FLOW_FILTERS = '/user/get-cash-flow-filters'

/** 预付费用户资金统计 */
export const API_ACCOUNT_BILLING_STATISTICS = '/user/get-money-statistics'

/** 后付费用户资金统计 */
export const API_ACCOUNT_POST_PAID_BILLING_STATISTICS = '/user/get-postpaid-money-statistics'

// account/collection

/** 创建收藏夹 */
export const API_COLLECTION_CREATE = '/favorite/group'

/** 获取收藏夹列表 */
export const API_COLLECTION_LIST = '/favorite/groups'

/** 删除收藏夹 */
export const API_COLLECTION_DELETE = '/favorite/delete-group'

/** 重命名收藏夹 */
export const API_COLLECTION_RENAME = '/favorite/rename-group'

/** 获取分享数据 */
export const API_COLLECTION_SHARE_LIST = '/favorite/show-share'

/** 获取收藏夹内素材列表 */
export const API_COLLECTION_MATERIAL_LIST = '/favorites'

/** 添加素材至收藏夹 */
export const API_COLLECTION_MATERIAL_ADD = '/favorite/add'

/** 添加素材至收藏夹（批量） */
export const API_COLLECTION_MATERIAL_BATCH_ADD = '/favorite/batch-add'

/** 添加素材至收藏夹（分享收藏夹） */
export const API_COLLECTION_BY_SHARE = '/favorite/batch-add/by-share'

/** 删除素材从收藏夹 */
export const API_COLLECTION_MATERIAL_CANCEL = '/favorite/cancel-by'

/** 删除素材从收藏夹 */
export const API_COLLECTION_MATERIAL_CANCEL_BATCH = '/favorite/cancel'

/** 移动素材至其它收藏夹 */
export const API_COLLECTION_MATERIAL_MOVE = '/favorite/move'

export const API_COLLECTION_COUNT = '/favorite/group-count'

/** 最近操作的收藏夹 */
export const API_COLLECTION_LAST = '/favorite/last'

// account/history

/** 获取历史记录列表 */
export const API_HISTORY_LIST = '/user/visited'

// video
/** 视频banner */
export const API_VIDEO_BANNER = '/video/banner'

/** 视频详情 */
export const API_VIDEO_SHOW = '/video/show'

/** 视频搜索 */
export const API_VIDEO_SEARCH = '/video/search'

/** 相同作者 */
export const API_VIDEO_CONTRIBUTOR = '/video/contributor'

/** 相似视频 */
export const API_VIDEO_SIMILAR = '/video/similar'

/** 相同模特 */
export const API_VIDEO_MODEL = '/video/model'

/** 视频分类 */
export const API_VIDEO_CATEGORY = '/video/categories'

/** 视频 - 热门剪辑 */
export const API_VIDEO_HOT = '/video/hot-video'

/** 视频 - 轮播 视频专题 */
export const API_VIDEO_INVENTIONS = '/video/subject'

/** 视频 - 轮播 视频专题详情 */
export const API_VIDEO_INVENTION_DETAIL = '/video/subject/detail'

/** 视频 - 轮播 视频专题搜索 */
export const API_VIDEO_INVENTION_SEARCH = '/video/subject/search'

/** 视频 - 带图片关键字 */
export const API_VIDEO_KEYWORDS = '/video/video-keywords'

/** 视频 - 推荐的微利视频关键词组 */
export const API_VIDEO_DISTRACT_KEYWORDS = '/video/distract-keywords'

/** 视频 - 视频搜索关键词组 */
export const API_VIDEO_KEYWORD_COLUMNS = '/video/keyword-columns'

/** 视频 - 全部视频精选 */
export const API_VIDEO_TOPICS = '/video/topics'

/** 视频 - 指定专题详情 */
export const API_VIDEO_TOPIC_DETAIL = '/video/topic/detail'

/** 视频 - 相关精选视频 */
export const API_VIDEO_FEATURED = '/video/topic/search'

/** 视频 - 精选分类 */
export const API_VIDEO_TOPIC_CATEGORIES = '/video/topic/categories'

/** DAM - start */

/** 获取oss参数 */
export const API_DAM_OSS_PARAMS = '/dam/node/upload-params'

/** 获取qiniu参数 */
export const API_DAM_QINIU_PARAMS = '/dam/node/upload-token'

/** 保存文件 */
export const API_DAM_SAVE_IMAGE = '/dam/image'

/** 批量更新文件 */
export const API_DAM_BATCH_UPDATE_IMAGE = '/dam/image/batch-modify'

/** 获取文件 */
export const API_DAM_GET_IMAGE = '/dam/image'

/** 获取目录层级详情 */
export const API_DAM_GET_PATH_ANCESTRY = '/dam/path/ancestry'

/** DAM - 设置 - 所有分类 */
export const API_DAM_SETTING_ALL_CATEGORY = '/dam/setting/categories'

/** DAM - 图片详情 */
export const API_DAM_IMAGE = '/dam/image'

/** DAM - 我的管理权限 */
export const API_DAM_MY_PERMISSIONS = '/dam/my-permissions'

/** DAM - 管理员列表 */
export const API_DAM_MANAGERS = '/user/dam-managers'

/* 设置管理员权限 */
export const API_DAM_SETTING_PERMISSION = '/user/dam-permission'

/** DAM - 布局/文件夹配置  */
export const API_DAM_SETTING = '/dam/setting'

/** DAM - 设置 - 所有分类 */
export const API_DAM_SETTING_CATEGORIES = '/dam/setting/categories'

/** DAM - 设置 - 创建分类 */
export const API_DAM_SETTING_CATEGORY_ADD = '/dam/setting/category'

/** DAM - 设置 - 删除分类 */
export const API_DAM_SETTING_CATEGORY_DELETE = '/dam/setting/category/delete'

/** DAM - 设置 - 更新分类 */
export const API_DAM_SETTING_CATEGORY_UPDATE = '/dam/setting/category/update'

/** DAM - 设置 - 获取所有用途 */
export const API_DAM_SETTING_LICENSES = '/dam/setting/licenses'

/** DAM - 设置 - 创建用途 */
export const API_DAM_SETTING_LICENSE_ADD = '/dam/setting/license'

/** DAM - 设置 - 删除用途 */
export const API_DAM_SETTING_LICENSE_DELETE = '/dam/setting/license/delete'

/** DAM - 设置 - 更新用途 */
export const API_DAM_SETTING_LICENSE_UPDATE = '/dam/setting/license/update'

/** DAM - 设置 - 所有菜单 */
export const API_DAM_SETTING_MENUS = '/dam/setting/menus'

/** DAM - 设置 - 创建菜单 */
export const API_DAM_SETTING_MENU_ADD = '/dam/setting/menu'

/** DAM - 设置 - 删除菜单 */
export const API_DAM_SETTING_MENU_DELETE = '/dam/setting/menu/delete'

/** DAM - 设置 - 更新菜单 */
export const API_DAM_SETTING_MENU_UPDATE = '/dam/setting/menu/update'

/** DAM - 设置 - 更新菜单排序 */
export const API_DAM_SETTING_MENU_SORT_UPDATE = '/dam/dir/sort'

/** DAM - 设置 - 所有来源 */
export const API_DAM_SETTING_SOURCES = '/dam/setting/sources'

/** DAM - 设置 - 创建来源 */
export const API_DAM_SETTING_SOURCE_ADD = '/dam/setting/source'

/** DAM - 设置 - 删除来源 */
export const API_DAM_SETTING_SOURCE_DELETE = '/dam/setting/source/delete'

/** DAM - 设置 - 更新来源 */
export const API_DAM_SETTING_SOURCE_UPDATE = '/dam/setting/source/update'

/** 空间统计 */
export const API_DAM_CAPACITY = '/dam/statistics/capacity'

/** 上传统计-按日期 */
export const API_DAM_STATISTICS_UPLOAD_DATE = '/dam/statistics/upload/by-date'

/** 上传统计-按成员 */
export const API_DAM_STATISTICS_UPLOAD_MEMBER = '/dam/statistics/upload/by-member'

/** 下载统计-按日期 */
export const API_DAM_STATISTICS_DOWNLOAD_DATE = '/dam/statistics/download/by-date'

/** 下载统计-按成员 */
export const API_DAM_STATISTICS_DOWNLOAD_MEMBER = '/dam/statistics/download/by-member'

/** 下载统计-按成员 */
export const API_DAM_STATISTICS_DOWNLOAD_RESOURCE = '/dam/statistics/download/by-resource'

/** 搜索/获取数据 */
export const API_DAM_SEARCH = '/dam/search'

/** 轮询是否生成缩略图 */
export const API_DAM_PREVIEW = '/dam/image/crop-status'

/** 账号列表 */
export const API_DAM_ACCOUNT = '/dam/search/accounts'

/** 被授权方列表 */
export const API_DAM_WARRANT = '/dam/search/warrant-names'

/** 新建文件夹 */
export const API_DAM_CREATE = '/dam/dir'
/** 判断文件夹是否存在 */
export const API_DAM_CHECK_DIR_EXIST = 'dam/check-dir-exist'

/** 重命名文件/文件夹 */
export const API_DAM_RENAME = '/dam/dir/rename'

/** 删除文件/文件夹 */
export const API_DAM_REMOVE = '/dam/node/remove'

/** 彻底删除文件/文件夹 */
export const API_DAM_DELETE = '/dam/node/delete'

/** 面包屑 */
export const API_DAM_CRUMBS = '/dam/path/ancestry'

/* 目录层级树 */
export const API_DAM_TREE = '/dam/path-tree'

/* 复制文件 */
export const API_DAM_COPY = '/dam/node/copy'

/* 转移文件 */
export const API_DAM_TRANSFER = '/dam/node/move'

/** 最大层级 */
export const API_DAM_DEEPEST_LEVEL = '/dam/deepest-level'

/** 获取DAM帮助所有分类 */
export const API_DAM_FAQ_CATEGORIES = '/dam/faq/categories'

/** 获取DAM帮助所有文章 */
export const API_DAM_FAQ_ARTICLES = '/dam/faq'

/** 提交DAM帮助文章意见反馈 */
export const API_DAM_FAQ_FEEDBACK = '/user/feedback'

/** 打包下载 */
export const API_DAM_PACKAGE = '/dam/package'

/** 视频预览播放地址 */
export const API_DAM_VIDEO_PREVIEW = '/dam/image/preview-download-url'

/** 统计客户线索 */
export const API_DAM_CLUE = '/dam/clue'

/** DAM申请 */
export const API_DAM_APPLY = '/dam/apply'

/** DAM状态判断 */
export const API_DAM_STATUS = '/dam/status'

/** DAM设置多久删除 */
export const API_DAM_SET_DELETE_DELAY = '/dam/delete-delay'

/** DAM数字资产权限列表 */
export const API_DAM_PERMISSION_LIST = '/user/user-dam-permissions'

/** DAM目录权限列表 */
export const API_DIRECTORY_PERMISSION_LIST = 'user/dam-directory-permissions'

/** DAM更新数字资产权限 */
export const API_UPDATE_DAM_PERMISSION = 'user/update-user-dam-permission'

/** DAM更新目录权限 */
export const API_UPDATE_DIRECTORY_PERMISSION = 'user/update-user-dam-directory-permission'

/** DAM获取审核列表 */
export const API_REVIEW_LIST = 'dam/audits'

/** dam审核-审核通知设置 */
export const API_NOTIFICATION_CONFIG = 'dam/notification-permission'

/** dam审核-素材过期通知 */
export const API_EXPIRED_NOTIFICATION = 'dam/update-expired-notification-permission'

/** dam审核-审核通知 */
export const API_AUDIT_NOTIFICATION = 'dam/update-audit-notification-permission'

/** 获取dam-超级管理员 */
export const API_DAM_ADMIN = 'dam/dam-admin'

/** dam审核-获取审核人员列表 */
export const API_REVIEW_MEMBERS = 'dam/audit-members'

/** dam审核-获取更新审核通知 */
export const API_UPDATE_REVIEW = 'dam/update-notification-permission'

/** dam审核-通过审核 */
export const API_PASS_REVIEW = 'dam/audit-pass'

/** dam审核-拒绝审核 */
export const API_REJECT_REVIEW = 'dam/audit-reject'

/** dam审核-提交审核 */
export const API_SUBMIT_REVIEW = 'dam/submit-audit'

/** dam审批-获取审批设置 */
export const API_APPROVAL_SETTING = 'dam/approval/setting'

/** dam审批-审批更新 */
export const API_APPROVAL_UPDATE = 'dam/approval/update'

/** dam审批-获取审批流 */
export const API_APPROVAL_FLOW = 'dam/approval/flow'

/** dam审批-按照部门维度获取成员架构 */
export const API_APPROVAL_FLOW_MEMBERS = 'dam/approval/department/members'

/** dam审批-添加节点 */
export const API_APPROVAL_NODE = 'dam/approval/node'

/** dam审批-删除节点 */
export const API_APPROVAL_NODE_DELETE = 'dam/approval/node/delete'

/** dam审批-更新节点 */
export const API_APPROVAL_NODE_UPDATE = 'dam/approval/node/update'

/** dam审批-移动节点 */
export const API_APPROVAL_NODE_MOVE = 'dam/approval/node/move'

/** dam审批-上传/下载审批申请(支持批量) */
export const API_APPROVAL_REQUEST = 'dam/approval/request'

/** dam审批-审批阶段审批人自选 */
export const API_APPROVAL_APPROVERS = 'dam/approval/approvers'

/** dam审批-待审批/已审批列表 */
export const API_APPROVAL_LIST = 'dam/approval/list'

/** dam审批-已发起列表 */
export const API_APPROVAL_REQUEST_LIST = 'dam/approval/request/list'

/** dam审批-审核操作通过前检查 */
export const API_APPROVAL_PASS_CHECK = 'dam/approval/pass-check'

/** dam审批-审核操作通过 */
export const API_APPROVAL_PASS = 'dam/approval/pass'

/** dam审批-审核操作拒绝 */
export const API_APPROVAL_REJECT = 'dam/approval/reject'

/** dam审批-审核操作撤回 */
export const API_APPROVAL_WITHDRAWAL = 'dam/approval/withdrawal'

/** dam审批-查看审批内容详情-审批流程 */
export const API_APPROVAL_REQUEST_FLOW = 'dam/approval/request/flow'

/** dam审批-查看审批内容详情 */
export const API_APPROVAL_REQUEST_DETAIL = 'dam/approval/request'

/** DAM - end */

/** 定制设计 线索提交 */
export const API_CREATION_POST_CLUE = 'https://gc-kernel.zcool.com.cn/api/hellorf-plus/clue'
/** 定制设计 线索提交 pre环境 */
export const PRE_API_CREATION_POST_CLUE = 'https://pre-gc-kernel.zcool.com.cn/api/hellorf-plus/clue'

/** CRM - start */

/** 获取报价单信息 */
export const CRM_INFO = '/index/quotation'

/** 发送短信验证码 */
export const CRM_SEND_SMS_CODE = '/index/quotation/quotation-sms'

/** 获取内容 */
export const CRM_GET_CONTENTS = '/index/quotation/file'

/** 通过 */
export const CRM_PASS = '/index/quotation/pass'

/** 驳回 */
export const CRM_REJECT = '/index/quotation/reject'

/** 驳回 */
export const CRM_FEEDBACK = '/index/quotation/reason'

/** 下载pdf */
export const CRM_DOWNLOAD_PDF = '/index/quotation/download'

/** CRM - end */

/** Music - start */

/** 音乐banner */
export const AUDIO_BANNER = '/audio/banner'

/** 标签tags */
export const AUDIO_HOT_TAGS = '/audio/categories'

/** 供应商列表 */
export const AUDIO_PROVIDERS = '/audio/providers'

/** 音乐首页-推荐-list */
export const AUDIO_INDEX_RECOMMEND = '/audio/rand'

/** 音乐首页-专题 */
export const AUDIO_INDEX_TOPICS = '/audio/subject/index'

/** 音乐-全部专辑*/
export const AUDIO_ALBUMS_LISTS = '/audio/topics'

/** 音乐-全部专辑-分类*/
export const AUDIO_ALBUMS_CATEGORIES = '/audio/topic/categories'

/** 音乐-专题-搜索*/
export const AUDIO_INVENTION_SEARCH = '/audio/subject/search'

/** 音乐-专题-详情*/
export const AUDIO_INVENTION_DETAIL = '/audio/subject/detail'

/** ip banner */
export const IP_BANNER = '/ip/banner'

/** ip recommends */
export const IP_RECOMMENDS = '/ip/license/recommend'

/** ip license list */
export const IP_LICENSE_LIST = '/ip/license'

/** ip license detail */
export const API_IP_SHOW = '/ip/license'

/** ip business negotiate */
export const API_IP_BUSINESS_NEGOTIATE = '/ip/license/apply'

/** ip  categories */
export const IP_CATEGORIES = '/ip/categories'

/** ip  tags */
export const IP_TAGS = '/ip/tags'

/** ip 首页 - 热门推荐 */
export const IP_TOP_CATEGORIES = '/ip/recommend-category'

/** ip 首页 - 授权案例 */
export const IP_ARTICLES = '/ip/articles'

/** ip 详情 - 收藏夹详情 */
export const IP_FAVORITE_IMAGES = '/ip/related-pictures'

/** ip 搜索 */
export const IP_SEARCH = '/ip/license/search'

/** 充值订单 - 详情 */
export const PAYFOR_DETAIL = '/payment/order-show'

/** 充值订单 - 生成支付 */
export const PAYFOR_CREATE_WAY = '/payment/topay'

/** 希音相关接口 */
/** 获取订单接口 */
export const SHEIN_PACKAGE = '/shein/user/permission'

/** 发票列表 */
export const SHEIN_INVOICE_LIST = '/shein/user/invoice'

/** 订单列表 */
export const SHEIN_ORDER_LIST = '/shein/recharge'

/** 取消订单 */
export const SHEIN_CANCEL_ORDER = '/shein/recharge/cancel'

/** 申请普通发票 */
// export const SHEIN_CREATE_PLAIN_INVOICE = '/shein/user/create-plain-invoice'

/** 申请增值发票 */
export const SHEIN_CREATE_VAT_INVOICE = '/shein/user/create-vat-invoice'

/** 申请电子发票 */
export const SHEIN_CREATE_ELECTRONIC_INVOICE = '/shein/user/create-electronic-invoice'

/** 审核单个购买接口 */
export const SHEIN_PURCHASE = '/shein/purchase'

/** 审核批量购买接口 */
export const SHEIN_BATCH = '/shein/batch'

/** 修改发票邮箱 */
export const CHANGE_INVOICE_EMAIL = '/shein/user/send-invoice'

/** 下载发票 */
export const DOWNLOAD_INVOICE = '/shein/user/download-electronic-invoice'

/** 审核清单 */
export const SHEIN_AUDIT_LIST = '/shein/audit-list'
/** 希音相关接口 - end */

// aigc
export const API_AIGC_HISTORY = '/aigc/index'
export const API_AIGC_CREATE_PIC = '/aigc/submit-task/gen'
export const API_AIGC_PIC_RESULT = '/aigc/show'
export const API_AIGC_DOWNLOAD_TASK = '/aigc/submit-task/download'
export const API_AIGC_SAVE_DAM = '/aigc/submit-task/download-to-dam'
export const API_AIGC_DOWNLOAD_RESULT = '/aigc/show-download'
export const API_AIGC_UPLOAD_IMG = '/aigc/upload-image'
export const API_IMAGE_AIGC_UPLOAD = '/aigc/upload-image'
export const API_AIGC_LIMIT = '/aigc/check'
export const API_AIGC_APPLY = '/aigc/apply'

// 图片联想词
export const API_IMAGE_RECOMMEND = '/index/recommend'
/**
 * 选参考图 弹框
 */
// 获取购买记录接口
export const API_AIGC_IMAGE_PURCHASE = '/v1/api/image/purchased'

/**
图片搜索接口
*/
export const API_AIGC_IMAGE_SEARCH = '/v1/api/image/search'

/**
 * 获取图片下载地址接口*/
export const API_AIGC_IMAGE_DOWNLOAD_ADDR = '/v1/api/image/detail'

/**获取购单张买点数
 * */
export const API_AIGC_PURCHASE_POINTS_PER_IMAGE = '/v1/api/task/points_per_image'

/**
 * 用户鉴权接口
 */
export const API_AIGC_USER_AUTH = '/v1/api/user/auth'
/**
 * 下载图片和更改分辨率弹框
 */
// 获取图片信息
export const API_AIGC_IMAGE_INFO = '/v1/api/task/image_info'
// 图片下载
export const API_AIGC_IMAGE_DOWNLOAD = '/v1/api/task/image_download'
// 图片超分
export const API_AIGC_IMAGE_RESIZE = '/v1/api/task/image_resize'
// 图片超分信息查询
export const API_AIGC_IMAGE_RESIZE_INFO = '/v1/api/task/image_resize_info'
// 图片信息查询
export const API_AIGC_IMAGE_NFO = '/v1/api/task/info'
// 图片超分结果查询
export const API_AIGC_IMAGE_RESIZE_RES = '/v1/api/task/image_resize_query'
// 获取token的接口
export const API_AIGC_TOKEN = '/v1/api/common/get_token'
// 参考图上传钩子接口
export const API_AIGC_UPLOAD_HOOK = '/v1/api/image/init_image_upload_hook'
// ai助手
export const API_ASSISTANT_CREATE_RECORD = '/aigc'
export const API_ASSISTANT_CREATE_RECORD_DOWNLOAD = '/aigc/download'
export const API_ASSISTANT_DOWNLOAD_WARRENT = '/aigc/download-warrant'
export const API_ASSISTANT_ACCOUNT_CONSUMPTION_DETAILS = '/aigc/dot-statistic'
export const API_ASSISTANT_POINTS_MANAGE = '/aigc/dot-child'
export const API_ASSISTANT_POINTS_MANAGE_ITEM = '/aigc/set-dot'
export const API_ASSISTANT_POINTS_MANAGE_SET_POINTS = '/aigc/set-batch-dot'
export const API_ASSISTANT_CONSUMPTION_DETAILS = '/aigc/dot-consumption'
export const API_ASSISTANT_IMAGE_EXTENSION_TRACK_SOURCE = '/v1/api/task/relate_parent_hook'

// hellorf_session 换 zcool_mt
export const API_GET_MEITU_TOKEN = '/get-mt-token'

// shein 中间页登录
export const SHEIN_AUTO_LOGIN = '/shein/auto-login'

// 通过参考图 url 映射 id
export const API_AIGC_IMAGE_MAPPING = 'v1/api/image/init_image_id_by_url'

// 提交审核
export const API_AIGC_COMMERCIALIZE = '/v1/api/task/commercialize'

// 智能抠图 提交任务
export const API_AIGC_SUBMIT_CUTOUT = '/v1/api/task/image_matting'

// 智能抠图 任务详情
export const API_AIGC_CUTOUT_DETAIL = '/v1/api/task/image_matting_info'

// 智能抠图 历史记录
export const API_AIGC_CUTOUT_HISTORY = '/v1/api/task/image_matting_list'

// 智能抠图 删除图片
export const API_AIGC_CUTOUT_DELETE = '/v1/api/task/image_delete'

// 智能抠图 显著性检测
export const API_AIGC_CUTOUT_DETECT = '/v1/api/task/image_matting_sync'

// 智能抠图 交互分割
export const API_AIGC_CUTOUT_INTERACTION = '/v1/api/task/image_matting_interact_sync'

// 智能抠图 保存编辑图片
export const API_AIGC_CUTOUT_SAVE = '/v1/api/task/image_matting_save'

// 智能抠图 oss
export const API_AIGC_OSS_PARAMS = '/v1/api/image/obs_upload_params'

// ai 助手管理商用
export const API_COMMERCIALIZE_ASSISTANT = '/aigc/commercialize'

// dam 分享
export const API_DAM_NODE_SHARE = '/dam/node/share'

// dam 分享详情
export const API_DAM_SHARE_SHOW = '/dam-share/show'

// dam 分享获取token
export const API_DAM_SHARE_GET_TOKEN = '/dam-share/get-token'

// dam 分享搜索
export const API_DAM_SHARE_SEARCH = '/dam-share/search'

// dam 分享node详情
export const API_DAM_SHARE_NODE_DETAIL = '/dam-share/node-detail'

// dam 分享目录层级
export const API_DAM_SHARE_ANCESTRY_LIST = '/dam-share/ancestry-list'

// dam 分享文件下载（获取预览原始文件）
export const API_DAM_SHARE_DOWNLOAD = '/dam-share/download'

// dam 分享打包下载
export const API_DAM_SHARE_PACKAGE = '/dam-share/package'

/**
垂类模型侧边栏接口数据
*/
export const API_AIGC_MODEL_STYLE = '/v1/api/ai_model/style'

/**
垂类模型侧边栏接口数据
*/
export const API_AIGC_MODEL_IMAGE = '/v1/api/ai_model/image'

// 交付素材
// 保存图片
export const API_MATERIAL_DELIVERY_SAVE_IMAGE = '/v1/api/image-delivery/image-upload'
// 交付素材清单
export const API_MATERIAL_DELIVERY_LIST = '/v1/api/image-delivery/image-list'
// 导出交付素材CSV
export const API_EXPORT_MATERIAL_DELIVERY_LIST = '/v1/api/image-delivery/image-export'
