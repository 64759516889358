import React, { useContext, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import produce from 'immer'
import { palette, font } from '@zcool/theme'
import { zIndex } from '@zcool/util'
import Icon from 'components/ui/icon'
import TreeView from 'components/ui/tree-view'
import {
  ACTIONS,
  PLUS_MENUS,
  DAM_CONTENTS,
  DAM_CONTENTS_WITHOUT_AUTHORITY,
  ACCOUNT_CONTENTS,
  DAM_SUB_MENU_UPLOAD,
  DAM_SUB_MENU_APPROVAL_SETTINGS,
  DAM_SUB_MENU_APPROVAL_MANAGE,
  DAM_SUB_MENU_STATISTICS,
  DAM_SUB_MENU_SUBSIDIARY,
  DAM_SUB_MENU_SETTING,
  DAM_SUB_MENU_FAQ,
  sharedValues,
  SHEIN_ORDER,
  TEMPLATE,
  AIGC,
  AI_ASSISTANT,
} from 'shared/constants'
import { GlobalContext } from 'shared/context'
import { getUid } from 'helpers/util'
import { UserType, DAMMenuType, CustomMenuType, IsShowTemplate, IsShowAIGC, MeituAnalyzeNavName } from 'helpers/enums'
import { events } from 'helpers/event-emitter'
import { EditorialChannelsStore } from 'helpers/store'
import { getApprovalSetting, getDamSettings, getMenus, getMyPermissions } from 'api/dam'
import BackToHome from './back-home'
import Language from './language'
import { PlusGuideClick, safeExecute } from 'helpers/analyze-meitu'
import UpdateModal from 'components/dam/approval-settings/update-modal'

export default React.memo(Drawer)

const GC = [
  {
    id: 'nav.creation',
    path: '/creation',
    pattern: /\/creation/,
    glyph: 'nav-creation',
    type: 'creation',
    children: [],
  },
]

// const COPYRIGHT = [
//   {
//     id: 'nav.copyright',
//     path: 'https://www.zcoolip.com/?utm_source=hellorfplus&utm_medium=index&utm_campaign=navigation&utm_content=copyright',
//     pattern: /\\/,
//     glyph: 'nav-copyright',
//     target: '_blank',
//   },
// ]

interface BreadIds {
  breadIds?: Array<number>
}

function Drawer({
  children,
  channelId,
  breadIds = [],
  imgGray = false,
}: {
  children: React.ReactNode
  channelId?: number
  imgGray?: boolean
} & BreadIds) {
  const { asPath } = useRouter()

  const { settings } = useContext(GlobalContext)
  const hideMenu = settings.menuType === CustomMenuType.TypeOne
  const hasSubMenu = /\/(dam|account|editorial)/.test(asPath)
  const showBackToHome = /\/(account|editorial)/.test(asPath) && hideMenu
  const [isOpen, setIsOpen] = useState(false)

  const drawerCls: Array<any> = [hasSubMenu]

  if (settings.newNavbar || hideMenu) {
    drawerCls.push('new')
  } else {
    drawerCls.push('old')
  }

  if (isOpen) {
    drawerCls.push('opened')
  } else {
    drawerCls.push('closed')
  }

  if (imgGray) {
    drawerCls.push('img-gray')
  }

  useEffect(() => {
    if (settings.newNavbar) {
      events.on(ACTIONS.TOGGLE_DRAWER, setIsOpen)
    }

    return () => {
      events.off(ACTIONS.TOGGLE_DRAWER, setIsOpen)
    }
  }, [])

  function handleEnter(e: React.MouseEvent<HTMLUListElement>) {
    e.stopPropagation()
    if (!settings.newNavbar) {
      return
    }
    if (sharedValues.timer) {
      clearTimeout(sharedValues.timer)
    }
    if (!isOpen) {
      sharedValues.timer = setTimeout(() => {
        setIsOpen(true)
      }, 200)
    }
  }

  function handleLeave(e: React.MouseEvent<HTMLUListElement>) {
    e.stopPropagation()
    if (!settings.newNavbar) {
      return
    }
    if (sharedValues.timer) {
      clearTimeout(sharedValues.timer)
    }
    sharedValues.timer = setTimeout(() => {
      setIsOpen(false)
    }, 200)
  }

  return (
    <DrawerContainer className={drawerCls.join(' ')}>
      <aside className="aside">
        <Menus
          path={asPath}
          channelId={channelId}
          breadIds={breadIds}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        />
        {!settings.newNavbar && !hideMenu && <Language />}
        {showBackToHome && <BackToHome />}
      </aside>
      <section id="page-content">{children}</section>
    </DrawerContainer>
  )
}

let cachedPermissions = null

const Menus = React.memo(function ({
  path,
  channelId,
  breadIds = [],
  onMouseEnter,
  onMouseLeave,
}: {
  path: string
  channelId: number
  onMouseEnter: (e: React.MouseEvent<HTMLUListElement>) => void
  onMouseLeave: (e: React.MouseEvent<HTMLUListElement>) => void
} & BreadIds) {
  const [showDamNewTag, setShowDamNewTag] = useState(
    typeof localStorage !== 'undefined' ? localStorage.getItem('showDamNewTag') !== 'true' : false
  )
  const [showUpdateModal, setShowUpdateModal] = useState(false)

  const {
    currentUser: {
      parent_id,
      type,
      is_create_child,
      is_dam,
      sub_cnt,
      parents_is_dam,
      dam_capacity_statistics,
      multi_dam_manager,
      is_shein,
      is_zhrmt,
      is_daojin,
      allow_template,
      aigc,
      is_dam_super,
    },
    settings,
    locale,
  } = useContext(GlobalContext)
  const hideMenu = settings.menuType === CustomMenuType.TypeOne
  let tempMenus = PLUS_MENUS

  if (allow_template === IsShowTemplate.show) {
    tempMenus = tempMenus.concat(TEMPLATE)
  }

  if (aigc === IsShowAIGC.show) {
    tempMenus = tempMenus.concat(AIGC)
  }

  // 添加AI助手
  tempMenus = tempMenus.concat(AI_ASSISTANT)

  if (is_dam && parents_is_dam) {
    tempMenus = tempMenus.concat(DAM_CONTENTS).concat(GC, ACCOUNT_CONTENTS)
  } else {
    tempMenus = tempMenus.concat(DAM_CONTENTS_WITHOUT_AUTHORITY).concat(GC, ACCOUNT_CONTENTS)
  }

  const [plusMenus, setPlusMenus] = useState(tempMenus)

  /** 在【资金明细】之前添加【希音订单管理】 */
  function insertSheinOrder() {
    if (!is_shein) return
    const accountIndex = plusMenus.findIndex((item) => item.path === '/account')
    if (accountIndex >= 0) {
      const accountBillingIndex = plusMenus[accountIndex].children.findIndex((item) => item.path === '/account/billing')

      const newPlusMenus = produce(plusMenus, (draftPlusMenus) => {
        draftPlusMenus[accountIndex].children.splice(accountBillingIndex, 0, SHEIN_ORDER)
      })
      setPlusMenus(newPlusMenus)
    }
  }

  useEffect(() => {
    insertSheinOrder()
    if (/\/editorial/.test(path)) {
      getEditorialSubMenus()
    }

    if (/\/dam/.test(path) && parents_is_dam) {
      // get dam menus
      getDamSubMenus()
    }

    // 监听系统设置添加菜单
    events.on(ACTIONS.DAM_SUB_MENUS_EDIT_COMPLETE, getDamSubMenus)

    return () => {
      events.off(ACTIONS.DAM_SUB_MENUS_EDIT_COMPLETE, getDamSubMenus)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 如果当前路径是 DAM，检查是否需要显示更新提示
        if ((path.includes('dam') || path === '/dam') && is_dam_super && is_dam) {
          const result = await getDamSettings()
          if (result?.result) {
            const hasShownUpdateTip = localStorage.getItem('damUpdateTipShown')
            if (!hasShownUpdateTip) {
              setShowUpdateModal(true)
            }
          }
        }
      } catch (e) {
        console.warn(e)
      }
    }
    fetchData()
  }, [path])

  const handleUpdateModalConfirm = () => {
    localStorage.setItem('damUpdateTipShown', 'true')
    setShowUpdateModal(false)
  }

  function getEditorialSubMenus() {
    EditorialChannelsStore.getChannels({ id: 0 }).then((res) => {
      const channels = res.map((channel) => ({
        ...channel,
        path: `/editorial/channels?id=${channel.id}`,
        pattern: new RegExp(`\\/editorial\\/channels\\?id\\=${channel.id}$`),
      }))

      const newPlusMenus = produce(plusMenus, (draftPlusMenus) => {
        draftPlusMenus[1].children = [
          {
            id: 'nav.editorial.home',
            path: '/editorial',
            pattern: /\/editorial$/,
          },
          ...channels,
        ]
      })

      setPlusMenus(newPlusMenus)
    })
  }

  async function getDamSubMenus() {
    const [m, p, s, a] = await Promise.all([
      getMenus({ system: undefined }),
      getMyPermissions(),
      getDamSettings(),
      getApprovalSetting(),
    ])

    cachedPermissions = p
    processDamSubMenus(m, p, s, a)
  }

  /**
   * @description 获取对应路由
   *
   * @param {*} system dam路由type
   * @return {*} subPath
   */
  const handleGetSubpath = (system) => {
    let subPath
    switch (system) {
      case DAMMenuType.Plus:
        subPath = 'plus'
        break
      case DAMMenuType.Aigc:
        subPath = 'aiPicture'
        break
      case DAMMenuType.Delete:
        subPath = 'delete'
        break
      case DAMMenuType.AiAssistant:
        subPath = 'ai-assistant'
        break
      default:
        subPath = 'folder'
        break
    }
    return subPath
  }

  function processDamSubMenus(m: any, p: any = cachedPermissions, s: any, a: any) {
    function _loop(d: Array<any>) {
      return d.map(({ id, system, children, ...rest }) => {
        const subPath = handleGetSubpath(system)
        const r: any = {
          id,
          system,
          path: `/dam/${subPath}?id=${id}`,
          // eslint-disable-next-line no-useless-escape
          pattern: new RegExp(`/\/dam\/${subPath}\\?id${id}$`),
          ...rest,
        }

        if (children) {
          r.children = _loop(children)
        }

        return r
      })
    }

    const { result, data, error_code } = m
    let newPlusMenus = plusMenus

    const damIndex = plusMenus.findIndex((item) => item.path === '/dam')

    if (result && data.length) {
      const allAbleMenus = _loop(data)
      const useAbleMenus = allAbleMenus.filter(
        (item) => ![DAMMenuType.AiAssistant, DAMMenuType.Delete, DAMMenuType.Aigc].includes(item.system)
      )
      const damDeleteMenu = allAbleMenus.find((item) => item.system === DAMMenuType.Delete)
      const aigcPictureMenu = allAbleMenus.find((item) => item.system === DAMMenuType.Aigc)
      const aiAssistantMenu = allAbleMenus.find((item) => item.system === DAMMenuType.AiAssistant)

      newPlusMenus = produce(plusMenus, (draftPlusMenus) => {
        draftPlusMenus[damIndex].children.unshift(...useAbleMenus)
      })

      if (aiAssistantMenu) {
        // 后面会以system为标准sort排序路由位置
        aiAssistantMenu.system = 0
        newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
          draftPlusMenus[damIndex].children.unshift(aiAssistantMenu)
        })
      }

      if (aigcPictureMenu) {
        aigcPictureMenu.system = 1
        newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
          draftPlusMenus[damIndex].children.push(aigcPictureMenu)
        })
      }

      // 插入【我上传的】
      newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
        draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_UPLOAD)
      })

      // if (s?.data?.need_audit) {
      //   newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
      //     draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_REVIEW)
      //   })
      // }

      // 插入【最近删除】
      if (damDeleteMenu) {
        // 将 system 设置为1，避免排序变位置
        damDeleteMenu.system = 1

        newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
          draftPlusMenus[damIndex].children.push(damDeleteMenu)
        })
      }

      // 如果没有权限，不显示DAM子菜单
    } else if (error_code || !data || !data.length) {
      newPlusMenus = produce(plusMenus, (draftPlusMenus) => {
        if (draftPlusMenus[damIndex]) {
          draftPlusMenus[damIndex].children = null
        }
      })
      setPlusMenus(newPlusMenus)
      return
    }

    const { data: permissionData } = p

    // 插入【数据统计】
    if (permissionData && (permissionData.supper || dam_capacity_statistics)) {
      newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
        draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_STATISTICS)
      })
    }

    // 插入【成员管理】
    if (!parent_id || is_create_child || sub_cnt !== 0 || !!multi_dam_manager) {
      newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
        draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_SUBSIDIARY)
      })
    }

    // 插入【系统设置】
    if (permissionData) {
      if (
        permissionData.dam_menu ||
        permissionData.dam_category ||
        permissionData.dam_license ||
        permissionData.dam_source
      ) {
        newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
          draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_SETTING)
        })
      }
    }

    newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
      draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_FAQ)
    })

    const { need_audit } = a.data

    if (need_audit) {
      if (is_dam_super) {
        newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
          draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_APPROVAL_SETTINGS)
        })
      }

      newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
        draftPlusMenus[damIndex].children.push(DAM_SUB_MENU_APPROVAL_MANAGE)
      })
    }

    if (is_daojin) {
      newPlusMenus = produce(newPlusMenus, (draftPlusMenus) => {
        draftPlusMenus[damIndex].children.push({
          id: 'Sharepoint',
          system: 1,
          path: 'https://shimadzugroup.sharepoint.com/sites/SSL_GP0063/Shared%20Documents/Forms/AllItems.aspx',
          pattern: /\/dam\/faq/,
          target: '_blank',
        })
      })
    }

    setPlusMenus(newPlusMenus)
  }

  // 左侧导航栏点击事件
  function handleItemClick(id: string) {
    // 左侧导航栏点击埋点
    safeExecute(() => {
      const navName = id.split('.')[1]
      PlusGuideClick({
        button_name: MeituAnalyzeNavName[navName as keyof typeof MeituAnalyzeNavName],
        url: window.location.href,
        referrer: document.referrer,
      })
    })
    if (id === 'nav.dam') {
      setShowDamNewTag(false)
      localStorage.setItem('showDamNewTag', 'true')
    }
  }

  const subMenus = []

  const menus = plusMenus.map((_) => {
    const isActive = _.pattern.test(path)
    const hasChild = isActive && _.children && _.children.length
    // 判断 ai 助手
    const isAi = _.path.includes('ai-assistant')

    if (hasChild) {
      const data = _.children.slice().sort((a, b) => a.system - b.system)
      const dataSource = data.filter((item) => {
        if (!item.isShowFun) {
          return true
        } else {
          return item.isShowFun({ is_shein, is_zhrmt, aigc })
        }
      })

      // 判断是否只有一级
      const isOnlyOneLevel = dataSource.every((child) => !child.children)

      subMenus.push(
        <SubMenu key={`sub-menu-${path}`} className={`sub-menu ${isOnlyOneLevel} ${locale}`}>
          <SubMenuItem
            data={dataSource}
            parentId={parent_id}
            isCreateChild={is_create_child}
            subCount={sub_cnt}
            type={type}
            path={path}
            locale={locale}
            breadIds={breadIds}
            newNavbar={settings.newNavbar}
            hideMenu={hideMenu}
            channelId={channelId}
          />
        </SubMenu>
      )
    }
    return (
      <React.Fragment key={_.id}>
        <li
          className={isActive ? `menu__item--active ${hasChild || isAi ? 'white_bg' : ''}` : 'menu__item'}
          onClick={() => handleItemClick(_.id)}
        >
          {_.target ? (
            <a href={_.path} target={_.target}>
              {_.glyph ? <Icon glyph={_.glyph} /> : null}
              <FormattedMessage id={_.id} tagName="span" />
            </a>
          ) : (
            <Link href={_.path} as={_.asPath || _.path}>
              <a>
                {_.glyph ? <Icon glyph={_.glyph} /> : null}
                <FormattedMessage id={_.id} tagName="span" />
              </a>
            </Link>
          )}
          {(!is_dam || !parents_is_dam) && showDamNewTag && _.id === 'nav.dam' && <div />}
        </li>
      </React.Fragment>
    )
  })

  return (
    <>
      <Menu onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {menus}
      </Menu>
      {subMenus}

      <UpdateModal
        title="更新提示"
        isModalOpen={showUpdateModal}
        setIsModalOpen={() => {
          handleUpdateModalConfirm()
        }}
        handleUpdateModalConfirm={handleUpdateModalConfirm}
      ></UpdateModal>
    </>
  )
})

Menus.displayName = 'Menus'

function toObject(arr: Array<any>) {
  const rv = {}
  for (let i = 0; i < arr.length; ++i) {
    rv[arr[i]] = true
  }
  return rv
}

class SubMenuItem extends React.Component<{
  data: Array<any>
  parentId: number
  isCreateChild: boolean
  subCount: number
  type: UserType
  path: string
  locale: PLUS.Locale
  breadIds: Array<number>
  newNavbar: boolean
  hideMenu: boolean
  channelId: number
}> {
  state = {
    collapsedKeeping: toObject(this.props.breadIds),
  }

  render() {
    const { collapsedKeeping } = this.state
    const { data, ...rest } = this.props
    const {
      parentId,
      isCreateChild,
      subCount,
      type,
      path,
      // breadIds,
      newNavbar,
      hideMenu,
      channelId,
      locale,
    } = rest

    return data.map((child) => {
      // 子帐号
      //   -- 不显示明细菜单
      if (child.id === 'nav.account.billing' && !!parentId) {
        return null
      }
      // 主帐号
      //   -- 预付费 -> 资金明细菜单
      //   -- 后付费 -> 账单明细菜单
      if (child.id === 'nav.account.billing' && type !== UserType.PrePaid) {
        // eslint-disable-next-line no-param-reassign
        child.id = 'nav.account.invoice'
      }
      // 子帐号 没有邀请权限 并且 下级成员数量为0
      //   -- 不显示成员管理
      if (!!parentId && !isCreateChild && subCount === 0 && child.id === 'nav.account.subsidiary') {
        return null
      }

      const subCls = []
      if (
        (child.pattern && child.pattern.test(path)) ||
        // (breadIds.includes(child.id) && breadIds.includes(channelId)) ||
        child.id === channelId
      ) {
        subCls.push('sub-menu__item--active')
      } else {
        subCls.push('sub-menu__item')
      }

      if (child.system) {
        subCls.push('system')
      }

      if (child.level > 3) {
        subCls.push('level-limit')
      } else {
        subCls.push(`level-${child.level || 1}`)
      }

      let { name } = child
      if (name && locale === 'zh') {
        if ((newNavbar || hideMenu) && child.level < 4 && name.length > 10) {
          name = `${name.substr(0, 9)}...`
        }

        if ((newNavbar || hideMenu) && child.level > 3 && name.length > 8) {
          name = `${name.substr(0, 7)}...`
        }

        if (!(newNavbar || hideMenu) && name.length > 5) {
          name = `${name.substr(0, 4)}...`
        }
      }

      const nodeLabel = (
        <FormattedMessage id={String(child.id)}>
          {(msg) => (
            <Link href={`${child.path}?refresh=${getUid()}`} as={child.asPath || child.path}>
              <a title={child.name || msg} target={child.target}>
                {name || msg}
              </a>
            </Link>
          )}
        </FormattedMessage>
      )

      return child.children && newNavbar ? (
        <TreeView
          key={`tree-${child.id}`}
          treeViewClassName={subCls.join(' ')}
          nodeLabel={nodeLabel}
          collapsed={!collapsedKeeping[child.id]}
          onClick={() => {
            collapsedKeeping[child.id] = !collapsedKeeping[child.id]
            this.setState({ collapsedKeeping })
          }}
        >
          <SubMenuItem data={child.children} {...rest} />
        </TreeView>
      ) : (
        <div key={child.id} className={subCls.join(' ')}>
          {nodeLabel}
        </div>
      )
    })
  }
}

export const DrawerContainer = styled.main`
  display: flex;
  align-items: stretch;
  padding-top: 90px;
  min-height: calc(100vh + 1px);

  .aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background: ${({ theme }) => theme.customize.aside_bg_color};
    z-index: ${zIndex.nav};
  }

  #page-content {
    min-width: 1185px;
    padding: 40px 50px;
    background: ${palette.pearl};
    overflow: hidden;
    position: relative;

    .tooltip__reference {
      line-height: 0;
    }
  }

  &.img-gray {
    filter: grayscale(1);
  }

  &.old {
    > aside {
      width: 140px;
      height: calc(100% - 90px);
    }

    .sub-menu {
      left: 100%;
      width: 100px;
      text-align: center;
    }

    &.true {
      #page-content {
        margin-left: 240px;
        width: calc(100% - 240px);
      }

      #page-filter[data-fixed='true'] {
        width: calc(100% - 340px);
      }
    }

    &.false {
      #page-content {
        margin-left: 140px;
        width: calc(100% - 140px);
      }

      #page-filter[data-fixed='true'] {
        width: calc(100% - 240px);
      }
    }

    #event-tabs {
      border: none;
    }
  }

  &.new {
    > aside {
      width: 200px;
      background: ${palette.white};

      > ul {
        z-index: 2001;
        background: ${palette.white};
        color: ${palette.black3};

        > .menu__item {
          padding-left: 32px;

          :hover {
            color: ${({ theme }) => theme.customize.header_bg_color};
          }
        }

        > .menu__item--active {
          padding-left: 32px;
          border-left: none;
          background: transparent;
        }
      }
    }

    .sub-menu {
      left: 0;
      width: 220px;
      background: ${({ theme }) => theme.customize.aside_bg_color};
      box-shadow: none;

      &.true {
        text-align: center;

        .level-1 > a {
          padding-left: 0 !important;
        }
      }

      .sub-menu__item,
      .sub-menu__item--active {
        margin: 0;
        padding-left: 0;

        &.level-limit,
        &.level-1,
        &.level-2,
        &.level-3 {
          margin-left: 0;

          .tree-view_children {
            margin-left: 0;
          }
        }

        &.level-1 {
          > .tree-view_item,
          > a {
            font-size: 16px;
            line-height: 54px;
          }

          > .tree-view_item {
            padding-left: 16px;
          }

          > a {
            padding-left: 32px;
          }
        }

        &.level-2 {
          > .tree-view_item,
          > a {
            line-height: 40px;
          }

          > .tree-view_item {
            padding-left: 32px;
          }

          > a {
            padding-left: 48px;
          }
        }

        &.level-3 {
          > .tree-view_item,
          > a {
            line-height: 40px;
          }

          > .tree-view_item {
            padding-left: 48px;
          }

          > a {
            padding-left: 64px;
          }
        }

        &.level-limit {
          > .tree-view_item,
          > a {
            line-height: 40px;
          }

          > .tree-view_item {
            padding-left: 64px;
          }

          > a {
            padding-left: 80px;
          }
        }
      }

      .sub-menu__item {
        color: ${({ theme }) => theme.customize.aside_font_color};

        a:hover {
          color: ${({ theme }) => theme.customize.aside_font_hover_color};
        }
      }

      .sub-menu__item--active {
        border-radius: 0;
        color: ${({ theme }) => theme.customize.aside_font_active_color};
        background: transparent;

        a:hover {
          color: ${({ theme }) => theme.customize.aside_font_active_color};
        }

        > .tree-view_children {
          a:hover {
            color: ${({ theme }) => theme.customize.aside_font_hover_color};
          }
        }

        > .tree-view_item,
        > a {
          display: block;
          background: ${palette.pearl};
        }
      }
    }

    &.true {
      #page-content {
        margin-left: 200px;
        width: calc(100% - 200px);
      }

      #page-filter[data-fixed='true'] {
        width: calc(100% - 300px);
      }
    }

    &.false {
      #page-content {
        margin-left: 0;
        width: 100%;
      }

      #page-filter[data-fixed='true'] {
        width: calc(100% - 100px);
      }
    }

    &.closed aside ul {
      display: none;
    }

    &.opened aside::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 2000;
    }
  }
`

const Menu = styled.ul`
  color: ${palette.gray};
  flex: 1;
  width: 100%;
  position: relative;

  > .menu__item,
  > .menu__item--active {
    position: relative;
    line-height: 64px;

    > a {
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }

    > div {
      position: absolute;
      top: 50%;
      right: -2px;
      width: 26px;
      height: 18px;
      transform: translateY(-50%);
      background-image: url('/dam-new.png');
      background-size: 100% 100%;
      pointer-events: none;
    }
  }

  > .menu__item {
    padding-left: 16px;

    :hover {
      color: ${({ theme }) => theme.customize.aside_font_hover_color};

      > div {
        background-image: url('/dam-new-hover.png');
      }
    }
  }

  > .menu__item--active {
    padding-left: 14px;
    background: ${palette.pearl};
    color: ${({ theme }) => theme.customize.aside_font_active_color};
    font-weight: ${font.weight.medium};
    border-left: 2px solid ${({ theme }) => theme.customize.aside_font_active_color};
  }

  > .white_bg {
    background: ${palette.white};
  }
`

const SubMenu = styled.div`
  font-size: ${font.size.sm}px;
  height: calc(100vh - 90px);
  position: absolute;
  overflow: hidden;
  top: 0;
  background: ${palette.white};
  box-shadow: 1px 0 0 0 rgba(40, 40, 40, 0.09);

  :hover {
    overflow-y: auto;
  }

  &.true {
    text-align: center;
  }

  &.en {
    font-size: ${font.size.xs}px;
  }

  > .sub-menu__item,
  > .sub-menu__item--active {
    line-height: 46px;
    margin: 9px 5px;
    position: relative;
    font-weight: ${font.weight.normal};
    color: ${palette.spruce};
    white-space: nowrap;

    > a {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  > .sub-menu__item {
    a:hover {
      color: ${palette.primary};
    }
  }

  > .sub-menu__item--active {
    border-radius: 10px;
    background-color: ${palette.daisy};
    font-weight: ${font.weight.medium};
    color: ${palette.black3};
  }

  > .system {
    border-top: 1px solid ${palette.daisy};
  }

  > .system ~ .system {
    border: none;
  }
`
