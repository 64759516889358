/* eslint-disable no-param-reassign */
import API from './index'
import { jsonNodes } from './subsidiary'
import { getDownloadTimeoutInterval } from './util'
import {
  API_DAM_MANAGERS,
  API_DAM_MY_PERMISSIONS,
  API_DAM_SETTING,
  API_DAM_SETTING_PERMISSION,
  API_DAM_SETTING_CATEGORIES,
  API_DAM_SETTING_CATEGORY_ADD,
  API_DAM_SETTING_CATEGORY_DELETE,
  API_DAM_SETTING_CATEGORY_UPDATE,
  API_DAM_SETTING_LICENSES,
  API_DAM_SETTING_LICENSE_ADD,
  API_DAM_SETTING_LICENSE_DELETE,
  API_DAM_SETTING_LICENSE_UPDATE,
  API_DAM_SETTING_MENUS,
  API_DAM_SETTING_MENU_ADD,
  API_DAM_SETTING_MENU_DELETE,
  API_DAM_SETTING_MENU_UPDATE,
  API_DAM_SETTING_MENU_SORT_UPDATE,
  API_DAM_SETTING_SOURCES,
  API_DAM_SETTING_SOURCE_ADD,
  API_DAM_SETTING_SOURCE_DELETE,
  API_DAM_SETTING_SOURCE_UPDATE,
  API_DAM_SETTING_ALL_CATEGORY,
  API_DAM_IMAGE,
  API_DAM_SEARCH,
  API_DAM_CREATE,
  API_DAM_RENAME,
  API_DAM_REMOVE,
  API_DAM_DELETE,
  API_DAM_CRUMBS,
  API_DAM_TREE,
  API_DAM_COPY,
  API_DAM_TRANSFER,
  API_DAM_ACCOUNT,
  API_DAM_WARRANT,
  API_DAM_DEEPEST_LEVEL,
  API_DAM_PACKAGE,
  API_DAM_PREVIEW,
  API_DAM_VIDEO_PREVIEW,
  API_DAM_FAQ_CATEGORIES,
  API_DAM_FAQ_ARTICLES,
  API_DAM_FAQ_FEEDBACK,
  API_DAM_OSS_PARAMS,
  API_DAM_CLUE,
  API_DAM_APPLY,
  API_DAM_SET_DELETE_DELAY,
  API_DAM_PERMISSION_LIST,
  API_DIRECTORY_PERMISSION_LIST,
  API_UPDATE_DAM_PERMISSION,
  API_UPDATE_DIRECTORY_PERMISSION,
  API_DAM_QINIU_PARAMS,
  API_REVIEW_LIST,
  API_REVIEW_MEMBERS,
  API_UPDATE_REVIEW,
  API_AUDIT_NOTIFICATION,
  API_EXPIRED_NOTIFICATION,
  API_NOTIFICATION_CONFIG,
  API_DAM_ADMIN,
  API_PASS_REVIEW,
  API_REJECT_REVIEW,
  API_SUBMIT_REVIEW,
  API_DAM_STATUS,
  API_DAM_CHECK_DIR_EXIST,
  API_APPROVAL_UPDATE,
  API_APPROVAL_FLOW,
  API_APPROVAL_SETTING,
  API_APPROVAL_FLOW_MEMBERS,
  API_APPROVAL_NODE,
  API_APPROVAL_NODE_DELETE,
  API_APPROVAL_NODE_UPDATE,
  API_APPROVAL_REQUEST,
  API_APPROVAL_LIST,
  API_APPROVAL_PASS_CHECK,
  API_APPROVAL_PASS,
  API_APPROVAL_REJECT,
  API_APPROVAL_REQUEST_LIST,
  API_APPROVAL_WITHDRAWAL,
  API_APPROVAL_REQUEST_FLOW,
  API_APPROVAL_REQUEST_DETAIL,
  API_APPROVAL_APPROVERS,
  API_APPROVAL_NODE_MOVE,
} from './endpoints'

const DAM_FILE_OPERATION_TIMEOUT_INTERVAL = 30 * 1000

export function getFolderDetail({
  headers,
  directory_id,
  search_directory_id,
  keyword,
  per_page = 20,
  page = 1,
  plus_user_id,
  sort,
  hellorf,
  ...options
}: {
  headers?: PLUS.Headers
  directory_id?: number
  search_directory_id?: number
  per_page?: number
  page?: number
  keyword?: string
  sort?: 'newest' | 'oldest' // newest 创建时间降序，oldest 创建时间升序
  hellorf?: boolean // 是否为海洛资源
  plus_user_id?: number
  no_replication?: boolean // 筛选未导入过的素材
  permanent?: string // 有限授权搜索：is_over 0：有效 1：过期，该参数只有permanent=false才有效
  is_over?: string // 改参数实际上会在permanent中取
}) {
  // 特殊处理的情况
  if (options.permanent?.indexOf('false') > -1) {
    const [permanentValue, isOverValue] = options.permanent.split('_')
    options.permanent = permanentValue
    options.is_over = isOverValue
  }

  return API.get(API_DAM_SEARCH, {
    headers,
    params: {
      plus_user_id,
      directory_id,
      search_directory_id,
      keyword,
      per_page,
      page,
      sort,
      hellorf,
      ...options,
    },
  }).catch(() => {
    return {
      data: [],
      result: false,
      message: '获取文件夹详情失败',
    }
  })
}

// 轮询单个没有生成缩略图
export function getUnPreview(ids: any[]) {
  return API.get(API_DAM_PREVIEW, {
    params: {
      ids: ids.join(','),
    },
  })
}

export function getUserAccount() {
  return API.get(API_DAM_ACCOUNT)
}

export function getUserWarrant() {
  return API.get(API_DAM_WARRANT)
}

export function createFolder({ name, parent_id }: { name: string; parent_id: number }) {
  return API.post(API_DAM_CREATE, {
    name,
    parent_id,
  })
}
export function checkFolderExist({ name, parent_id }: { name: string; parent_id: number }) {
  return API.get(API_DAM_CHECK_DIR_EXIST, {
    params: {
      name,
      parent_id,
    },
  })
}

export function updateFolderName({ id, name }: { id: string; name: string }) {
  return API.post(API_DAM_RENAME, {
    id,
    name,
  })
}

export function deleteFiles(ids: string) {
  return API.post(
    API_DAM_REMOVE,
    {
      ids,
    },
    { timeout: DAM_FILE_OPERATION_TIMEOUT_INTERVAL }
  )
}

export function deleteFilesForever(ids: string) {
  return API.post(
    API_DAM_DELETE,
    {
      ids,
    },
    { timeout: DAM_FILE_OPERATION_TIMEOUT_INTERVAL }
  )
}

export function getFolderCrumbs({ id, headers }: { headers?: PLUS.Headers; id: number }) {
  return API.get(`${API_DAM_CRUMBS}/${id}`, {
    headers,
  })
}

export function getFolderTree(system: string) {
  return API.get(API_DAM_TREE, { params: { system } })
}

export function transferFiles({ ids, targetId }: { ids: string; targetId: number }) {
  return API.post(
    API_DAM_TRANSFER,
    {
      source_ids: ids,
      destination_id: targetId,
    },
    { timeout: DAM_FILE_OPERATION_TIMEOUT_INTERVAL }
  )
}

export function copyFiles({ ids, targetId }: { ids: string; targetId: number }) {
  return API.post(
    API_DAM_COPY,
    {
      source_ids: ids,
      destination_id: targetId,
    },
    {
      timeout: DAM_FILE_OPERATION_TIMEOUT_INTERVAL,
    }
  )
}

export function getAllCategories() {
  return API.get(API_DAM_SETTING_ALL_CATEGORY)
}

/**
 * 获取我的权限
 */
export function getMyPermissions(headers?: PLUS.Headers) {
  return API.get(API_DAM_MY_PERMISSIONS, { headers }).catch((err) => {
    console.error('获取我的权限失败', err)
    return { data: {} }
  })
}

/**
 * 设置管理员权限
 */
export function updateMyPermissions({
  id,
  isDam,
  dam_menu,
  dam_category,
  dam_license,
  dam_source,
  dam_capacity_statistics,
  dam_audit_resource,
  nodes,
}: {
  id: number
  isDam?: boolean
  dam_menu?: number
  dam_category?: number
  dam_license?: number
  dam_source?: number
  dam_audit_resource?: boolean
  dam_capacity_statistics?: boolean
  nodes?: Array<PLUS.ResourcePermission>
}) {
  return API.post(API_DAM_SETTING_PERMISSION, {
    plus_user_id: id,
    is_dam: isDam,
    menu: dam_menu,
    category: dam_category,
    license: dam_license,
    source: dam_source,
    dam_capacity_statistics,
    node_data: jsonNodes(nodes),
    dam_audit_resource,
  })
}

/**
 * 获取管理员列表
 */
export function getManagers(headers?: PLUS.Headers) {
  return API.get(API_DAM_MANAGERS, { headers }).catch((err) => {
    console.error('获取管理员列表失败', err)
    return { data: [] }
  })
}

/**
 * 获取所有分类
 */
export function getCategories({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(API_DAM_SETTING_CATEGORIES, { headers }).catch((err) => {
    console.error('获取所有分类失败', err)
    return { data: [] }
  })
}

/**
 * 添加分类
 */
export function addCategory(name: string, remark: string) {
  return API.post(API_DAM_SETTING_CATEGORY_ADD, {
    name,
    remark,
  })
}

/**
 * 删除分类
 */
export function deleteCategoryById(id: number) {
  return API.post(API_DAM_SETTING_CATEGORY_DELETE, {
    id,
  })
}

/**
 * 更新分类
 */
export function updateCategoryById(id: number, name: string, remark: string) {
  return API.post(API_DAM_SETTING_CATEGORY_UPDATE, {
    id,
    name,
    remark,
  })
}

/* 获取全部用途
 * @param system undefined：获取所有 0：自定义菜单 1：系统菜单
 */
export function getLicenses({ system, headers }: { system?: undefined | 0 | 1; headers?: PLUS.Headers } = {}) {
  return API.get(API_DAM_SETTING_LICENSES, {
    headers,
    params: {
      system,
    },
  }).catch((err) => {
    console.error('获取所有用途失败', err)
    return { data: [] }
  })
}

/**
 * 添加用途
 */
export function addLicense(name: string, remark: string, childIds: number[]) {
  return API.post(API_DAM_SETTING_LICENSE_ADD, {
    name,
    remark,
    child_ids: childIds.join(','),
  })
}

/**
 * 删除用途
 */
export function deleteLicenseById(id: number) {
  return API.post(API_DAM_SETTING_LICENSE_DELETE, {
    id,
  })
}

/**
 * 更新用途
 */
export function updateLicenseById(id: number, name: string, remark: string, childIds: number[]) {
  return API.post(API_DAM_SETTING_LICENSE_UPDATE, {
    id,
    name,
    remark,
    child_ids: childIds && childIds.join(','),
  })
}

/**
 * 获取所有菜单
 * @param system undefined：不传获取所有   0：自定义菜单   1：系统菜单
 */
export function getMenus({ system, headers }: { system?: undefined | 0 | 1; headers?: PLUS.Headers } = {}) {
  return API.get(API_DAM_SETTING_MENUS, {
    headers,
    params: {
      system,
    },
  }).catch((err) => {
    console.error('获取菜单失败', err)
    return { data: [], result: false, error_code: '500', message: '' }
  })
}

/**
 * 添加菜单
 */
export function addMenu(name: string, remark: string) {
  return API.post(API_DAM_SETTING_MENU_ADD, {
    name,
    remark,
  })
}

/**
 * 删除菜单
 */
export function deleteMenuById(id: number) {
  return API.post(API_DAM_SETTING_MENU_DELETE, {
    id,
  })
}

/**
 * 更新菜单
 */
export function updateMenuById(id: number, name: string, rest: { [key: string]: any }) {
  return API.post(API_DAM_SETTING_MENU_UPDATE, {
    id,
    name,
    ...rest,
  })
}

/**
 * 更新菜单排序
 */
export function updateSortById(id: number, orderNumber: number) {
  return API.post(API_DAM_SETTING_MENU_SORT_UPDATE, {
    id,
    order_number: orderNumber,
  })
}

/**
 * 获取所有来源
 */
export function getSources({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(API_DAM_SETTING_SOURCES, { headers }).catch((err) => {
    console.error('获取所有来源失败', err)
    return { data: [] }
  })
}

/**
 * 添加来源
 */
export function addSource(name: string, remark: string) {
  return API.post(API_DAM_SETTING_SOURCE_ADD, {
    name,
    remark,
  })
}

/**
 * 删除来源
 */
export function deleteSourceById(id: number) {
  return API.post(API_DAM_SETTING_SOURCE_DELETE, {
    id,
  })
}

/**
 * 更新来源
 */
export function updateSourceById(id: number, name: string, remark: string) {
  return API.post(API_DAM_SETTING_SOURCE_UPDATE, {
    id,
    name,
    remark,
  })
}

/**
 * 获取DAM图片详情
 */
export function getDamImageById(id: string, headers?: PLUS.Headers) {
  return API.get(`${API_DAM_IMAGE}/${id}`, { headers })
}

/**
 * 获取DAM图片列表
 */
export function getDamImagesByIds(ids: string, headers?: PLUS.Headers) {
  return API.get(API_DAM_IMAGE, { params: { ids }, headers })
}

/**
 * 获取DAM图片下载记录
 */
export function getDamImageDownloadRecordsById(id: string, headers?: PLUS.Headers) {
  return API.get(`/dam/image/${id}/download-record`, { headers }).catch((err) => {
    console.error('获取DAM图片下载记录失败', err)
    return { data: [] }
  })
}

/**
 * 获取DAM图片编辑记录
 */
export function getDamImageEditRecordsById(id: string, headers?: PLUS.Headers) {
  return API.get(`/dam/image/${id}/edit-record`, { headers })
}

/**
 * 图片下载
 */
export function downloadDAMImageById(id: string, isApproval?: boolean, headers?: PLUS.Headers) {
  return API.get(`/dam/image/${id}/download`, {
    params: {
      from: isApproval ? 'approval' : undefined,
    },
    headers,
    timeout: getDownloadTimeoutInterval(),
  })
}

/**
 * 图片打包下载
 */
export function downloadDAMImageByIds(ids: Array<string>) {
  return API.get(API_DAM_PACKAGE, {
    params: {
      ids: ids.toString(),
    },
    timeout: getDownloadTimeoutInterval(),
  })
}

/**
 * 下载附件
 * @param id 附件id
 */
export function downloadAttachmentById({ id }: { id: string }) {
  return API.get(`/dam/image/${id}/download-attachment`, {
    timeout: getDownloadTimeoutInterval(),
  })
}

/**
 * 获取用户的布局 & 文件夹配置
 */
export function getDamSettings({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(API_DAM_SETTING, { headers })
}

/**
 * 获取用户的布局 & 文件夹配置
 */
export function updateDamSettings(props: { layout?: 'multi' | 'normal'; show_folder?: 0 | 1; show_license?: 0 | 1 }) {
  return API.post(API_DAM_SETTING, {
    settings: JSON.stringify(props),
  })
}

/**
 * 设置 DAM 的布局方式
 * @param value 1、列表视图 2、缩略视图 3、平铺视图
 */
export function setDamLayout(value: number) {
  return API.post('/dam/view', {
    dam_view: value,
  })
}

/**
 * 获取最大层级，用于判断是否需要弹窗提示用户
 */
export function getDeepestLevel() {
  return API.get(API_DAM_DEEPEST_LEVEL)
}

/**
 * 获取视频预览播放地址
 */
export function getVideoPreview(id: string) {
  return API.get(`${API_DAM_VIDEO_PREVIEW}/${id}`)
}

/**
 *  获取DAM帮助全部分类
 */
export function getDAMFaqCategories({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(API_DAM_FAQ_CATEGORIES, { headers })
}

/**
 * 获取DAM帮助全部文章
 */
export function getDAMFaqArticles({ headers }: { headers?: PLUS.Headers } = {}) {
  return API.get(API_DAM_FAQ_ARTICLES, { headers })
}

/**
 * 提交DAM帮助文章意见反馈
 */
export function postDAMFaqFeedback({ content }: { content: string }) {
  return API.post(API_DAM_FAQ_FEEDBACK, {
    content,
    type: 5,
  })
}

/**
 * 获取OSS参数
 */
export function getOssParams(params: {
  type: string
  app: string
  sigTime?: string
  sig?: string
  count?: number | string
}) {
  return API.get(API_DAM_OSS_PARAMS, {
    params,
  })
}

/**
 * 获取OSS参数
 */
export function getQiniuParams() {
  return API.get(API_DAM_QINIU_PARAMS)
}

/**
 * 统计客户线索
 * clue_type: 1: 浏览, 2: 点击
 */
export function postDAMClue(clueType: 1 | 2) {
  return API.post(API_DAM_CLUE, { clue_type: clueType })
}

/**
 * DAM申请
 * phone: 联系方式
 * userName: 称呼
 */
export function applyDAM({ userName, phone }: { userName: string; phone: string }) {
  return API.post(API_DAM_APPLY, { phone, username: userName })
}

/**
 * DAM状态获取
 */
export function getDamStatus() {
  return API.get(API_DAM_STATUS)
}
/**
 * 设置多久删除
 *
 * @export
 * @param {number} days -1: 保留全部; 0: 立即删除; 其他: 天数
 * @param {(1 | 0)} confirm
 */
export function setDeleteDelay(days: number, confirm: 1 | 0) {
  return API.post(API_DAM_SET_DELETE_DELAY, { days, confirm })
}

interface PermissionParams {
  name?: string
  email?: string
  department?: string
  position?: string
  sub_company_name?: string
  is_dam_open?: 0 | 1
  parent_id?: number
  member_status?: 1 | 2
  page?: number
  per_page?: number
  order?: string
  by?: 'desc' | 'asc'
}

/**
 * 获取数字资产权限列表
 */
export function getDamPermissionList({ page = 1, per_page = 20, ...params }: PermissionParams): Promise<any> {
  return API.get(API_DAM_PERMISSION_LIST, {
    params: {
      ...params,
      page,
      per_page,
    },
  })
}

/**
 * 获取目录权限列表
 */
export function getDirectoryPermissionList({
  page = 1,
  per_page = 20,
  ...params
}: {
  dam_node_id: number
} & PermissionParams): Promise<any> {
  return API.get(API_DIRECTORY_PERMISSION_LIST, {
    params: {
      ...params,
      page,
      per_page,
    },
  })
}

type PermissionStatus = 0 | 1

/**
 * 更新数字资产列表
 */
export function updateDamPermission(params: {
  user_ids: number[]
  dam?: PermissionStatus
  dam_source?: PermissionStatus
  dam_license?: PermissionStatus
  dam_category?: PermissionStatus
  dam_capacity_statistics?: PermissionStatus
}): Promise<any> {
  return API.post(API_UPDATE_DAM_PERMISSION, params)
}

/**
 * 更新目录权限列表
 */
export function updateDirectoryPermission(params: {
  user_ids: number[]
  dam_node_id: number
  visit?: PermissionStatus
  push?: PermissionStatus
  delete?: PermissionStatus
  download?: PermissionStatus
}): Promise<any> {
  return API.post(API_UPDATE_DIRECTORY_PERMISSION, params)
}

/** 获取审核列表 */
export function getReviewList(query: Record<string, string>, headers?: PLUS.Headers) {
  const formatQuery = { ...query }
  formatQuery.per_page = query.per_page || '20'

  // 特殊处理的情况
  if (formatQuery.permanent?.indexOf('false') > -1) {
    const [permanentValue, isOverValue] = formatQuery.permanent.split('_')
    formatQuery.permanent = permanentValue
    formatQuery.is_over = isOverValue
  }
  return API.get(API_REVIEW_LIST, { headers, params: formatQuery })
}

/** dam审核-通知详情 */
export function getNotification(headers?: PLUS.Headers) {
  return API.get(API_NOTIFICATION_CONFIG, { headers })
}

/** dam审核-素材过期通知 */
export function updateExpiredNotification(data) {
  return API.post(API_EXPIRED_NOTIFICATION, data)
}

/** dam审核-审核通知 */
export function updateAuditNotification(data) {
  return API.post(API_AUDIT_NOTIFICATION, data)
}

/** dam审核-超级管理员 */
export function getDamAdmin(headers?: PLUS.Headers) {
  return API.get(API_DAM_ADMIN, { headers })
}

/** 获取审核人员 */
export function getReviewMember(headers?: PLUS.Headers) {
  return API.get(API_REVIEW_MEMBERS, { headers })
}

/** 获取邮件设置 */
export function updateReview(
  data: Partial<{
    expired_notification: 0 | 1
    expired_notification_user_type: 0 | 1
    audit_notification: 0 | 1
    audit_notification_users: string
  }>
) {
  return API.post(API_UPDATE_REVIEW, data)
}

/** 通过审核 */
export function passReview(id: number) {
  return API.post(API_PASS_REVIEW, { resource_id: id })
}

/** 拒绝审核 */
export function rejectReview(id: number) {
  return API.post(API_REJECT_REVIEW, { resource_id: id })
}

/** 提交审核 */
export function submitReview(id: number) {
  return API.post(API_SUBMIT_REVIEW, { resource_id: id })
}

/** 更新审批状态 */
export function updateApprovalStatus({ type, status }: { type: string; status: string }) {
  return API.post(API_APPROVAL_UPDATE, { type, status })
}

/** 获取审批流 */
export function getApprovalFlow({ type }: { type: string }) {
  return API.get(`${API_APPROVAL_FLOW}/${type}`)
}

/** 获取审批设置 */
export function getApprovalSetting() {
  return API.get(API_APPROVAL_SETTING)
}

/** 获取审批设置 */
export function getApprovalFlowMembers({
  nodeId,
  requestId,
  type,
}: {
  nodeId?: number
  requestId?: number | string
  type: number
}) {
  return API.get(API_APPROVAL_FLOW_MEMBERS, { params: { type, node_id: nodeId, request_id: requestId } })
}

/** 添加节点 */
export function addNode({
  type,
  approverType,
  signMode,
  plusUserIds,
}: {
  type: number
  approverType: number
  signMode: number
  plusUserIds: string
}) {
  return API.post(API_APPROVAL_NODE, {
    type,
    approver_type: approverType,
    sign_mode: signMode,
    plus_user_ids: plusUserIds,
  })
}

/** 删除节点 */
export function deleteNode({ nodeId }: { nodeId: number }) {
  return API.post(`${API_APPROVAL_NODE_DELETE}`, { node_id: nodeId })
}

/** 更新节点 */
export function updateNode({
  type,
  nodeId,
  approverType,
  signMode,
  plusUserIds,
}: {
  type: number
  nodeId: number
  approverType: number
  signMode: number
  plusUserIds: string
}) {
  return API.post(`${API_APPROVAL_NODE_UPDATE}`, {
    node_id: nodeId,
    approver_type: approverType,
    sign_mode: signMode,
    plus_user_ids: plusUserIds,
    type,
  })
}

/** 移动节点 */
export function moveNode({ nodeId, targetDepth }: { nodeId: number; targetDepth: number }) {
  return API.post(`${API_APPROVAL_NODE_MOVE}`, {
    node_id: nodeId,
    target_depth: targetDepth,
  })
}

/** 上传/下载审批申请(支持批量) */
export function submitApproval({
  type,
  damNodeIds,
  damResourceIds,
  nodeId,
  plusUserIds,
}: {
  type: number
  damNodeIds?: string
  damResourceIds?: string
  nodeId?: number
  plusUserIds?: string
}) {
  return API.post(`${API_APPROVAL_REQUEST}`, {
    type,
    dam_node_ids: damNodeIds ? damNodeIds : undefined,
    node_id: nodeId ? nodeId : undefined,
    dam_resource_ids: damResourceIds ? damResourceIds : undefined,
    plus_user_ids: plusUserIds ? plusUserIds : undefined,
  })
}

/** 审批阶段审批人自选 */
export function submitApprovalApprover({
  requestId,
  nextNodeId,
  nodeId,
  plusUserIds,
}: {
  requestId: number
  nextNodeId: string
  nodeId: string
  plusUserIds: string
}) {
  return API.post(`${API_APPROVAL_APPROVERS}`, {
    request_id: requestId,
    next_node_id: nextNodeId,
    node_id: nodeId,
    plus_user_ids: plusUserIds,
  })
}

/** 待审批/已审批列表 */
export function getApprovalList({
  type,
  page,
  per_page,
  plus_user_name,
  start_time,
  end_time,
  status,
}: {
  type: number // 1待审核2已处理列表
  page?: number
  per_page?: string
  plus_user_name?: string
  start_time?: string
  end_time?: string
  status?: string
}) {
  return API.get(API_APPROVAL_LIST, { params: { type, status, page, per_page, plus_user_name, start_time, end_time } })
}

/** 待发起列表 */
export function getApprovalRequestList({
  page,
  per_page,
  plus_user_name,
  start_time,
  end_time,
  status,
}: {
  page?: number
  per_page?: string
  plus_user_name?: string
  start_time?: string
  end_time?: string
  status?: string
}) {
  return API.get(API_APPROVAL_REQUEST_LIST, {
    params: { status, page, per_page, plus_user_name, start_time, end_time },
  })
}

/** 审核操作通过前检查 */
export function checkApproval({ requestId }: { requestId: string }) {
  return API.post(`${API_APPROVAL_PASS_CHECK}`, { request_id: requestId })
}

/** 审核操作通过 */
export function approvalPass({ requestIds }: { requestIds: string }) {
  return API.post(`${API_APPROVAL_PASS}`, { request_ids: requestIds })
}

/** 审核操作拒绝 */
export function approvalReject({ requestIds }: { requestIds: string }) {
  return API.post(`${API_APPROVAL_REJECT}`, { request_ids: requestIds })
}

/** 审核操作撤回 */
export function approvalWithdrawal({ requestIds }: { requestIds: string }) {
  return API.post(`${API_APPROVAL_WITHDRAWAL}`, { request_ids: requestIds })
}

/** 查看审批内容详情-审批流程 */
export function approvalRequestFlow({ requestId }: { requestId: string }) {
  return API.get(`${API_APPROVAL_REQUEST_FLOW}`, { params: { request_id: requestId } })
}

/** 查看审批内容详情 */
export function approvalRequestDetail({ requestId }: { requestId: string }) {
  return API.get(`${API_APPROVAL_REQUEST_DETAIL}/${requestId}`)
}
