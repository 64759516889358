import { ContentType, Payment, ResourceStatisticsType, IsShowAIGC } from 'helpers/enums'

// 顶部导航下拉选项

export const PLUS_CONTENTS = [
  {
    id: 'nav.creative',
    path: '/creative',
    pattern: /\/creative/,
    glyph: 'nav-creative',
    type: ContentType.Creative,
    children: [],
  },
  // MOD: hide editorial channel 2019.12.10 zhanghao
  // {
  //   id: 'nav.editorial',
  //   path: '/editorial',
  //   pattern: /\/editorial/,
  //   glyph: 'nav-editorial',
  //   children: []
  // },
  {
    id: 'nav.video',
    path: '/video',
    pattern: /\/video/,
    glyph: 'nav-video',
    type: ContentType.Video,
    children: [],
  },
  {
    id: 'nav.music',
    path: '/music',
    pattern: /\/music/,
    glyph: 'nav-music',
    type: ContentType.Music,
    children: [],
  },
  {
    id: 'nav.font',
    path: '/font',
    pattern: /\/font/,
    glyph: 'nav-font',
    type: ContentType.Font,
    children: [],
  },
  {
    id: 'nav.ip',
    path: '/ip',
    pattern: /\/ip/,
    glyph: 'nav-ip',
    type: ContentType.Ip,
    children: [],
  },
  // {
  //   id: 'nav.dam',
  //   path: '/dam/introduction',
  //   pattern: /\/dam\/introduction/,
  //   glyph: 'nav-dam',
  //   type: ContentType.Dam,
  //   target: '_blank',
  //   children: []
  // }
]

export const DAM_CONTENTS = [
  {
    id: 'nav.dam',
    path: '/dam',
    pattern: /\/dam/,
    glyph: 'nav-dam',
    type: ContentType.Dam,
    children: [],
  },
]

export const TEMPLATE = [
  {
    id: 'nav.template',
    // path: '/template',
    path: 'https://www.hellorf.com/template',
    pattern: /\/template/,
    glyph: 'nav-template',
    type: 'template',
    target: '_blank',
    children: [],
  },
]

// AI助手
export const AI_ASSISTANT = [
  {
    id: 'nav.ai-assistant',
    path: '/ai-assistant/landing',
    pattern: /\/ai-assistant\/landing/,
    glyph: 'nav-ai-assistant',
    type: 'ai-assistant-landing',
    children: [],
  },
]

export const AIGC = [
  {
    id: 'nav.aigc',
    path: '/aigc',
    pattern: /\/aigc/,
    glyph: 'nav-aigc',
    type: 'aigc',
    children: [],
  },
]

export const DAM_CONTENTS_WITHOUT_AUTHORITY = [
  {
    id: 'nav.dam',
    path: '/dam/introduction',
    pattern: /\/dam\/introduction/,
    glyph: 'nav-dam',
    type: ContentType.Dam,
    target: '_blank',
    children: [],
  },
]

// DAM我上传的，仅当用户有相关权限时，添加进DAM子目录
export const DAM_SUB_MENU_UPLOAD = {
  id: 'nav.dam.my-upload', // 我上传的
  system: 1,
  path: '/dam/my-upload',
  pattern: /\/dam\/my-upload/,
}

// DAM审批设置
export const DAM_SUB_MENU_APPROVAL_SETTINGS = {
  id: 'nav.dam.approval-settings',
  system: 1,
  path: '/dam/approval-settings',
  pattern: /\/dam\/approval-settings/,
}

// DAM审批管理
export const DAM_SUB_MENU_APPROVAL_MANAGE = {
  id: 'nav.dam.approval-manage', // 我上传的
  system: 1,
  path: '/dam/approval-manage',
  pattern: /\/dam\/approval-manage/,
}

// DAM审核列表，仅当用户有相关权限时，添加进DAM子目录
export const DAM_SUB_MENU_REVIEW = {
  id: 'nav.dam.review', // 我上传的
  system: 1,
  path: '/dam/review',
  pattern: /\/dam\/review/,
}

// DAM数据统计，仅当用户为超级权限账户时，添加进DAM子目录
export const DAM_SUB_MENU_STATISTICS = {
  id: 'nav.dam.statistics',
  system: 1,
  path: '/dam/statistics',
  pattern: /\/dam\/statistics/,
}

// DAM系统设置，仅当用户有相关权限时，添加进DAM子目录
export const DAM_SUB_MENU_SETTING = {
  id: 'nav.dam.settings', // 系统设置
  system: 1,
  path: '/dam/settings',
  pattern: /\/dam\/settings/,
}

// DAM成员管理，仅当用户有相关权限时，添加进DAM子目录
export const DAM_SUB_MENU_SUBSIDIARY = {
  id: 'nav.dam.subsidiary', // 成员管理
  system: 1,
  path: '/account/subsidiary',
  asPath: '/dam/subsidiary',
  pattern: /\/dam\/subsidiary/,
}

// DAM帮助中心，仅当用户为超级权限账户时，添加进DAM子目录
export const DAM_SUB_MENU_FAQ = {
  id: 'nav.dam.faq', // 帮助中心
  system: 1,
  path: '/dam/faq',
  pattern: /\/dam\/faq/,
}

// 希音订单管理
export const SHEIN_ORDER = {
  id: 'nav.shein.order', // 希音订单管理
  path: '/account/order',
  pattern: /\/account\/order/,
}

export const ACCOUNT_CONTENTS = [
  {
    id: 'nav.account',
    path: '/account',
    pattern: /^\/account/,
    glyph: 'nav-home',
    children: [
      {
        id: 'nav.account.overview',
        path: '/account',
        pattern: /^\/account$/,
      },
      {
        id: 'nav.account.purchased',
        path: '/account/purchased',
        pattern: /\/account\/purchased/,
      },
      {
        id: 'nav.account.audit-list',
        path: '/account/audit-list',
        pattern: /\/account\/audit-list/,
        isShowFun: (currentUser) => {
          return !!currentUser.is_shein || !!currentUser.is_zhrmt
        },
      },
      {
        id: 'nav.account.sample-downloads',
        path: '/account/sample-downloads',
        pattern: /\/account\/sample-downloads/,
      },
      {
        id: 'nav.account.browsing-history',
        path: '/account/browsing-history',
        pattern: /\/account\/browsing-history/,
      },
      {
        id: 'nav.account.lightbox',
        path: '/account/collection',
        pattern: /\/account\/collection/,
      },
      {
        id: 'nav.account.billing',
        path: '/account/billing',
        pattern: /\/account\/billing/,
      },
      {
        id: 'nav.account.subsidiary',
        path: '/account/subsidiary',
        pattern: /\/account\/subsidiary/,
      },
      {
        id: 'nav.account.aiRecordsList',
        path: '/account/list-aigc',
        pattern: /\/account\/list-aigc/,
        isShowFun: (currentUser) => {
          return currentUser.aigc === IsShowAIGC.show
        },
      },
      {
        id: 'nav.account.aiAssistantManage',
        path: '/account/ai-assistant-manage',
        pattern: /\/account\/ai-assistant-manage/,
      },
    ],
  },
]

// 左侧导航 注释项暂不开放
export const PLUS_MENUS = [
  // {
  //   id: 'nav.home',
  //   path: '/',
  //   pattern: /^\/$|\/home/,
  //   glyph: 'nav-home',
  //   children: []
  // }
].concat(PLUS_CONTENTS)
// .concat(ACCOUNT_CONTENTS)

export const HTML_TITLE =
  '站酷海洛Plus - 正版图片,视频,字体,音乐素材交易平台 - Shutterstock中国独家合作伙伴 - 站酷旗下品牌'
export const HTML_TITLE_MIDEA = '美的品牌资产管理平台'
// 搜索页-色彩筛选项
export const COLORS = [
  '#38AFFB',
  '#496EF9',
  '#3438EF',
  '#8F40F0',
  '#C616EC',
  '#F636E2',
  '#61CFBC',
  '#EB0000',
  '#FF6D26',
  '#F7B526',
  '#FFD618',
  '#9BD550',
  '#54C731',
  '#000000',
]

const ALL = [{ text: 'common.all', key: 'all' }]

// 用途
export const USE = ALL.concat([
  { text: 'filters.commercial-usage', key: 'commercial' },
  { text: 'filters.editorial-usage', key: 'editorial' },
])

// 图片用途
export const CREATIVE_USE = [
  { text: 'filters.commercial-usage', key: 'commercial' },
  { text: 'filters.un-commercial-usage', key: 'editorial' },
]

// 图片类型
export const IMAGE_TYPES = ALL.concat([
  { text: 'filters.photos', key: 'photo' },
  { text: 'filters.illustrations', key: 'illustration' },
  { text: 'filters.aigc', key: 'aigc' },
])

// 性别
export const GENDER = ALL.concat([
  { text: 'filters.male', key: 'male' },
  { text: 'filters.female', key: 'female' },
])

// 视频分辨率
export const VIDEO_RESOLUTIONS = ALL.concat([
  { key: '4k', text: '4K' },
  { key: 'sd', text: 'SD' },
  { key: 'hd', text: 'HD' },
])

// 年龄
export const AGE = ALL.concat([
  { text: 'filters.infants', key: 'infants' },
  { text: 'filters.children', key: 'children' },
  { text: 'filters.teenagers', key: 'teenagers' },
  { text: 'filters.20s', key: '20s' },
  { text: 'filters.30s', key: '30s' },
  { text: 'filters.40s', key: '40s' },
  { text: 'filters.50s', key: '50s' },
  { text: 'filters.60s', key: '60s' },
  { text: 'filters.older', key: 'older' },
])

// 种族
export const RACE = ALL.concat([
  { text: 'filters.chinese', key: 'chinese' },
  { text: 'filters.black', key: 'black' },
  { text: 'filters.caucasian', key: 'caucasian' },
  { text: 'filters.hispanic', key: 'hispanic' },
  { text: 'filters.middle.eastern', key: 'middle_eastern' },
  { text: 'filters.southeast.asian', key: 'southeast_asian' },
])

// 人数
export const NUMBERS = ALL.concat([
  { text: 'filters.1', key: '1' },
  { text: 'filters.2', key: '2' },
  { text: 'filters.3', key: '3' },
  { text: 'filters.4+', key: '4' },
])

// 方向
export const DIRECTION = [
  { text: 'filters.horizontal', key: 'horizontal' },
  { text: 'filters.vertical', key: 'vertical' },
]

// 排序
export const CREATIVE_SORTS = [
  { text: 'filters.popular', key: 'popular' },
  { text: 'filters.relevance', key: 'relevance' },
  { text: 'common.latest', key: 'newest' },
]

export const VIDEO_SORTS = [
  // { text: 'filters.popular', key: 'popular' },
  { text: 'filters.relevance', key: 'similarity' },
  { text: 'common.latest', key: 'newest' },
]

// 微利授权类型
export const AUTHORIZATION_TYPES = ALL.concat([
  { text: 'hellorf.media', key: 4 },
  { text: 'hellorf.standard', key: 1 },
  { text: 'hellorf.extension', key: 2 },
  { text: 'hellorf.commodity', key: 3 },
] as any)

// 内部自定义事件
export const ACTIONS = {
  ADD_TOAST: 'ADD_TOAST',
  ADD_NEWTOAST: 'ADD_NEWTOAST',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  UPLOAD_CHANGE: 'UPLOAD_CHANGE',
  DAM_EDIT_COMPLETE: 'DAM_EDIT_COMPLETE',
  DAM_EDIT_COVER_COMPLETE: 'DAM_EDIT_COVER_COMPLETE',
  DAM_SUB_MENUS_EDIT_COMPLETE: 'DAM_SUB_MENUS_EDIT_COMPLETE',
  DAM_START_UPLOAD_FROM_EMPTY: 'DAM_START_UPLOAD_FROM_EMPTY',
  CHECK_IMAGE_ALL_ITEMS: 'CHECK_IMAGE_ALL_ITEMS',
  BATCH_COLLECTION: 'BATCH_COLLECTION',
  UPDATE_CART_COUNT: 'UPDATE_CART_COUNT',
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',
  SYNC_MUSIC_STATUS: 'SYNC_MUSIC_STATUS',
  DAM_AFTER_CONFIRM_FILE: 'DAM_AFTER_CONFIRM_FILE',
}

// 浏览器本地存储 k-v
export const STORAGE_KEYS = {
  CREATIVE_IMAGE_CATEGORY: 'plus-cr-category',
  DOWNLOAD_SAMPLE: 'plus-cr-download-sample',
  QUICKLY_DOWNLOAD: 'quickly-download',
  EDITORIAL_CHANNELS: 'editorial-channels',
  VIEW_REMINDER: 'view-reminder',
  DAM_EDIT_OPERATION: 'dam-edit-operation',
  SEARCH_ID_KEY: 'search-id',
}

// 资源类型
export const CONTENT_TYPES = [
  { text: 'common.all', key: 'all', router: 'all' },
  { text: 'nav.creative', key: ContentType.Creative, router: 'creative' },
  { text: 'nav.editorial', key: ContentType.Editorial, router: 'editorial' },
  { text: 'nav.video', key: ContentType.Video, router: 'video' },
  { text: 'nav.music', key: ContentType.Music, router: 'music' },
  { text: 'nav.font', key: ContentType.Font, router: 'font' },
  // { text: 'IP', key: ContentType.Ip, router: 'ip' },
]

// 微利tabs
export const HELLORF_CONTENT_TYPES = [
  {
    text: 'hellorf.picture',
    key: 'image',
    hasPurchase: false,
    type: ContentType.Creative,
    router: 'creative',
  },
  {
    text: 'nav.video',
    key: 'video',
    hasPurchase: false,
    type: ContentType.Video,
    router: 'video',
  },
  {
    text: 'nav.font',
    key: 'font',
    hasPurchase: false,
    type: ContentType.Font,
    router: 'font',
  },
  {
    text: 'nav.music',
    key: 'music',
    hasPurchase: false,
    type: ContentType.Music,
    router: 'music',
  },
  {
    text: 'hellorf.freepicture',
    key: 'freeimage',
    hasPurchase: false,
    type: ContentType.Creative,
    router: 'creative',
  },
]

export const TAGS_MAP = {
  0: null,
  1: 'only-plus', // plus专享
  2: 'great-selection', // 大神精选
  3: 'buy', // 已购买
  4: 'rex', // rex图
  5: 'selected-video', // 严选视频
  6: 'essential', // essentials
  7: 'choice', // choice
  10: 'essential', // essentials
}

export const TITLE_TAGS_MAP = {
  20: '商品类C级',
  21: '商品类B级',
  22: '商品类A级',
  23: '商品类S级',
  24: '站酷纹样',
}

export const VIDEO_TAGS_MAP = {
  3: { glyph: 'buy', width: 48, height: 25 }, // 已购买
  5: { glyph: 'selected-video', width: 42, height: 42 }, // 严选视频
  10: { glyph: 'essential', width: 68, height: 25 }, // essentials
}

export const MUSIC_LEVELS_MAP = {
  levels: [
    { id: 'all', name: 'common.all' },
    { id: 1, name: 'Absolute' },
    { id: 2, name: 'Boost' },
    { id: 3, name: 'Chic' },
    { id: 99, name: 'Shutterstock' },
    { id: 100, name: 'Shutterstock优选' },
  ],
}

// 编辑图片搜索结果 单图/组图
export const EDITORIAL_LIST_TYPES = [
  { id: 1, name: 'filters.editorial.single-image' },
  { id: 2, name: 'filters.editorial.group-image' },
]

// 编辑图片搜索结果排序
export const EDITORIAL_SORTS = [
  { id: 'newest', name: 'common.latest' },
  { id: 'oldest', name: 'filters.editorial.earliest' },
]

// 编辑图片组图列表排序
export const GROUP_IMAGE_SORTS = [
  { id: 'newest', name: 'common.latest' },
  { id: 'recommend', name: 'filters.editorial.recommend' },
]

// 详情页 图片 最大宽度
export const SHOW_MAX_IMAGE_LENGTH = 1000

// 详情页 创意图片 最大高度
export const SHOW_MAX_CREATIVE_HEIGHT = 640

// 详情页 编辑图片 最大高度
export const SHOW_MAX_EDITORIAL_HEIGHT = 544

// 详情页 编辑图片 最小宽度
export const SHOW_MIN_EDITORIAL_WIDTH = 260

// 素材单位
export const CONTENT_UNIT = {
  1: '张',
  2: '个',
  3: '款',
  4: '张',
  5: '个',
  6: '首',
}

// 字体热搜分类
export const FONT_TOP_SEARCH = [
  { id: 0, name: 'filters.type' },
  { id: 1, name: 'filters.font.style' },
]

// 创意图片-详情-分类 (国内、全部)
export const CREATIVE_SHOW_TOP_SEARCH = [
  { id: 0, name: 'nav.editorial.domestic' },
  { id: 1, name: 'common.all' },
]

// dam 资源下载统计 0 成员下载量  1 资源下载量
export const DOWNLOAD_STATISTICS = {
  0: ResourceStatisticsType.DownloadMember,
  1: ResourceStatisticsType.DownloadResource,
}

export const DAM_FILTER_CONDITIONS = [
  'resource_type',
  'category_id',
  'source_id',
  'license_id',
  'status',
  'permanent',
  'warrant',
  'created_at_start',
  'created_at_end',
  'license_id',
  'warrant_name',
  'plus_user_id',
]

// 翻页每页显示数量
export const PER_PAGE_NUMBERS = [100, 50, 20]
// dam翻页每页显示数量
export const DAM_PER_PAGE_NUMBERS = [100, 500, 1000]

export const sharedValues: any = {}

export const DAM_RESOURCE_TYPE = [
  { name: '全部', id: 'all' },
  { name: '图片', id: 'image' },
]
export const DAM_VIDEO = { name: '视频', id: 'video' }

export const DAM_FONT = { name: '字体', id: 'font' }

export const DAM_DOCUMENT = { name: '文档', id: 'document' }

export const DAM_MUSIC = { name: '音乐', id: 'audio' }

export const DAM_EDIT_STATUS = [
  { name: '全部', id: 'all' },
  { name: '已完成编辑', id: 'true' },
  { name: '未完成编辑', id: 'false' },
]

export const DAM_PERMANENT_STATUS = [
  { name: '全部', id: 'all' },
  { name: '永久授权', id: 'true' },
  { name: '非永久授权（有效）', id: 'false_0' },
  { name: '非永久授权（已过期）', id: 'false_1' }, // 有限授权搜索：is_over 0：有效 1：过期，该参数只有permanent=false才有效
]

export const DAM_WARRANT_STATUS = [
  { name: '全部', id: 'all' },
  { name: '有版权文件', id: 'true' },
  { name: '无版权文件', id: 'false' },
]

export const DAM_EDIT_PERMANENT_STATUS = [
  { name: '永久授权', value: 1 },
  { name: '有限授权', value: 3 },
  { name: '授权不明', value: 2 },
]

export const DAM_REVIEW_STATUS_STATUS = [
  { name: '全部', id: 'all' },
  { name: '已通过', id: '3' },
  { name: '待提审', id: '1' },
  { name: '审核中', id: '2' },
]

export const CRM_PDF = [
  { value: 1, label: '产品授权及价格' },
  { value: 2, label: '公司介绍' },
  { value: 3, label: 'SS独家代理证明' },
  { value: 4, label: '公司营业执照' },
  { value: 5, label: '公司银行开户许可' },
  { value: 6, label: '信息安全等保证书' },
  { value: 7, label: 'ICP证书' },
  { value: 8, label: '文网文证' },
  { value: 9, label: '安全生产标准化证书' },
  { value: 10, label: '知识产权管理体系认证证书' },
]

export const SENSITIVE_WORDS = [
  {
    value: /(地|版)图/,
    warning: 'errors.sensitive-map',
  },
]

// 音乐标签分类
export const musicTagsType = {
  genres: 'page.music.category.genres',
  moods: 'page.music.album.moods',
  instruments: 'page.music.album.instrument',
  bpms: 'page.music.album.rhythm',
  providers: 'page.music.album.contributor',
  levels: 'page.music.album.rating',
}

// 音乐搜索params与标签类型的映射
export const mapTagsToAudioQuery = {
  genres: 'genreId',
  moods: 'moodId',
  instruments: 'instrumentId',
  bpms: 'bpmId',
  providers: 'providerId',
  levels: 'levelType',
}

export const DAM_UPLOAD_IMAGE_ACCEPTS = [
  'RAW',
  'NEF',
  'ARW',
  'CDR',
  'CR2',
  'SKETCH',
  'PSD',
  'DWG',
  'JPG',
  'JPEG',
  'PNG',
  'TIF',
  'TIFF',
  'SVG',
  'EPS',
  'AI',
]

export const DAM_UPLOAD_VIDEO_ACCEPTS = ['AVI', 'MOV', 'RMVB', 'RM', 'FLV', 'MP4', '3GP']

export const DAM_UPLOAD_FONT_ACCEPTS = ['TTF', 'OTF']

export const DAM_UPLOAD_DOCUMENT_ACCEPT = ['PDF', 'TXT', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'ZIP']

export const DAM_UPLOAD_MUSIC_ACCEPT = ['MP3', 'WAV', 'WMA', 'APE', 'FLAC', 'M4A', 'AAC']

export const DAM_CAN_PALY_MUSIC_ACCEPT = ['MP3']

export const DAM_COVER_ACCEPT = ['JPG', 'JPEG', 'PNG']

export const DAM_COVER_TOOLTIP = '修改预览图（限jpg/jpeg/png格式，不超过10M）'

export const AIGC_PICTURE_BY_PICTURE = ''

export const DAM_DELETE_EXPIRES_SETTING = {
  '-1': '保留删除的全部资源',
  30: '保留最近30天删除的资源，自删除日起超过30天后将自动删除',
}

export const VIDEO_FPS_FILTERS = [
  { text: 'filters.video.fps.23.98', key: 23.98 },
  { text: 'filters.video.fps.24', key: 24 },
  { text: 'filters.video.fps.25', key: 25 },
  { text: 'filters.video.fps.29.97', key: 29.97 },
  { text: 'filters.video.fps.30', key: 30 },
  { text: 'filters.video.fps.50', key: 50 },
  { text: 'filters.video.fps.59.94', key: 59.94 },
  { text: 'filters.video.fps.60', key: 60 },
  { text: 'filters.video.fps.other', key: 10000 },
]

export const VIDEO_FILTER_DURATION_START = 0
export const VIDEO_FILTER_DURATION_END = 600
export const ENTER_KEY_CODE = 13
export const OTHER_FPS = 10000

export const FONT_FILE_EXTS = {
  pdf: 'pdf',
  txt: 'txt',
  doc: 'doc',
  docx: 'doc',
  ppt: 'ppt',
  pptx: 'ppt',
  xlsx: 'xls',
  xls: 'xls',
  zip: 'zip',
}

export const UPLOAD_ERROR_MAP = {
  LimitError: '文件超出限制',
  TypeError: '文件格式不支持',
}

export const CREATIVE_TABS = [
  { id: 0, name: 'hellorf.picture' },
  { id: 1, name: 'page.creative.groups' },
]

export const LEGAL_DOMAIN = ['in.zcool.cn', 'hellorf.pub', 'hellorf.com', 'zcoolip.com'] // 合法域名

// 支付方式 - label icon 映射
export const PAYFOR_WAY = [
  { value: Payment.Ali, label: '支付宝', iconUrl: '//static.hellorf.com/plus/_next/images/pay-ali.svg' },
  { value: Payment.Wechat, label: '微信', iconUrl: '//static.hellorf.com/plus/_next/images/pay-wechat.svg' },
]

// 收藏夹排序
export const COLLEACTION_SORTS = [
  { text: 'filters.collection.newest', key: 'created_at', by: 'descend' },
  { text: 'filters.collection.oldest', key: 'created_at', by: 'asc' },
  { text: 'filters.collection.nearest', key: 'updated_at', by: 'descend' },
  { text: 'filters.collection.most', key: 'cnt', by: 'descend' },
]

// 希音订单状态
export const SHEIN_ORDER_STATUS = {
  /** 待支付 */
  unpaid: 0,
  /** 成功 */
  success: 1,
  /** 关闭 */
  close: 2,
  /** 取消 */
  cancel: 3,
  /** 退款 */
  refund: 4,
  /** 到账审核中 */
  checking: 5,
}

// 发票状态
// 0=>申请中,1=>发票已开,2=>发票已拒绝,3=>已废弃,4=>作废中,5=>开票中, ,
export const INVOICE_STATUS = {
  /** 申请中 */
  apply: 0,
  /** 发票已开 */
  success: 1,
  /** 发票已拒绝 */
  refuse: 2,
  /** 已废弃 */
  abandon: 3,
  /** 作废中 */
  abandoning: 4,
  /** 开票中 */
  making: 5,
}
export const INVOICE_STATUS_MAP = [
  { label: 'table.order.invoice-status-applying', value: INVOICE_STATUS.apply },
  { label: 'table.order.invoice-status-billed', value: INVOICE_STATUS.success },
  { label: 'table.order.invoice-status-refuse', value: INVOICE_STATUS.refuse },
  { label: 'table.order.invoice-status-obsolete', value: INVOICE_STATUS.abandon },
  { label: 'table.order.invoice-status-obsoleting', value: INVOICE_STATUS.abandoning },
  { label: 'table.order.invoice-status-invoicing', value: INVOICE_STATUS.making },
]

// 是否展示希音弹窗
export const SHOW_SHEIN_MODAL = 'SHOW_SHEIN_MODAL'

// 同步希音状态
export const SYNC_SHEIN_STATUS = {
  /** 默认 */
  default: 0,
  /** 同步中 */
  syncing: 1,
  /** 成功 */
  success: 2,
  /** 失败 */
  fail: 3,
}
export const SYNC_SHEIN_STATUS_TEXT = [
  { value: SYNC_SHEIN_STATUS.default, label: 'table.account.sync-status-default' },
  { value: SYNC_SHEIN_STATUS.syncing, label: 'table.account.sync-status-syncing' },
  { value: SYNC_SHEIN_STATUS.success, label: 'table.account.sync-status-success' },
  { value: SYNC_SHEIN_STATUS.fail, label: 'table.account.sync-status-fail' },
]

// 发票类型
export const INVALID_TYPES = {
  INCREASE: 1,
  SPECIAL: 2,
  NORMAL: 3,
}

// 字体编码
export const FONT_CODE = [
  { key: 1, name: 'GB2312（简体）' },
  { key: 2, name: 'GB12345（繁体）' },
  { key: 3, name: 'BIG5（港台）' },
  { key: 4, name: 'GBK（简繁扩展）' },
  { key: 5, name: 'GB18030' },
]

// DAM 大客户上传限制白名单
export const DAM_WHITE_USER = [574]

// AIGC 风格
export const AIGC_STYLE = [
  // {key: }
]

// AIGC 模型
export const AIGC_MODELS = [
  { key: 'general', value: '通用大模型' },
  { key: 'real', value: '写实模型' },
  { key: 'art', value: '艺术模型' },
  { key: 'anime', value: '二次元模型' },
  { key: '3danime', value: '3D动漫模型' },
]

// AIGC 尺寸
export const AIGC_PIC_SIZE = [
  { key: '1::1', value: 'square', wid: '1024', hg: '1024' },
  { key: '3::4', value: 'three-rect', wid: '768', hg: '1024' },
  { key: '4::3', value: 'four-rect', wid: '1024', hg: '768' },
]

// AIGC 风格enum
export const AIGC_STYLE_LIST = [
  {
    id: '不限',
    value: 'high definition',
  },
  {
    id: '动漫',
    value: 'animation style',
  },
  {
    id: '史诗',
    value: 'epic composition',
  },
  {
    id: 'CG',
    value: 'CG scenario',
  },
  {
    id: '油画',
    value: 'oil painting',
  },
  {
    id: '水彩画',
    value: 'watercolor with',
  },
  {
    id: '摄影',
    value: 'photograph by canon',
  },
  {
    id: '赛博朋克',
    value: 'Cyberpunk style',
  },
  {
    id: '未来主义',
    value: 'futurism style',
  },
  {
    id: '素描',
    value: 'pencil drawing',
  },
  {
    id: '卡通画',
    value:
      "2D, cartoon style, Disney's Animated, animation pixar style,  by pendleton ward, magali villeneuve, artgerm, rob rey and kentaro miura style",
  },
  {
    id: '中国画',
    value: 'Chinese court painting',
  },
  {
    id: '霓虹游戏',
    value:
      'glowing neon pink blocks floating around, by peter mohrbacher dan mumford craig mullins nekro, cgsociety, pixiv, volumetric light, 3d render',
  },
  {
    id: '吉卜力',
    value: 'Ghibli style',
  },
  {
    id: '水晶朋克',
    value: 'opalescent crystal, magical, hyperrealistic, octane render',
  },
  {
    id: '迷幻',
    value: 'vivid colors, detailed lines, detailed portrait, poster styl',
  },
  {
    id: '低聚',
    value: 'low ploygon',
  },
  {
    id: '像素',
    value: 'pixel art',
  },
  {
    id: '哑光',
    value: 'matte painting',
  },
  {
    id: '室内设计',
    value: 'octane render, amazing colors, bloom, hdri, 8k high quality, trending on art station',
  },
  {
    id: '蒸汽波',
    value: 'Vaporwave style',
  },
  {
    id: '印象派',
    value: 'Impressionism style',
  },
  {
    id: '浮世绘',
    value: 'Ukiyo-e art',
  },
  {
    id: '逆波',
    value:
      'retrowave palette, digital world, highly detailed, electric breeze, anatomically correct, synth feel,  4k digital art',
  },
  {
    id: '城市',
    value: '80s city pop style',
  },
  {
    id: '新中式',
    value: 'by huang guangjian, fenghua zhong, ruan jia, xin jin and wei chang.',
  },
]

export const VIDEO_TYPE = {
  1: 'AE视频',
  2: '视频素材',
}

// DAM账户状态
// 0试用，1已开通，2未开通，3已关闭
export const DAM_STATUS = {
  attempt: 0,
  open: 1,
  notopen: 2,
  closed: 3,
}

/** 共创设计定制 */
export const CDN_PATH = 'https://huasheng-img.zcool.cn/zcool_design/cc/hellorf_landing'

export const COMPETITION = [
  {
    img_id: 382,
    cover: '/competition1 - sumsung.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/event/sanxingzhp/',
    type_cn: '赛事共创',
    type_en: 'COMPETITION CO-CREATION',
    category: 'IP',
    desc: '三星智慧显示器二次元IP形象征集大赛',
    union_host: '站酷  X  三星',
  },
  {
    img_id: 561,
    cover: '/competition2 - smart.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/event/smart/',
    type_cn: '赛事共创',
    type_en: 'COMPETITION CO-CREATION',
    category: '插画',
    desc: 'smartidea潮T共创大赛',
    union_host: '站酷 X smart',
  },
  {
    img_id: 436,
    cover: '/competition3 - thegreatwall.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/event/thegreatwall/',
    type_cn: '赛事共创',
    type_en: 'COMPETITION CO-CREATION',
    category: '周边',
    desc: '中国长城文化创意创新大赛',
    union_host: '站酷  X  中国长城学会',
  },
  {
    img_id: 389,
    cover: '/competition4 - ymilk.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/event/ymilk/',
    type_cn: '赛事共创',
    type_en: 'COMPETITION CO-CREATION',
    category: '海报',
    desc: 'Y-Milk未来牛奶平台包装设计大赛',
    union_host: '站酷 X 伊利',
  },
  {
    img_id: 438,
    cover: '/competition5 - tangchenbeijian.jpeg',
    cover_linkurl: 'https://www.zcool.com.cn/event/tangchenbeijian/',
    type_cn: '赛事共创',
    type_en: 'COMPETITION CO-CREATION',
    category: '产品',
    desc: '汤臣倍健产品焕新设计大赛',
    union_host: '站酷  X  汤臣倍健',
  },
  {
    img_id: 562,
    cover: '/competition6 - wps.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/event/wps/',
    type_cn: '赛事共创',
    type_en: 'COMPETITION CO-CREATION',
    category: '素材',
    desc: 'WPS稻壳儿办公软件素材征集大赛',
    union_host: '站酷  X  WPS Office  X  稻壳儿',
  },
]

export const DESIGN = [
  {
    img_id: 388,
    cover: '/design1-caisizhirui.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/article/ZMTMwMDYzMg\u003d\u003d.html',
    type_cn: '设计共创',
    type_en: 'DESIGN CO-CREATION',
    industry: '快消',
    desc: '蔡司智锐镜片系列包装设计',
    union_host: '站酷 X 蔡司',
  },
  {
    img_id: 582,
    cover: '/design2-dongfeng.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/special/exhibition/DF',
    type_cn: '设计共创',
    type_en: 'DESIGN CO-CREATION',
    industry: '汽车',
    desc: '潮燃车贴站酷艺术家作品展',
    union_host: '站酷 X 东风风光',
  },
  {
    img_id: 576,
    cover: '/design3-media.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/article/ZMTMzMDEzNg\u003d\u003d.html',
    type_cn: '设计共创',
    type_en: 'DESIGN CO-CREATION',
    industry: '家电家居',
    desc: '美的集团M博IP形象设计',
    union_host: '站酷 X 美的集团',
  },
  {
    img_id: 387,
    cover: '/design4-mi-mix2.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/article/ZMTMwMDIyMA\u003d\u003d.html',
    type_cn: '设计共创',
    type_en: 'DESIGN CO-CREATION',
    industry: 'IT/3C',
    desc: '小米MIX2全面屏手机创意海报设计',
    union_host: '站酷 X 小米',
  },
  {
    img_id: 410,
    cover: '/design5-maoting-dell.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/article/ZMTMyNTAyNA==.html',
    type_cn: '艺术家共创',
    type_en: 'ARTISTS CO-CREATION',
    industry: 'IT/3C',
    desc: '戴尔Precision移动工作站 设计工作室评测',
    union_host: '站酷 X 戴尔 X 毛婷 / SEEN VISION',
  },
  {
    img_id: 587,
    cover: '/design6-B.Duck.jpg',
    cover_linkurl: 'https://www.zcool.com.cn/article/ZMTMwMTgwOA\u003d\u003d.html',
    type_cn: '艺术家共创',
    type_en: 'ARTISTS CO-CREATION',
    industry: '潮流IP',
    desc: 'B.Duck x ZCOOL联名扑克设计',
    union_host: '站酷 X B.Duck',
  },
]

export const SERVICELIST = [
  {
    key: 'zcool-gc-posters',
    text: '海报物料',
    desc: '10万+头部品牌海报设计解决方案，涵盖电商、游戏、影视、汽车、快消等多行业，线上线下多场景覆盖<br/>丰富案例辅助决策，专业服务全程提供',
    img: [
      {
        target: 'https://www.zcool.com.cn/article/ZMTMzMDEwOA==.html',
        title: '站酷 X 李宁',
        secondTitle: '李宁旗舰店新店开业系列海报设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/poster_material/3.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTMwMTc2MA==.html',
        title: '站酷 X ThinkPad',
        secondTitle: 'ThinkPad双生隐士国漫海报创作',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/11.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTMzMDEzMg==.html',
        title: '站酷 X 高通',
        secondTitle: '高通骁龙5G毫米波概念海报设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/poster_material/2.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTMwMDIyMA==.html',
        title: '站酷 X 小米',
        secondTitle: '小米MIX2全面屏手机创意海报设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/10.png',
      },
    ],
  },
  {
    key: 'zcool-gc-paint',
    text: '平面插画',
    desc: '1500万注册设计师，平面插画类占比超40%，全行业多领域覆盖',
    img: [
      {
        target: 'https://www.zcool.com.cn/event/wangzhedunhuang/',
        title: '站酷 X 王者荣耀',
        secondTitle: '主题设定全品类征集大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/9.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTMzMDEyMA==.html',
        title: '站酷 X 长虹 X 四川航空',
        secondTitle: '长虹x川航飞机涂装插画设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/16.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/wps2/',
        title: '站酷 X 金山 WPS',
        secondTitle: 'WPS品牌创意图形图案设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/6.png',
      },
      {
        target: 'https://www.zcool.com.cn/special/exhibition/DF',
        title: '站酷 X 东风风光',
        secondTitle: '潮燃车贴站酷艺术家作品展',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/4.png',
      },
    ],
  },
  {
    key: 'zcool-gc-ipdesign',
    text: 'IP 设计',
    desc: '从概念塑造到人物设定，从抽象形象到视觉化演绎；<br/>实现一站式全链路IP孵化&运营&生产&衍生定制&跨界合作',
    img: [
      {
        target: 'https://www.zcool.com.cn/article/ZMTMzMDEzNg==.html',
        title: '站酷 X 美的',
        secondTitle: '美的集团M博IP形象设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/ip/4.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTMzMDExNg==.html',
        title: '站酷 X 若来',
        secondTitle: '若来文创IP&盲盒设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/8.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/kuaishouip/',
        title: '站酷 X 快手',
        secondTitle: '快手十周年IP创意设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/ip/1.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTMwMTgyMA==.html',
        title: '站酷 X 英雄联盟',
        secondTitle: '云顶之弈小小英雄IP形象设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/ip/3.png',
      },
    ],
  },
  {
    key: 'zcool-gc-surround',
    text: '包装周边',
    desc: '深挖产品特性，打造独一无二的个性化产品包装；<br/>以颜值助力产品出圈，打造爆款包装&周边设计',
    img: [
      {
        target: 'https://www.zcool.com.cn/article/ZMTMwMTgwOA==.html',
        title: '站酷 X B.Duck小黄鸭',
        secondTitle: 'B.Duck x ZCOOL联名扑克设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/packaging_perimeter/4.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/ymilk/',
        title: '站酷 X 伊利',
        secondTitle: 'Y-Milk未来牛奶平台包装设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/packaging_perimeter/5.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/jissbon2021/',
        title: '站酷 X 杰士邦',
        secondTitle: '杰士邦玻尿酸系列产品视觉设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/2.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTMwMDYzMg==.html',
        title: '站酷 X 蔡司眼镜',
        secondTitle: '蔡司智锐镜片系列包装设计',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/packaging_perimeter/2.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/tangchenbeijian/',
        title: '站酷 X 汤臣倍健',
        secondTitle: '汤臣倍健产品焕新设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/packaging_perimeter/6.png',
      },
    ],
  },
  {
    key: 'zcool-gc-branddesign',
    text: '品牌设计',
    desc: '从品牌故事到 LOGO演绎、从品牌形象到CI规范；<br/>专注品牌全案设计，聚焦视觉创新和品牌升级',
    img: [
      {
        target: 'https://www.zcool.com.cn/event/bytedance/',
        title: '站酷 X 字节跳动',
        secondTitle: '字节跳动 LOGO 创意延展大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/brand/3.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/wanda/',
        title: '站酷 X 万达',
        secondTitle: '万达 30 周年 LOGO 设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/brand/2.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/baidudriverless/',
        title: '站酷 X 百度',
        secondTitle: '百度无人车 LOGO 设计征集',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/brand/1.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/zhongguoliantong',
        title: '站酷 X 联通',
        secondTitle: '中国联通青少年品牌创意征集大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/7.png',
      },
    ],
  },
  {
    key: 'zcool-gc-product',
    text: '三维/产品',
    desc: '依托平台大数据，根据项目需求精准匹配设计师；<br/>具备概念汽车设计、电子产品设计，食品饮料设计、生活小物设计等全品类设计经验',
    img: [
      {
        target: 'https://www.zcool.com.cn/event/bellefashion',
        title: '站酷 X 百丽',
        secondTitle: '百丽时尚鞋履美学设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/1.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/oppo_enco_free/',
        title: '站酷 X OPPO',
        secondTitle: 'OPPO Enco Free耳机套设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/3d_product/4.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/jilijihe/',
        title: '站酷 X 北京越野',
        secondTitle: '北京越野概念车设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/3.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/baijiumenggongchang/',
        title: '站酷 X 白酒梦工厂',
        secondTitle: '白酒梦工场产品创意设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/3d_product/1.png',
      },
    ],
  },
  {
    key: 'zcool-gc-pages',
    text: 'UI/网页',
    desc: '将产品功能、互动体验和界面美观性深度融合；<br/>为企业或品牌提供既符合用户体验又兼具产品个性的UI设计',
    img: [
      {
        target: 'https://www.zcool.com.cn/event/oppowatch/',
        title: '站酷 X OPPO',
        secondTitle: 'OPPO  Watch 表盘设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/17.png',
      },
      {
        target: 'https://www.zcool.com.cn/event/hepingjingying/',
        title: '站酷 X 和平精英',
        secondTitle: '和平精英-冒险设计大赛',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/5.png',
      },
    ],
  },
  {
    key: 'zcool-gc-video',
    text: '视频/摄影',
    desc: '与艺术家、创意机构广泛合作，各类视频/摄影作品产出能力，包含但不限于：TVC、宣传片、social video、人物访谈等类型，CG、MG、逐帧动画、定格等表现手法',
    img: [
      {
        target: 'https://www.zcool.com.cn/article/ZMTQ0MTAyNA==.html',
        title: '站酷 X QQ星',
        secondTitle: '陪TA玩吧，趁TA还没长大',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/18.png',
      },
      {
        target: 'https://www.zcool.com.cn/article/ZMTM3ODE0MA==.html',
        title: '站酷 X 站酷共创动画短片',
        secondTitle: '我叫站酷，请让我再做一次自我介绍',
        src: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/15.png',
      },
    ],
  },
]

export const SOLUTIONSLIST = [
  {
    firstImg: {
      // imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/14.png',
      imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new/1.png',
      mainTitle: '创意营销',
      subTitile: '从营销物料制作到创意内容生产、从年框合作到全案推广；为品牌提供定制化创意与营销解决方案',
    },
    conList: [
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/12.png',
        mainTitle: '站酷 X 安慕希',
        subTitile: '新品上市如何「高颜值」圈粉年轻人？',
        target: 'https://www.zcool.com.cn/article/ZMTI1MzUxMg==.html',
      },
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/14.png',
        mainTitle: '站酷 X 天猫服饰风尚',
        subTitile: '天猫 「万人万创」创意营销新玩法',
        target: 'https://www.zcool.com.cn/article/ZMTI3NDE3Ng==.html',
      },
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new20221221/13.png',
        mainTitle: '站酷 X 杰士邦',
        subTitile: '不一样的女性，渴望不一样的爱情“玻”长',
        target: 'https://www.zcool.com.cn/article/ZMTM2MDE4OA==.html',
      },
    ],
  },
  {
    firstImg: {
      // imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/9.png',
      imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new/3.png',
      mainTitle: '艺术家共创',
      subTitile:
        '携手头部艺术家，联动全球艺术资源，进行评测代言、活动出席、艺术演绎等艺术家共创活动。实现全方位艺术家背书，垂直领域私域流量赋能商业传播',
    },
    conList: [
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/10.png',
        mainTitle: '站酷 X Acer',
        subTitile: 'Acer ConceptD 产品代言',
        target: 'https://www.zcool.com.cn/article/ZMTA5NjYxMg==.html',
      },
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/1.png',
        mainTitle: '站酷 X 戴尔',
        subTitile: '戴尔Precision移动工作站 设计工作室评测',
        target: 'https://www.zcool.com.cn/article/ZMTMyNTAyNA==.html',
      },
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/12.png',
        mainTitle: '站酷 X ThinkPad',
        subTitile: 'ThinkPad X1隐士系列 艺术家评测',
        target: 'https://www.zcool.com.cn/article/ZMTMxNDExMg==.html',
      },
    ],
  },
  {
    firstImg: {
      // imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/2.png',
      imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/new/2.png',
      mainTitle: '公益共创',
      subTitile: '设计融合公益，用艺术守护公益善举。助力企业实现多品牌跨界，多渠道联合，重塑企业公益之美',
    },
    conList: [
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/7.png',
        mainTitle: '站酷 X 人民图片网',
        subTitile: '「待到春华烂漫时」用创作致敬战疫英雄',
        target: 'https://www.zcool.com.cn/special/zhijing/',
      },
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/13.png',
        mainTitle: '站酷 X UR',
        subTitile: '站酷 X UR 线上海洋艺术展',
        target: 'https://www.zcool.com.cn/special/exhibition/UR',
      },
      {
        imgUrl: 'https://huasheng-static.zcool.com.cn/hellorf-plus/entrance/3.png',
        mainTitle: '站酷 X Acer',
        subTitile: '宏碁Vero未来地球守卫者计划',
        target: 'https://www.zcool.com.cn/special/protectorplan/',
      },
    ],
  },
]

export const DESIGNTYPELIST = [
  {
    value: '海报物料',
    label: '海报物料',
  },
  {
    value: '平面插画',
    label: '平面插画',
  },
  {
    value: 'IP 设计',
    label: 'IP 设计',
  },
  {
    value: '包装周边',
    label: '包装周边',
  },
  {
    value: '品牌设计',
    label: '品牌设计',
  },
  {
    value: '3D/产品',
    label: '3D/产品',
  },
  {
    value: 'UI/网页',
    label: 'UI/网页',
  },
  {
    value: '视频/摄影',
    label: '视频/摄影',
  },
]

export const MONEYBUGDET = [
  {
    value: '万元以内',
    label: '万元以内',
  },
  {
    value: '1-10万',
    label: '1-10万',
  },
  {
    value: '10-100万',
    label: '10-100万',
  },
  {
    value: '100万以上',
    label: '100万以上',
  },
  {
    value: '预算待定',
    label: '预算待定',
  },
]
