import { Modal, ConfigProvider } from 'antd'
import { Button } from 'components/antd'
import Icon from 'components/ui/icon'
import styled from 'styled-components'

const WrapperModal = styled(Modal)`
  .ant-modal-content {
    padding: 32px;
  }
`

const DivContaienr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 24px;

  .modal-text {
    color: #616366;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .modal-text-item {
      display: flex;
      align-items: center;
      gap: 12px;

      .modal-text-item-icon {
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        background: #fff7f6;
      }

      .modal-text-item-text {
        display: flex;
        flex-direction: column;

        .modal-text-item-title {
          color: #616366;
          font-family: 'PingFang SC';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .modal-text-item-content {
          color: #939599;
          font-family: 'PingFang SC';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .modal-action {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    margin-top: 40px;

    button {
      flex: 1;
    }
  }
`
interface IProps {
  title: string
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
  handleUpdateModalConfirm: () => void
}
export default function UpdateModal({ title, isModalOpen, setIsModalOpen, handleUpdateModalConfirm }: IProps) {
  const handleCancel = () => {
    setIsModalOpen(false)
    handleUpdateModalConfirm()
  }

  const handleOk = () => {
    setIsModalOpen(false)
    handleUpdateModalConfirm()
  }

  return (
    <WrapperModal width={480} footer={null} title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <DivContaienr>
        <div className="modal-text">
          <div className="modal-text-item">
            <div className="modal-text-item-icon">
              <Icon glyph="dam-update-1" size={24} color="#F74640"></Icon>
            </div>
            <div className="modal-text-item-text">
              <div className="modal-text-item-title">文件分享链接</div>
              <div className="modal-text-item-content">设置外部访问权限、密码及有效期</div>
            </div>
          </div>
          <div className="modal-text-item">
            <div className="modal-text-item-icon">
              <Icon glyph="dam-update-2" size={24} color="#F74640"></Icon>
            </div>
            <div className="modal-text-item-text">
              <div className="modal-text-item-title">上传和下载审核</div>
              <div className="modal-text-item-content">开启后，审核通过方可进行操作</div>
            </div>
          </div>
        </div>
        <div className="modal-action">
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  primaryColor: '#fff',
                  colorPrimary: '#F74640',
                  colorPrimaryHover: '#F74640',
                  colorPrimaryActive: '#c23322',
                  defaultColor: '#1C1D1F',
                  defaultBorderColor: '#DCDCDC',
                  fontSize: 14,
                  fontWeight: 500,
                },
              },
            }}
          >
            <Button className="confirm-btn" type="primary" onClick={handleOk}>
              查看
            </Button>
          </ConfigProvider>
        </div>
      </DivContaienr>
    </WrapperModal>
  )
}
