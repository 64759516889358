import { Checkbox, CheckboxProps, ConfigProvider } from 'antd'
import styled from 'styled-components'

export default function CheckBox({ children, indeterminate, onChange, checked, className, ...props }: CheckboxProps) {
  return (
    <CheckboxLayout>
      <ConfigProvider
        theme={{
          components: {
            Checkbox: {
              colorPrimary: '#F74640',
              colorPrimaryHover: '#F74640',
              colorBorder: '#D0D2D6',
            },
          },
        }}
      >
        <Checkbox indeterminate={indeterminate} onChange={onChange} checked={checked} className={className} {...props}>
          {children}
        </Checkbox>
      </ConfigProvider>
    </CheckboxLayout>
  )
}

const CheckboxLayout = styled.div`
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.75);
    background-color: #f74640;
    border-radius: 3px;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 2px;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 2px;
  }
`
