/** 本地开发环境
 * @enum pre | beta
 */
const devEnv = 'pre'

const APPID = 10021
const DAM_MAX_IMG_SIZE = 1 // DAM系统中jpg/png文件上传的最大限制 G 1
const DAM_MAX_VIDEO_SIZE = 10 // DAM系统中视频文件上传的最大限制 G
const DAM_MAX_FONT_SIZE = 50 // DAM系统中字体文件上传的最大限制 MB
const DAM_MAX_DOCUMENT_SIZE = 2 // DAM系统中文档类型文件上传的最大限制 G
const DAM_MAX_MUSIC_SIZE = 1 // DAM系统中音乐类型文件上传的最大限制 G

const CUSTOM_DAM_MAX_IMG_SIZE = 5 // DAM系统中jpg/png文件上传的最大限制 G
const CUSTOM_DAM_MAX_DOCUMENT_SIZE = 5 // DAM系统中文档类型文件上传的最大限制 G
const isDev = process.env.ENV === 'dev' || !process.env.ENV
const env = isDev ? 'development' : process.env.ENV // 自定义环境变量
const currentDomain = 'https://plus.hellorf.com'
let url = {}
switch (env) {
  /**  生产环境 */
  case 'production':
    url = {
      SELF_URL: currentDomain,
      BASE_URL: `${currentDomain}/api`,
      // BASE_URL_SERVER: 'https://api.hellorf.com/pro',
      // AI_TOOL_URL: 'https://aitool-api.hellorf.com',
      /**内网域名 */
      BASE_URL_SERVER: 'http://backbone-plus.zcool-hellorf-be.cloud.m.com/pro',
      HELLORF_BASE_URL_SERVER: 'http://inner-backbone-api.zcool-hellorf-be.cloud.m.com/hellorf',
      PASSPORT_SERVICE: 'http://passport.zcool-hellorf-fe.cloud.m.com',
      AI_TOOL_URL: 'http://aitool-api.zcool-aitool.cloud.m.com',
      WEB_FONT_SERVICE: 'http://web-fonts.zcool-hellorf-fe.cloud.m.com/hellorf',
      GO_BASE_URL: 'http://go-plus.zcool-hellorf-be.cloud.m.com',
      /** end=======*/
      PASSPORT_URL: 'https://passport.hellorf.com',
      // EDITOR_BASE_URL: 'https://editor.hellorf.com/editor/image',
      HELLORF_BASE_URL: `${currentDomain}/api/hellorf`,
      STATIC_IMG_URL: 'https://static.hellorf.com',
      PLUS_WAP_URL: 'https://mplus.hellorf.com',
    }
    break
  /**  beta环境 */
  case 'beta':
    url = {
      /**本地调试 */
      // BASE_URL: 'https://local.hellorf.com/api',
      // BASE_URL_SERVER: 'https://beta-api.hellorf.com/pro',
      // HELLORF_BASE_URL_SERVER: 'https://beta-api.hellorf.com/hellorf',
      // PASSPORT_SERVICE: 'https://beta-passport.hellorf.com/api',
      // AI_TOOL_URL: 'https://beta-aitool-api.hellorf.com',
      /** end=======*/
      SELF_URL: 'https://beta-plus.hellorf.com',
      BASE_URL: 'https://beta-plus.hellorf.com/api',
      /**内网域名 */
      BASE_URL_SERVER: 'http://backbone-plus-beta.zcool-hellorf-be.cloud.m.com/pro',
      HELLORF_BASE_URL_SERVER: 'http://beta-backbone-inner-api-beta.zcool-hellorf-be.cloud.m.com/hellorf',
      WEB_FONT_SERVICE: 'http://web-font-beta.zcool-hellorf-fe.cloud.m.com/hellorf',
      PASSPORT_SERVICE: 'http://passport-beta.zcool-hellorf-fe.cloud.m.com',
      AI_TOOL_URL: 'http://beta-aitool-api.zcool-aitool.cloud.m.com',
      GO_BASE_URL: 'http://go-plus-beta.zcool-hellorf-be.cloud.m.com',
      /** end=======*/
      PASSPORT_URL: 'https://beta-passport.hellorf.com',
      // EDITOR_BASE_URL: 'https://editor.hellorf.com/editor/image',
      HELLORF_BASE_URL: 'https://beta-plus.hellorf.com/api/hellorf',
      STATIC_IMG_URL: 'https://static-test.hellorf.com/beta',
      PLUS_WAP_URL: 'https://beta-mplus.hellorf.com',
    }
    break
  case 'pre':
    url = {
      SELF_URL: 'https://pre-plus.hellorf.com',
      BASE_URL: 'https://pre-plus.hellorf.com/api/',
      // BASE_URL_SERVER: 'https://pre-api.hellorf.com/pro',
      // HELLORF_BASE_URL_SERVER: 'https://pre-api.hellorf.com/hellorf',
      // PASSPORT_SERVICE: 'https://pre-passport.hellorf.com/api',
      // AI_TOOL_URL: 'https://pre-plus.hellorf.com/api/aitool',
      /**
       * 内网域名
       * */
      BASE_URL_SERVER: 'http://backbone-plus-pre.zcool-hellorf-be.cloud.m.com/pro',
      HELLORF_BASE_URL_SERVER: 'https://pre-alpha.hellorf.com',
      WEB_FONT_SERVICE: 'http://web-font-beta.zcool-hellorf-fe.cloud.m.com/hellorf',
      PASSPORT_SERVICE: 'http://passport-pre.zcool-hellorf-fe.cloud.m.com',
      AI_TOOL_URL: 'http://pre-aitool-api.zcool-aitool.cloud.m.com',
      GO_BASE_URL: 'http://go-plus-pre.zcool-hellorf-be.cloud.m.com',
      /**
       * end=======
       * */
      HELLORF_BASE_URL: 'https://pre-plus.hellorf.com/api/hellorf',
      // EDITOR_BASE_URL: 'https://pre-editor.hellorf.com/editor/image',
      // EDITOR_BASE_URL: 'https://editor-alpha.in.zcool.cn/editor/image',
      // STATIC_IMG_URL: 'https://static-test.hellorf.com/pre',
      STATIC_IMG_URL: 'https://static-test.hellorf.com/alpha',
      PASSPORT_URL: 'https://pre-passport.hellorf.com',
      GO_BASE_URL: 'http://go-plus-pre.zcool-hellorf-be.cloud.m.com',
    }
    break
  case 'development':
    url = {
      BASE_URL: 'https://local.hellorf.com:443/api',
      BASE_URL_SERVER: `http://backbone-plus-${devEnv}.zcool-hellorf-be.cloud.meitu-int.com/pro`,
      HELLORF_BASE_URL_SERVER: `https://${devEnv}-api.hellorf.com/hellorf`,
      PASSPORT_SERVICE: `https://${devEnv}-passport.hellorf.com`,
      AI_TOOL_URL: `https://${devEnv}-aitool-api.hellorf.com`,
      WEB_FONT_SERVICE: `https://beta-webfont.hellorf.com/hellorf`,
      HELLORF_BASE_URL: `https://${devEnv}-plus.hellorf.com/api/hellorf`,
      // EDITOR_BASE_URL: 'https://pre-editor.hellorf.com/editor/image',
      // STATIC_IMG_URL: 'https://static-test.hellorf.com/pre',
      STATIC_IMG_URL: `https://static-test.hellorf.com/alpha`,
      PASSPORT_URL: `https://${devEnv}-passport.hellorf.com`,
      PLUS_WAP_URL: `https://${devEnv}-mplus.hellorf.com`,
      HELLORF_BASE_URL_SERVER: 'https://pre-alpha.hellorf.com',
      WEB_FONT_SERVICE: 'http://web-font-beta.zcool-hellorf-fe.cloud.m.com/hellorf',
      PASSPORT_SERVICE: 'http://passport-pre.zcool-hellorf-fe.cloud.meitu-int.com',
      AI_TOOL_URL: 'http://aitool-api-pre.zcool-aitool.cloud.meitu-int.com',
      GO_BASE_URL: 'http://go-plus-pre.zcool-hellorf-be.cloud.meitu-int.com',
    }
    break
  default:
    break
}

const { PASSPORT_URL } = url
const PASSPORT_CHANGE_PHONE_URL = `${PASSPORT_URL}/account-safe/change-phone?appid=${APPID}`
module.exports = {
  APPID,
  CART_COUNT: 0,
  DAM_MAX_IMG_SIZE,
  DAM_MAX_VIDEO_SIZE,
  DAM_MAX_FONT_SIZE,
  DAM_MAX_DOCUMENT_SIZE,
  DAM_MAX_MUSIC_SIZE,
  API_TIMEOUT_INTERVAL: 30 * 1000,
  DOWNLOAD_TIMEOUT_INTERVAL: 60 * 1000,
  PASSPORT_URL,
  PASSPORT_LOGIN_URL: `${PASSPORT_URL}/?appid=${APPID}&status=1`,
  PASSPORT_MODIFY_USER_NAME_URL: `${PASSPORT_URL}/account-safe/change-name?appid=${APPID}`,
  PASSPORT_MODIFY_EMAIL_URL: `${PASSPORT_URL}/account-safe/change-email?appid=${APPID}`,
  PASSPORT_MODIFY_PHONE_URL: PASSPORT_CHANGE_PHONE_URL,
  PASSPORT_BIND_PHONE_URL: PASSPORT_CHANGE_PHONE_URL,
  PASSPORT_MODIFY_PASSWORD_URL: `${PASSPORT_URL}/account-safe/change-password?appid=${APPID}`,
  CUSTOM_DAM_MAX_IMG_SIZE,
  CUSTOM_DAM_MAX_DOCUMENT_SIZE,
  COOKIE_NAME: 'hellorf_session',
  COOKIE_DOMAIN: '.hellorf.com',
  AI_TOOL_URL: 'https://pre-plus.hellorf.com/api/aitool', // 测试环境默认地址
  SENTRY_DSN: '', // 已弃用
  ...url,
}
