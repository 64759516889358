import { MeituAnalyze } from 'typings/meitu-analyze'
import { canUseDOM, handlePollingMtstatIsInitialized } from './util'
import { MeituAnalyzeSearchContentType, MeituAnalyzeSearchType } from './enums'
import Cookies from 'js-cookie'
import { COOKIE_DOMAIN } from 'shared/env'

// 本地调试的时候改成false
const bool = false
// const bool = process.env.NODE_ENV === 'development'

/** meitu埋点初始化 */
export const meituAnalyzeInit = (currentUser) => {
  // 环境判断 非开发
  if (bool) return
  // windows 浏览器环境
  if (!canUseDOM()) return

  /** sdk初始化 */
  const initFunction = () => {
    // console.warn('device_id ===========> ', Cookies.get('device_id'))
    // console.warn('mt_device_id ===========> ', window.mtstat.getDeviceId())
    // 监听主站发送事件
    // window.addEventListener('message', function (e) {
    //   if (e.origin + '/syncDeviceId' !== iframeSrc) return
    //   const deviceId = e.data.DeviceId
    const device_id = Cookies.get('device_id')
    !Cookies.get('mt_device_id') && Cookies.set('mt_device_id', window.mtstat.getDeviceId(), { domain: COOKIE_DOMAIN })
    const { locale } = window.__NEXT_DATA__.props
    window.mtstat.init({
      module_id: 'hellorf_plus',
      app_key: process.env.ENV === 'production' ? 'DCAB804E1A9C465A' : 'D837460613449DEB',
      env: process.env.ENV === 'production' ? 'release' : 'test',
      app_language: locale === 'en' ? 'en-CN' : 'zh-CN',
      customizedStatsMarker: device_id,
    })
    // 公共参数
    const { passport_id, id } = currentUser || {}
    const commonParam: MeituAnalyze.CommonParam = {
      zcool_uid: String(passport_id),
      gid: String(id),
      device_id,
    }
    window.mtstat.setCommonParams(commonParam)

    if (currentUser) {
      // 美图登录
      window.mtstat.login(passport_id || id)
    } else {
      // 美图登出
      window.mtstat.logout()
    }
    // })
  }

  if (!window.mtstat) {
    // 没有mtstat则动态加载sdk
    const script = document.createElement('script')
    script.src = '//public.static.meitudata.com/meitu/mtstat-sdk/mtstat-sdk.min.js'
    script.onload = function () {
      // if (window.location.href.includes('syncDeviceId')) return
      initFunction()
    }
    // 处理加载错误的回调函数
    script.onerror = function () {
      console.error('Failed to load the script.')
    }
    document.head.appendChild(script)
  } else {
    // 有mtstat直接执行
    initFunction()
  }
}

/** 封装美图sdk埋点方法 */
const meituTrack = (track, props) => {
  // 环境判断 非开发
  if (bool) return
  // windows 浏览器环境
  if (!canUseDOM()) return
  // 美图sdk 加载成功
  if (!window.mtstat) return
  // 是否init sdk初始化成功
  handlePollingMtstatIsInitialized(() => window.mtstat.track(track, props))
}

export function safeExecute(fn: () => void): void {
  try {
    // console.warn(fn)
    fn()
  } catch (error) {
    console.error('error', fn)
  }
}

/** 搜索点击 */
export const meituSearchClick = (props: MeituAnalyze.SearchClickParam) => {
  meituTrack('plus_search_click', props)
}

/** 搜索成功 */
export const meituSearchSuccess = (props: MeituAnalyze.SearchSuccessParam) => {
  meituTrack('plus_search_success', props)
}

/** 搜索失败 */
export const meituSearchFail = (props: MeituAnalyze.SearchFailParam) => {
  meituTrack('plus_search_fail', props)
}

/** 图片搜索结果页类型点击 */
export const meituImageTabClick = (props: MeituAnalyze.ImageTabClickParam) => {
  meituTrack('plus_image_tab_click', props)
}

/** 视频搜索结果页类型点击 */

export const meituVideoTabClick = (props: MeituAnalyze.VideoParams) => {
  meituTrack('plus_video_tab_click', props)
}

/** 字体搜索结果页类型点击 */

export const meituTypeFaceTabClick = (props: MeituAnalyze.FontParams) => {
  meituTrack('plus_typeface_tab_click', props)
}

/** 音乐搜索结果页类型点击 */
export const meituMusicTabClick = (props: MeituAnalyze.MusicParams) => {
  meituTrack('plus_music_tab_click', props)
}

/** 搜索结果页素材卡片曝光 */
export const meituSearchResultsCardShow = (props: MeituAnalyze.SearchResultsCardClickParams) => {
  meituTrack('plus_search_results_card_show', props)
}

/** 搜索结果页素材卡片点击 */
export const meituSearchResultsCardClick = (props: MeituAnalyze.SearchResultsCardClickParams) => {
  meituTrack('plus_search_results_card_click', props)
}

/** 搜索结果页精选素材曝光 */
export const meituSearchResultsCardSetsShow = (props: MeituAnalyze.SearchResultSetsParams) => {
  meituTrack('plus_search_results_card_sets_show', props)
}

/** 搜索结果页精选素材点击 */
export const meituSearchResultsCardSetsClick = (props: MeituAnalyze.SearchResultSetsParams) => {
  meituTrack('plus_search_results_card_sets_click', props)
}

/**  素材详情页点击 */
export const meituSearchResultsInfoDownloadClick = (props: MeituAnalyze.PlusSearchResultsInfoDownloadParams) => {
  meituTrack('plus_search_results_info_download_click', props)
}

/** 素材收藏点击 */
export const meituCollectClick = (props: MeituAnalyze.CollectClickParams) => {
  meituTrack('plus_collect_click', props)
}

/** 素材收藏弹窗确认点击 */
export const meituCollectConfirmClick = (props: MeituAnalyze.CollectConfirmClickParams) => {
  meituTrack('plus_collect_confirm_click', props)
}

/** 素材成功加入清单 */
export const meituWishListClick = (props: MeituAnalyze.CollectClickParams) => {
  meituTrack('plus_wish_list_click', props)
}

/** 立即购买按钮点击 */
export const meituBuyNowClick = (props: MeituAnalyze.BuyNowClickParams) => {
  meituTrack('plus_buy_now_click', props)
}

/** 购买授权弹窗按钮点击 */
export const meituPlusServicePayClick = (props: MeituAnalyze.PlusServicePayClickParams) => {
  return props.key_word === '1' // 无用代码
  // meituTrack('plus_service_pay_click', props)
}

// /** ip合作咨询弹窗按钮点击 */
// export const meituIpTeamworkConsultClick = (props: MeituAnalyze.IpTeamworkConsultClickParams) => {
//   meituTrack('plus_ip_teamwork_consult_click', props)
// }

/** 素材详情页曝光 */
export const meituSearchResultsInfoShow = (props: MeituAnalyze.SearchResultsInfoShowParams) => {
  meituTrack('plus_search_results_info_show', props)
}

/** 大客户申请试用提交按钮点击 */
export const PlusApplyCommitClick = (props: MeituAnalyze.PlusApplyCommitClickParams) => {
  meituTrack('plus_apply_commit_click', props)
}

/** 图片首页专题轮播曝光 */
export const PlusImageHomepageSubjectGuideExpose = (props: MeituAnalyze.PlusImageHomepageSubjectGuideParams) => {
  meituTrack('plus_image_homepage_subject_guide_expose', props)
}

/** 图片首页专题轮播点击 */
export const PlusImageHomepageSubjectGuideClick = (props: MeituAnalyze.PlusImageHomepageSubjectGuideParams) => {
  meituTrack('plus_image_homepage_subject_guide_click', props)
}

/** 图片首页灵感图册曝光 */
export const PlusImageHomepageAlbumExpose = (props: MeituAnalyze.PlusImageHomepageAlbumParams) => {
  meituTrack('plus_image_homepage_album_expose', props)
}

/** 图片首页灵感图册点击 */
export const PlusImageHomepageAlbumClick = (props: MeituAnalyze.PlusImageHomepageAlbumParams) => {
  meituTrack('plus_image_homepage_album_click', props)
}

/** 图片首页灵感图册查看全部按钮点击 */
export const PlusImageHomepageAlbumAllClick = (props: MeituAnalyze.UrlCommonParams) => {
  meituTrack('plus_image_homepage_album_all_click', props)
}

/** 图片灵感图册页面顶部卡片曝光 */
export const PlusImageAlbumpageTopCardExpose = (props: MeituAnalyze.PlusImageAlbumpageTopCardParams) => {
  meituTrack('plus_image_albumpage_top_card_expose', props)
}

/** 图片灵感图册页面顶部卡片点击 */
export const PlusImageAlbumpageTopCardClick = (props: MeituAnalyze.PlusImageAlbumpageTopCardParams) => {
  meituTrack('plus_image_albumpage_top_card_click', props)
}

/** 图片灵感图册页面图册曝光 */
export const PlusImageAlbumpageAlbumExpose = (props: MeituAnalyze.PlusImageAlbumpageAlbumParams) => {
  meituTrack('plus_image_albumpage_album_expose', props)
}

/** 图片灵感图册页面图册点击 */
export const PlusImageAlbumpageAlbumClick = (props: MeituAnalyze.PlusImageAlbumpageAlbumParams) => {
  meituTrack('plus_image_albumpage_album_click', props)
}

/** 图片灵感图册页面类别按钮点击 */
export const PlusImageAlbumpageAlbumTypeClick = (props: MeituAnalyze.PlusExcellentpageTypeClickParams) => {
  meituTrack('plus_image_albumpage_album_type_click', props)
}

/** 图片灵感图册页面搜索结果 */
export const PlusImageAlbumpageSearchResults = (props: MeituAnalyze.PlusExcellentpageSearchResultsParams) => {
  meituTrack('plus_image_albumpage_search_results', props)
}

/** 图片首页专题卡片曝光 */
export const PlusImageHomepageSubjectExpose = (props: MeituAnalyze.PlusImageHomepageSubjectParams) => {
  meituTrack('plus_image_homepage_subject_expose', props)
}

/** 图片首页专题卡片点击 */
export const PlusImageHomepageSubjectClick = (props: MeituAnalyze.PlusImageHomepageSubjectParams) => {
  meituTrack('plus_image_homepage_subject_click', props)
}

/** 图片首页查看全部专题点击 */
export const PlusImageHomepageAllSubjectClick = (props: MeituAnalyze.UrlCommonParams) => {
  meituTrack('plus_image_homepage_all_subject_click', props)
}

/** 图片专题页面专题卡片曝光 */
export const PlusImageSubjectpageSubjectExpose = (props: MeituAnalyze.PlusImageSubjectpageSubjectParams) => {
  meituTrack('plus_image_subjectpage_subject_expose', props)
}

/** 图片专题页面专题卡片点击 */
export const PlusImageSubjectpageSubjectClick = (props: MeituAnalyze.PlusImageSubjectpageSubjectParams) => {
  meituTrack('plus_image_subjectpage_subject_click', props)
}

/** 图片专题页面分类按钮点击 */
export const PlusImageSubjectpageTypeClick = (props: MeituAnalyze.PlusSubjectpageSubjectParams) => {
  meituTrack('plus_image_subjectpage_type_click', props)
}

/** 图片首页新图速递卡片曝光 */
export const PlusImageLatestCardExpose = (props: MeituAnalyze.PlusImageLatestCardParams) => {
  meituTrack('plus_image_latest_card_expose', props)
}

/** 图片首页新图速递卡片点击 */
export const PlusImageLatestCardClick = (props: MeituAnalyze.PlusImageLatestCardParams) => {
  meituTrack('plus_image_latest_card_click', props)
}

/** 视频首页专题轮播曝光 */
export const PlusVideoHomepageSubjectGuideExpose = (props: MeituAnalyze.PlusVideoHomepageSubjectGuideParams) => {
  meituTrack('plus_video_homepage_subject_guide_expose', props)
}

/** 视频首页专题轮播点击 */
export const PlusVideoHomepageSubjectGuideClick = (props: MeituAnalyze.PlusVideoHomepageSubjectGuideParams) => {
  meituTrack('plus_video_homepage_subject_guide_click', props)
}

/** 视频首页专题卡片曝光 */
export const PlusVideoHomepageSubjectExpose = (props: MeituAnalyze.PlusVideoHomepageSubjectParams) => {
  meituTrack('plus_video_homepage_subject_expose', props)
}

/** 视频首页专题卡片点击 */
export const PlusVideoHomepageSubjectClick = (props: MeituAnalyze.PlusVideoHomepageSubjectParams) => {
  meituTrack('plus_video_homepage_subject_click', props)
}

/** 视频首页查看全部专题点击 */
export const PlusVideoHomepageSubjectAllClick = (props: MeituAnalyze.UrlCommonParams) => {
  meituTrack('plus_video_homepage_subject_all_click', props)
}

/** 视频专题页面专题卡片曝光 */
export const PlusVideoSubjectpageSubjectShow = (props: MeituAnalyze.PlusVideoSubjectpageSubjectShowParams) => {
  meituTrack('plus_video_subjectpage_subject_show', props)
}

/** 视频专题页面专题卡片点击 */
export const PlusVideoSubjectpageSubjectClick = (props: MeituAnalyze.PlusVideoSubjectpageSubjectShowParams) => {
  meituTrack('plus_video_subjectpage_subject_click', props)
}

/** 视频专题页面分类按钮点击 */
export const PlusVideoSubjectpageTypeClick = (props: MeituAnalyze.PlusSubjectpageSubjectParams) => {
  meituTrack('plus_video_subjectpage_type_click', props)
}

/** 视频首页精选视频卡片曝光 */
export const PlusVideoHomepageExcellentVideoExpose = (props: MeituAnalyze.PlusVideoHomepageExcellentVideoParams) => {
  meituTrack('plus_video_homepage_excellent_video_expose', props)
}

/** 视频首页精选视频卡片点击 */
export const PlusVideoHomepageExcellentVideoClick = (props: MeituAnalyze.PlusVideoHomepageExcellentVideoParams) => {
  meituTrack('plus_video_homepage_excellent_video_click', props)
}

/** 视频首页查看全部精选视频点击 */
export const PlusVideoHomepageExcellentVideoAllClick = (props: MeituAnalyze.UrlCommonParams) => {
  meituTrack('plus_video_homepage_excellent_video_all_click', props)
}

/** 视频精选页面精选视频卡片曝光 */
export const PlusVideoExcellentpageExcellentVideoExpose = (
  props: MeituAnalyze.PlusVideoExcellentpageExcellentVideoParams
) => {
  meituTrack('plus_video_excellentpage_excellent_video_expose', props)
}

/** 视频精选页面精选视频卡片点击 */
export const PlusVideoExcellentpageExcellentVideoClick = (
  props: MeituAnalyze.PlusVideoExcellentpageExcellentVideoParams
) => {
  meituTrack('plus_video_excellentpage_excellent_video_click', props)
}

/** 视频精选页面分类按钮点击 */
export const PlusVideoExcellentpageTypeClick = (props: MeituAnalyze.PlusExcellentpageTypeClickParams) => {
  meituTrack('plus_video_excellentpage_type_click', props)
}

/** 视频精选页面搜索结果 */
export const PlusVideoExcellentpageSearchResults = (props: MeituAnalyze.PlusExcellentpageSearchResultsParams) => {
  meituTrack('plus_video_excellentpage_search_results', props)
}

/** 音乐首页顶部轮播曝光 */
export const PlusMusicHomepageTopGuideExpose = (props: MeituAnalyze.PlusMusicHomepageTopGuideExposeParams) => {
  meituTrack('plus_music_homepage_top_guide_expose', props)
}

/** 音乐首页顶部轮播点击 */
export const PlusMusicHomepageTopGuideClick = (props: MeituAnalyze.PlusMusicHomepageTopGuideClickParams) => {
  meituTrack('plus_music_homepage_top_guide_click', props)
}

/** 音乐首页专题卡片曝光 */
export const PlusMusicHomepageSubjectExpose = (props: MeituAnalyze.PlusMusicHomepageSubjectParams) => {
  meituTrack('plus_music_homepage_subject_expose', props)
}

/** 音乐首页专题卡片点击 */
export const PlusMusicHomepageSubjectClick = (props: MeituAnalyze.PlusMusicHomepageSubjectParams) => {
  meituTrack('plus_music_homepage_subject_click', props)
}

/** 音乐首页查看全部专题点击 */
export const PlusMusicHomepageSubjectAllClick = (props: MeituAnalyze.UrlCommonParams) => {
  meituTrack('plus_music_homepage_subject_all_click', props)
}

/** 音乐专题页面专题卡片曝光 */
export const PlusMusicSubjectpageSubjectShow = (props: MeituAnalyze.PlusMusicSubjectpageSubjectParams) => {
  meituTrack('plus_music_subjectpage_subject_show', props)
}

/** 音乐专题页面专题卡片点击 */
export const PlusMusicSubjectpageSubjectClick = (props: MeituAnalyze.PlusMusicSubjectpageSubjectParams) => {
  meituTrack('plus_music_subjectpage_subject_click', props)
}

/** 音乐专题页面分类按钮点击 */
export const PlusMusicSubjectpageTypeClick = (props: MeituAnalyze.PlusSubjectpageSubjectParams) => {
  meituTrack('plus_music_subjectpage_type_click', props)
}

/** 音乐首页精选音乐卡片曝光 */
export const PlusMusicHomepageExcellentMusicExpose = (props: MeituAnalyze.PlusMusicHomepageExcellentMusicParams) => {
  meituTrack('plus_music_homepage_excellent_music_expose', props)
}

/** 音乐首页精选音乐卡片点击 */
export const PlusMusicHomepageExcellentMusicClick = (props: MeituAnalyze.PlusMusicHomepageExcellentMusicParams) => {
  meituTrack('plus_music_homepage_excellent_music_click', props)
}

/** 音乐首页查看全部精选音乐点击 */
export const PlusMusicHomepageExcellentMusicAllClick = (props: MeituAnalyze.UrlCommonParams) => {
  meituTrack('plus_music_homepage_excellent_music_all_click', props)
}

/** 音乐精选专辑页面卡片曝光 */
export const PlusMusicExcellentpageExcellentMusicExpose = (
  props: MeituAnalyze.PlusMusicExcellentpageExcellentMusicParams
) => {
  meituTrack('plus_music_excellentpage_excellent_music_expose', props)
}

/** 音乐精选专辑页面卡片点击 */
export const PlusMusicExcellentpageExcellentMusicClick = (
  props: MeituAnalyze.PlusMusicExcellentpageExcellentMusicParams
) => {
  meituTrack('plus_music_excellentpage_excellent_music_click', props)
}

/** 音乐精选专辑页面类别按钮点击 */
export const PlusMusicExcellentpageTypeClick = (props: MeituAnalyze.PlusExcellentpageTypeClickParams) => {
  meituTrack('plus_music_excellentpage_type_click', props)
}

/** 音乐精选专辑页面搜索结果 */
export const PlusMusicExcellentpageSearchResults = (props: MeituAnalyze.PlusExcellentpageSearchResultsParams) => {
  meituTrack('plus_music_excellentpage_search_results', props)
}

/**
 * @version 1.2.2 ai助手专项
 * */
/** 大客户左侧导航栏点击 */
export const PlusGuideClick = (props: MeituAnalyze.PlusGuideClickParams) => {
  meituTrack('plus_guide_click', props)
}

/** AI助手页面点击 */
export const PlusAiWebClick = (props: MeituAnalyze.PlusAiWebClickParams) => {
  meituTrack('plus_ai_web_click', props)
}

/** AI助手申请试用提交按钮点击 */
export const PlusAiApplyCommitClick = (props: MeituAnalyze.PlusApplyCommitClickParams) => {
  meituTrack('plus_ai_apply_commit_click', props)
}

/**  AI助手作图页面曝光 */
export const PlusAiInfoPageExpose = (props: MeituAnalyze.PlusAiInfoPageExposeParams) => {
  meituTrack('plus_ai_info_page_expose', props)
}

/**  AI助手生成结果页面按钮点击 */
export const PlusAiResultsButtonClick = (props: MeituAnalyze.PlusAAiResultsButtonClickParams) => {
  meituTrack('plus_ai_results_button_click', props)
}

/** 图片详情页面图片以图搜图点击 */
export const PlusImageInfoGraphSearchClick = (props: MeituAnalyze.PlusImageInfoGraphSearchClickParams) => {
  meituTrack('plus_image_info_graph_search_click', props)
}

/** 相似图片按钮点击 */
export const PlusSimilarContributorClick = (props: MeituAnalyze.PlusSimilarContributorClickParams) => {
  meituTrack('plus_similar_click', props)
}

/** 相同作者按钮点击 */
export const PlusSameAuthorClick = (props: MeituAnalyze.PlusSimilarContributorClickParams) => {
  meituTrack('plus_contributor_click', props)
}

/** 图片视频详情页作者分类标签点击 */
export const PlusImageVideoInfoLabelClick = (props: MeituAnalyze.PlusImageVideoInfoLabelClickParams) => {
  meituTrack('plus_image_video_info_label_click', props)
}

/** 聚合请求成功失败埋点函数 */
export const meituSearchApiStatistics = ({
  result,
  keyword,
  searchType,
  searchContentType,
  searchid,
  total,
  message,
}) => {
  try {
    const search_type = MeituAnalyzeSearchType[searchType as keyof typeof MeituAnalyzeSearchType]
    const search_content_type =
      MeituAnalyzeSearchContentType[searchContentType as keyof typeof MeituAnalyzeSearchContentType]

    if (result) {
      meituSearchSuccess({
        key_word: keyword,
        request_id: searchid,
        search_result_num: total,
        search_type,
        search_content_type,
      })
    } else {
      meituSearchFail({
        key_word: keyword,
        request_id: searchid,
        reason: message || 'Error NetWork',
        search_type,
        search_content_type,
      })
    }
  } catch (error) {
    console.error(error)
  }
}

/** AI助手侧边路由点击埋点 */
export const PlusAiAssistantMenuClick = (props: MeituAnalyze.PlusAiAssistantMenuClickParams) => {
  meituTrack('plus_ai_guide_click', props)
}
