import React, { useContext } from 'react'
import Link from 'next/link'
import Tooltip from '@zcool/tooltip'
import { palette } from '@zcool/theme'
import { FormattedMessage } from 'react-intl'
import Icon from 'components/ui/icon'
import { GlobalContext } from 'shared/context'
import { showChoosePermissionModal } from 'components/actions'
import { showModal, updateCartCounts } from 'shared/actions'
import { addCartResult } from 'components/show/common/actions'
import { addSuccess, getCartCount, handleLocationToPageName } from 'helpers/util'
import { addToCart } from 'api/cart'
import {
  ContentType,
  AuthType,
  PurchaseConfirmType,
  MeituAnalyzeContentType,
  MeituAnalyzeSearchPageName,
  MeituAnalyzeSearchContentType,
  MeituAnalyzeFromModule,
} from 'helpers/enums'
import { showCartCountFullModal } from 'components/batch-operation/actions'

import Collect from './collect'
import {
  meituWishListClick,
  PlusSameAuthorClick,
  PlusSimilarContributorClick,
  safeExecute,
} from 'helpers/analyze-meitu'

interface ImageInfo {
  aspect: number
  src: string
}

interface ImageActions {
  index?: number
  id: string
  contentType?: ContentType
  contributorId?: string
  hasFavorite?: boolean
  hasSimilar?: boolean
  addCart?: boolean
  buyNow?: boolean
  // 立即购买弹窗使用
  imageInfo?: ImageInfo
  // 来源页面
  sourcePage: string
  // 来源模块
  fromModule?: string
}

export function getImageActions({
  index,
  id,
  imageInfo,
  contentType = ContentType.Creative,
  contributorId,
  hasFavorite,
  hasSimilar,
  addCart,
  buyNow,
  sourcePage,
  fromModule = MeituAnalyzeFromModule.ResultList,
}: ImageActions) {
  const child = []

  if (!id) {
    return child
  }

  child.push(
    <Collect index={index} id={id} isCollected={hasFavorite} contentType={contentType} contributorId={contributorId} />
  )

  if (hasSimilar) {
    const diffFields = {
      [ContentType.Video]: { name: 'video', router: 'video' },
      [ContentType.Creative]: { name: 'image', router: 'creative' },
    }

    const { name, router } = diffFields[contentType]

    child.push(
      <Tooltip
        key={`icon-similar-${name}`}
        placement="top"
        mouseEnterDelay={100}
        mouseLeaveDelay={100}
        overlayStyle={{ fontSize: 12 }}
        title={<FormattedMessage id={`actions.similar.${name}`} />}
      >
        <Link href={`/${router}/similar?id=${id}`} as={`/${router}/similar/${id}`}>
          <a
            onClick={() =>
              safeExecute(() => {
                PlusSimilarContributorClick({
                  card_id: id,
                  content_type: MeituAnalyzeSearchContentType[MeituAnalyzeContentType[contentType]],
                  from_module: fromModule,
                  source_page: sourcePage,
                  url: window.location.href,
                  referrer: document.referrer,
                })
              })
            }
          >
            <Icon glyph={`similar-${name}`} size={20} color={palette.spruce} />
          </a>
        </Link>
      </Tooltip>
    )
  }

  if (contributorId) {
    child.push(
      <Tooltip
        key="icon-similar-author"
        placement="top"
        mouseEnterDelay={100}
        mouseLeaveDelay={100}
        overlayStyle={{ fontSize: 12 }}
        title={<FormattedMessage id="actions.similar.author" />}
      >
        <Link href={`/creative/contributor?id=${contributorId}`} as={`/creative/contributor/${contributorId}`}>
          <a
            onClick={() =>
              safeExecute(() => {
                PlusSameAuthorClick({
                  card_id: id,
                  content_type: MeituAnalyzeSearchContentType[MeituAnalyzeContentType[contentType]],
                  from_module: fromModule,
                  source_page: sourcePage,
                  url: window.location.href,
                  referrer: document.referrer,
                })
              })
            }
          >
            <Icon glyph="similar-author" size={20} color={palette.spruce} />
          </a>
        </Link>
      </Tooltip>
    )
  }

  if (buyNow) {
    child.push(<BuyNow id={id} contributorId={contributorId} contentType={contentType} imageInfo={imageInfo} />)
  }

  if (addCart) {
    child.push(<AddToCart id={id} contentType={contentType} />)
  }

  return child
}

// 加入购物车
export function AddToCart({
  id,
  contentType,
  searchStatistics = {},
}: {
  id: string
  contentType: ContentType
  searchStatistics?: Record<string, any>
}) {
  const {
    currentUser: { can_warrant: canWarrant, user_warrant: userWarrant, write_warrant: writeWarrant },
  } = useContext(GlobalContext)

  const addCartBaseParams = {
    contentType,
    id,
    queryPackagePermission: contentType === ContentType.Creative,
  }

  function handleClickAddCart(e: React.MouseEvent<HTMLElement>) {
    if (getCartCount() >= 200) {
      return showCartCountFullModal({ cartCount: getCartCount() })
    }

    const event = {
      clientX: e.clientX,
      clientY: e.clientY,
    }

    function addCart(permissionId?: number, warrant?: PLUS.UserWarrant, packagePermissionId?: number) {
      addCartResult({
        ...addCartBaseParams,
        event,
        warrantId: warrant ? warrant.id : undefined,
        permissionId,
        packagePermissionId,
      })

      try {
        const { index, author_id, searchid } = searchStatistics
        const page = handleLocationToPageName(MeituAnalyzeContentType[contentType])

        meituWishListClick({
          card_id: id,
          author_id,
          page_source: MeituAnalyzeSearchPageName[page as keyof typeof MeituAnalyzeSearchPageName],
          material_type:
            MeituAnalyzeSearchContentType[
              MeituAnalyzeContentType[contentType] as keyof typeof MeituAnalyzeSearchContentType
            ],
          postion_id: String(index),
          request_id: searchid,
        })
      } catch (error) {
        console.error(error)
      }
    }

    showChoosePermissionModal({
      ...addCartBaseParams,
      canWarrant,
      onConfirm: (permission) => {
        if (!canWarrant || !writeWarrant) {
          addCart(permission.id, userWarrant)
        } else {
          showModal('AUTH', {
            ...addCartBaseParams,
            type: AuthType.AddToCart,
            beforeSelectCheck: (warrant) => {
              return addToCart({
                ...addCartBaseParams,
                permissionId: permission.id,
                userWarrantId: warrant.id,
              }).then((resp) => ({
                ...resp,
                id,
              }))
            },
            onSelect: () => addSuccess(event, () => updateCartCounts(getCartCount() + 1)),
            noWarrantSelect: () => {
              addCart(permission.id)
            },
          })
        }
      },
    })
  }

  return (
    <Tooltip
      key="icon-add-cart"
      placement="top"
      mouseEnterDelay={100}
      mouseLeaveDelay={100}
      overlayStyle={{ fontSize: 12 }}
      title={<FormattedMessage id="actions.cart.add" />}
    >
      <Icon
        glyph="add-cart"
        size={20}
        color={palette.spruce}
        hoverColor={palette.primary}
        onClick={(e) => handleClickAddCart(e)}
      />
    </Tooltip>
  )
}

// 立即购买
function BuyNow({
  id,
  contributorId,
  contentType,
  imageInfo,
}: {
  id: string
  contributorId: string
  contentType: ContentType
  imageInfo: ImageInfo
}) {
  const {
    currentUser: { can_warrant: canWarrant, user_warrant: userWarrant },
  } = useContext(GlobalContext)

  function buyImmediately(permission: PLUS.Permission, warrant?: PLUS.UserWarrant) {
    const confirmDetail: PLUS.PurchaseConfirmData = {
      id,
      contentType,
      aspect: imageInfo.aspect,
      coverUrl: imageInfo.src,
      permission,
      warrant: warrant || userWarrant,
      isVector: false,
    }

    showModal('PURCHASE_CONFIRM', {
      data: [confirmDetail],
      type: PurchaseConfirmType.Show,
    })
  }

  function handleClickBuyNow() {
    showChoosePermissionModal({
      id,
      contentType,
      canWarrant,
      onConfirm: (permission) => {
        if (!canWarrant) {
          buyImmediately(permission)
        } else {
          showModal('AUTH', {
            contentType,
            analyze: {
              id,
              author_id: contributorId,
            },
            onSelect: (warrant) => buyImmediately(permission, warrant),
          })
        }
      },
    })
  }

  return (
    <Tooltip
      key="icon-buy-now"
      placement="top"
      mouseEnterDelay={100}
      mouseLeaveDelay={100}
      overlayStyle={{ fontSize: 12 }}
      title={<FormattedMessage id="actions.cart.purchase" />}
    >
      <Icon glyph="buy-now" size={20} color={palette.spruce} onClick={() => handleClickBuyNow()} />
    </Tooltip>
  )
}
